import React, { useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const RichEditor = props => {
  const [quill, setQuill] = React.useState(null);

    useEffect(() => {
        loadEditor();
    }, []);

    useEffect(() => {
        if(quill!==null){
          if(props.value&&quill.getLength()===1){
            //setting the value of the editor
            quill.setContents(JSON.parse(props.value));
          }else if(!props.value){ {
            quill.setContents([]);
          }
        }
        }
    }, [props.value,quill]);
    
    const loadEditor = () => {
        const quill = new Quill('#editor-container', {
          modules: {
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline'],
              ['image', 'code-block'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['clean'],
              ['link'],
              [{ 'indent': '-1'}, { 'indent': '+1' }], 
            ]
          },
          placeholder: 'Describe a task...',
          theme: 'snow'  // or 'bubble'
        });
        quill.on('text-change', function() {
            props.onChange(JSON.stringify(quill.getContents()));
        });
        setQuill(quill);


    }

    

    return (
        <div style={{height:375}} id="editor-container"></div>
    );
}
export default RichEditor;