import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import Clauses from "./Clauses";

const AmendmentClause = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);
  const currentClauses = props.currentClauses || [];
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Section text={t("Additional Amendment Options")}>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
            <Form.Item label={t("Old Clauses")}>{field("oldClauses", { initialValue: currentClauses })(<Clauses disabled />)}</Form.Item>
            <Form.Item label={t("New Clauses")}>
              {field("newClauses", { initialValue: currentClauses, rules: [{ required: true }] })(<Clauses config={props.config} policy={props.policy} />)}
            </Form.Item>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
          </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentClause);
