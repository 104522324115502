import React, {useEffect, useState} from 'react';
import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Popconfirm, Row } from "antd";

import Currency from "../Shared/Currency";
import {useTranslation} from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {getConfigProfile} from "../../Lib/Helpers";
import {safeGet} from "../../Lib/Dal";
import ReinstatementInstallmentStatus from "../Contact/ReinstatementInstallmentStatus";
import DatePickerW from '../Shared/DatePickerW';
import ContractInstallmentTypeSelect from './ContractInstallmentTypeSelect';

const ContractReinstatementForm = (props) => {
    const [t] = useTranslation();
    const [contactRoles, setContactRoles] = useState([]);

    useEffect(() => {
        if (props.selected) props.form.setFieldsValue(props.selected);
    }, [props.selected])

    useEffect(() => {
        getConfigProfile().then((profile) => {
            setContactRoles(safeGet(["Reinsurance", "contactRoles"], window.global.configProfile, []));
        });
    }, []);

    const onSave = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            props.onSave(values);
        })
    }
    const onDelete = () => {
        props.onDelete(props.selected);
    }

    const field = props.form.getFieldDecorator;
    const status = props.form.getFieldValue("status");
    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Button type="link" icon={<LeftOutlined />} onClick={props.onBack}>{t("Back")}</Button>
                    <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
                    {props.selected.id > 0 &&
                        <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete}>
                            <Button type="link" icon={<DeleteOutlined />}>{t("Delete")}</Button>
                        </Popconfirm>}

                    {field("id")}
                    {field("contractId")}
                    {field("processId")}

                    <Form.Item label={t("Installment Number")}>
                        {field("installmentNum", {rules: [{required: true}]})(<InputNumber min={0}/>)}
                    </Form.Item>
                    <Form.Item label={t("Payment Date")}>
                        {field("payDate", {rules: [{required: true}]})(<DatePickerW/>)}
                    </Form.Item>
                    <Form.Item label={t("Beneficiary")}>
                        {field("contactId", {rules: [{required: true}]})(
                            <SimpleContactSelect allowNew
                                                 filter={contactRoles.length === 0 ? undefined : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `}
                            />)}
                    </Form.Item>
                    <Form.Item label={t("Line Id")}>
                        {field("lineId", {rules: [{required: true}]})(<Input/>)}
                    </Form.Item>
                    <Form.Item label={t("Status")}>
                        {field("status", {rules: [{required: true}]})(<ReinstatementInstallmentStatus disabled={status==3}/>)}
                    </Form.Item>
                    <Form.Item label={t("Amount")}>
                        {field("amount", {rules: [{required: true}]})(<InputNumber min={0}/>)}
                    </Form.Item>
                    <Form.Item label={t("Currency")}>
                        {field("currency", {rules: [{required: true}]})(<Currency/>)}
                    </Form.Item>
                    <Form.Item label={t("Type")}>
                        {field("typeCode", {rules: [{required: true}]})(<ContractInstallmentTypeSelect/>)}
                    </Form.Item>

                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(ContractReinstatementForm);