import React from "react";
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { MessageOutlined, UserOutlined } from '@ant-design/icons';
import { Tooltip, Comment, Avatar, Button } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { exe } from "../../Lib/Dal";
import { formatDate } from "../../Lib/Helpers";
import { withTranslation } from "react-i18next";

class Comments extends React.Component {
  state = {
    likes: 0,
    dislikes: 0,
    action: null,
    loading: false,
    data: [],
  };
  componentDidMount() {
    if (this.props.policyId || this.props.contactId || this.props.accountId || this.props.cardId) this.load();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.policyId != this.props.policyId || prevProps.contactId != this.props.contactId || prevProps.accountId != this.props.accountId || prevProps.cardId!=this.props.cardId) this.load();
  }

  load = () => {
    const entity = (this.props.contactId && "contactId") || (this.props.policyId && "lifePolicyId") || (this.props.accountId && "accountId") || (this.props.cardId && "cardId");
    const entityId = this.props.contactId || this.props.policyId || this.props.accountId || this.props.cardId;
    if (!entity || !entityId) return;

    this.setState({ loading: true });
    exe("RepoComment", { operation: "GET", filter: `${entity}=${entityId}` }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        const parsedData = r.outData.map((p) => ({ ...p, likes: p.likes ? JSON.parse(p.likes) : [] }));
        this.setState({ data: parsedData });
        if (this.props.numberOfComments) this.props.numberOfComments(parsedData.length);
      }
    });
  };
  onNewComment = () => {
    this.setState({ loading: true });
    exe("RepoComment", {
      operation: "ADD",
      entity: { message: this.state.value, lifePolicyId: this.props.policyId, contactId: this.props.contactId, accountId: this.props.accountId, cardId: this.props.cardId },
    }).then((r) => {
      if (r.ok) {
        this.setState({ editor: false, loading: false });
        this.load();
      }
    });
  };
  onRemoveComment = (id) => {
    this.setState({ loading: true });
    exe("RepoComment", { operation: "DELETE", entity: { id: id } }).then((r) => {
      if (r.ok) {
        this.setState({ loading: false });
        this.load();
      }
    });
  };
  likeComment = (comment) => {
    const operation = comment.likes.includes(window.GLOBAL.currentUser.email) ? "DISLIKE" : "LIKE";
    this.setState({ loading: true });
    exe("RepoComment", { operation: operation, entity: { id: comment.id, likes: JSON.stringify(comment.likes) } }).then((r) => {
      if (r.ok) {
        this.setState({ loading: false });
        this.load();
      }
    });
  };

  render() {
    const { likes, dislikes, action } = this.state;
    const { t, i18n } = this.props;

    return (
      <div>
        {this.state.data.map((p) => (
          <Comment
            style={{ marginBottom: -25 }}
            actions={[
              <span key="comment-basic-like">
                <Tooltip title={t("Like")}>
                  <LegacyIcon
                    type="like"
                    theme={p.likes.includes(window.GLOBAL.currentUser && window.GLOBAL.currentUser.email) ? "filled" : "outlined"}
                    onClick={() => this.likeComment(p)}
                  />
                </Tooltip>
                <span style={{ paddingLeft: 8, cursor: "auto" }}>
                  <Tooltip title={p.likes.length == 0 ? t("0 Likes") : JSON.stringify(p.likes)}>{p.likes.length}</Tooltip>
                </span>
              </span>,
              <span key="comment-basic-reply-to" onClick={() => this.onRemoveComment(p.id)}>
                {t("Delete")}
              </span>,
            ]}
            author={p.user}
            avatar={<Avatar icon={<UserOutlined />} />}
            content={p.message}
            datetime={
              <Tooltip title={formatDate(p.created)}>
                <span>{moment.utc(p.created).fromNow()}</span>
              </Tooltip>
            }
          />
        ))}

        {this.state.editor && (
          <Comment
            avatar={<Avatar icon={<UserOutlined />} />}
            content={
              <div>
                <Form.Item>
                  <TextArea
                    rows={4}
                    onChange={(e) =>
                      this.setState({
                        value: e.target.value,
                      })
                    }
                    value={this.state.value}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" loading={this.state.loading} onClick={() => this.onNewComment()} type="primary">
                    {t("Add Comment")}
                  </Button>
                </Form.Item>
              </div>
            }
          />
        )}
        {!this.state.editor && (
          <Button type="link" icon={<MessageOutlined />} onClick={() => this.setState({ editor: true, value: "" })}>
            {t("Add Comment")}
          </Button>
        )}
      </div>
    );
  }
}
export default withTranslation()(Comments);
