import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Tag,
  Select,
  Input,

} from "antd";
import { useTranslation } from "react-i18next";
import ContactSelect from "../Contact/ContactSelect";
import Compare from "../Shared/Compare";


const PolicyCertificateFilter = (props) => {
  const [t] = useTranslation();
  const [matches, setMatches] = useState(null);
  const [loading,setLoading]=useState(false);

  
  const submit = () => {
      props.form.validateFields((err, values) => {
          if (err) return;
          console.log(values, "FORM VALUES");
          let queries = [];
          
          if(values.payer){
              queries.push(`payerId=${values.payer}`);
            }
            if(values.insured){
                queries.push(`id in (SELECT lifePolicyId FROM Insured WHERE contactId=${values.insured})`);
            }
            if(values.group){
                queries.push(`[group]='${values.group}'`);
            }
            if(values.status!=undefined){
                queries.push(`active=${values.status}`);
            }
            if(values.insuredSum){
                queries.push(`insuredSum ${values.insuredSum}`);
            }
            if(values.anualPremium){
                queries.push(`anualPremium ${values.anualPremium}`);
            }
            if(values.description){
                queries.push(`description like '%${values.description}%'`);
            }
            
            
            const filterQuery = queries.join(" AND ");
            props.onSearch(filterQuery);
            
        });
    };
    const values=props.form.getFieldsValue();
    const { getFieldDecorator } = props.form;

  return (
    <div>
      <Drawer title={t("Policy Certificate Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{marginTop:-12 }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>
        <Form layout="vertical">
            <Form.Item label={t("Insured")} style={{ width: "100%" }}>
              {getFieldDecorator("insured")(<ContactSelect />)}
            </Form.Item>
            <Form.Item label={t("Payer")} style={{ width: "100%" }}>
              {getFieldDecorator("payer")(<ContactSelect />)}
            </Form.Item>
            <Form.Item label={t("Group")} style={{ width: "100%" }}>
              {getFieldDecorator("group")(<Select style={{ width: "100%" }} allowClear>
            {props.groups.map((group) => (
              <Select.Option key={group} value={group}>
                {group}
              </Select.Option>
            ))}
          </Select>)}
            </Form.Item>
            <Form.Item label={t("Insured Sum")}>
                    {getFieldDecorator("insuredSum")(<Compare />)}
              </Form.Item>
              <Form.Item label={t("Premium")}>
                    {getFieldDecorator("anualPremium")(<Compare />)}
              </Form.Item>
            <Form.Item label={t("Status")} style={{ width: "100%" }}>
              {getFieldDecorator("status")(
                <Select allowClear placeholder={t("Current in force status")} onClear={()=>props.form.setFieldsValue({status:undefined})}>
                  <Select.Option value={1}>{t("Active")}</Select.Option>
                  <Select.Option value={0}>{t("Inactive")}</Select.Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t("Description")} style={{ width: "100%" }}>
              {getFieldDecorator("description")(<Input />)}
            </Form.Item>

               
        </Form>
        <Button style={{marginTop:10}} type="primary" icon={<SearchOutlined />} onClick={() => submit()} loading={loading}>
          {t("Search")}
        </Button>
        
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(PolicyCertificateFilter);
