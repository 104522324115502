import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, message, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../../Lib/Dal";
import AddressTypeSelect from "./AddressTypeSelect";
import LocationSelect from "./LocationSelect";
import MapLocationField from "../Property/MapLocationField";
import ZipSelect from './ZipSelect';

const Address = (props) => {
  const [selectedSector, setSelectedSector] = useState();
  const { getFieldDecorator } = props.form;
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.value) {
      const address = props.value;
      address.location = {
        country: props.value.country,
        state: props.value.state,
        city: props.value.city,
        sector: props.value.sector,
      };
      props.form.resetFields();
      props.form.setFieldsValue(address);
    }
  }, [props.value.id]);

  useEffect(() => {
    if (props.defaultCountry) {
      props.form.setFieldsValue({ location: { country: props.defaultCountry, state: undefined, city: undefined, sector: undefined } });
    }
  }, [props.defaultCountry]);

  useEffect(() => {
    if (props.isMandatory) {
      props.form.validateFields();
    }
  }, [props.isMandatory]);

  //zip sync according to selected sector
  useEffect(() => {
    if(props.hasSector){
      if (props.value && props.value.location && props.value.location.sector) {
        exe("RepoSectorCatalog", { operation: "GET", filter: "id=" + props.value.location.sector }).then((r) => {
          if (r.ok) {
            props.form.setFieldsValue({ zip: r.outData[0].code });
          }
        });
      }
    }
  }, [props.value&&props.value.location&&props.value.location.sector]);


  const saveAddress = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        values.country = values.location.country;
        values.state = values.location.state;
        values.city = values.location.city;
        exe("RepoContactAddress", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
          if (r.ok) {
            message.success(r.msg);
            props.onRefresh(r.outData[0]);
            props.form.setFieldsValue(r.outData[0]);
          } else {
            message.error(r.msg);
          }
        });
      }
    });
  };
  const isMandatory = (fieldName) => {
    if (props.isMandatory) {
      return props.isMandatory(fieldName);
    } return false //else {
      //const defaultMandatoryFields = ["Location", "country", "state", "city", "addressType", "address1", "zip"];
      //return defaultMandatoryFields.some((p) => p == fieldName);
    //}
  };
  const locationValidator = (rule, value, cb) => {
    if (!value && isMandatory("Location")) return cb(t("Full location is mandatory"));
    if (!value.country && isMandatory("country")) return cb(t("Country is mandatory"));
    if (!value.state && isMandatory("state")) return cb(t("State is mandatory"));
    if (!value.city && isMandatory("city")) return cb(t("City is mandatory"));
    if (props.hasSector&&!value.sector && isMandatory("sector")) return cb(t("Sector is mandatory"));
    cb();
  };
  const addressTypeValidator = (rule, value, cb) => {
    if (!value && isMandatory("addressType")) cb(t("Address type is mandatory"));
    if (value == t("Please enter new address") && isMandatory("addressType")) cb(t("Address type is mandatory"));
    cb();
  };
  //programtic form value setting via custom forms
  if (!window.addressForm) window.addressForm = [];
  window.addressForm[props.index] = props.form;
  return (
    <div>
      {getFieldDecorator("id")}
      {getFieldDecorator("contactId")}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("Location")}>
            {getFieldDecorator("location", {
              initialValue: { country: props.defaultCountry, state: undefined, city: undefined, sector: undefined },
              rules: [{ validator: locationValidator }],
            })(<LocationSelect hasSector={props.hasSector} sector={selectedSector} onSectorChange={v=>v?null:props.form.setFieldsValue({zip:undefined})} />)}
          </Form.Item>
          <Form.Item label={t("Address Line 1")}>
            {getFieldDecorator("address1", {
              rules: [
                {
                  required: isMandatory("address1"),
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t("Address Line 2")}>{getFieldDecorator("address2", { rules: [{ required: isMandatory("address2") }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Map Location")}>{getFieldDecorator("jMap", { rules: [{ required: isMandatory("jMap") }] })(<MapLocationField />)}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("Address Type")}>
            {getFieldDecorator("addressType", {
              initialValue: "Home",
              rules: [
                {
                  validator: addressTypeValidator,
                },
              ],
            })(<AddressTypeSelect personType={props.personType} />)}
          </Form.Item>

          <Form.Item label={t("ZIP/Postal Code")}>
            {getFieldDecorator("zip", {
              rules: [
                {
                  required: isMandatory("zip"),
                },
              ],
            })(<ZipSelect hasSector={props.hasSector} onSelect={(id,code)=>setSelectedSector(id)} country={safeGet(["location","country"],props.value,undefined)} />)}
          </Form.Item>
          <Form.Item label={t("Legal Correspondence")}>{getFieldDecorator("legal",{ valuePropName: "checked"})(<Switch />)}</Form.Item>
          <Button type="link" icon={<PlusCircleOutlined />} onClick={() => props.onAddAddress()} style={{ paddingLeft: 2 }}>
            {t("Add Address")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({
  onChange: () => console.log("FORM CHANGED"),
  onValuesChange: (props, changedValues, allValues) => {
    props.form.validateFields((err, values) => {
      const newValues = { ...allValues, ...changedValues };
      //normalizing
      newValues.country = newValues.location.country;
      newValues.state = newValues.location.state;
      newValues.city = props.hasSector !== undefined ? newValues.location.city : +newValues.location.city;
      newValues.sector = props.hasSector ? newValues.location.sector : undefined;

      newValues.contactId = newValues.contactId ? +newValues.contactId : 0;
      //validations results
      //newValues.err = err; //events fires before the forma has changesd. incorerct validation. doing mnanually
      newValues.err = [];
      if (!newValues.country) newValues.err.push("country");
      if (!newValues.state) newValues.err.push("state");
      if (!newValues.city) newValues.err.push("city");
      if (!newValues.zip) newValues.err.push("zip");
      if (!newValues.address1) newValues.err.push("address1");
      if (!newValues.address2) newValues.err.push("address2");
      if (!newValues.addressType||newValues.addressType===props.t("Please enter new address")) newValues.err.push("addressType");
      if (!newValues.jMap) newValues.err.push("jMap");
      if (props.hasSector&&!newValues.sector) newValues.err.push("sector");

      console.log("validation and sending", newValues, err);
      props.onChange(newValues);
    });
  },
})(Address);
