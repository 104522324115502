import { Descriptions } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateShort } from "../../Lib/Helpers";
import ContactLink from "../Contact/ContactLink";
import MultiContactSelect from "../Contact/MultiContactSelect";
import ClaimPolicyLife from "../Health/ClaimPolicyLife";
import Currency from "../Shared/Currency";
import Money, { formatter } from "../Shared/Money";
import BillingStatus from "./BillingStatus";
import Coverages from "./Coverages";
import PaymentPlan from "./PaymentPlan";
import LifePolicy from "./LifePolicy";

const PolicySnapshot = (props) => {
  const [t] = useTranslation();
  const [policy, setPolicy] = useState(null);
  useEffect(() => {
    if (props.value) {
      const pol = JSON.parse(props.value);
      if(pol.Product) pol.config = JSON.parse(pol.Product.configJson);else pol.config=props.config;//using current config if config at change time not available
      setPolicy(pol);
      console.log(pol,"POLICY SNAPSHOT");
    }
  }, [props.value]);
  if (!policy) return null;
  return (
    <div>
      <LifePolicy snapshot={policy} config={props.config} product={props.product} checkTabPermissions={props.checkTabPermissions} />
    </div>
  );
};

export default PolicySnapshot;
