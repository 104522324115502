import React, { useEffect, useState } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Input,
  Table,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  InputNumber,
  DatePicker,
  Checkbox,
  Tabs,
} from "antd";
import { exe } from "Lib/Dal";
import PolicySelect from "../Health/PolicySelect";
import Compare from "../Shared/Compare";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import DistributionModeSelect from "./DistributionModeSelect";
import useUpdateEffect from "../../Lib/Helpers";
import moment from "moment";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import ContractCodeSelect from "./ContractCodeSelect";
import StatusSelect from "../Shared/StatusSelect";
import LobSelect from './../Shared/LobSelect';
import ProductSelect from './../Life/ProductSelect';
import ContractSelect from './ContractSelect';
import SelectionSelect from "./SelectionSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const CessionsFilter = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.location.hash.includes("?policyId=")) {
      const policy = window.location.hash.split("?policyId=")[1];
      props.form.setFieldsValue({ policyId: policy });
      onSearch();
    }else if(window.location.hash.includes("?savedSelectionId=")){
      const selectionId = window.location.hash.split("?savedSelectionId=")[1];
      props.form.resetFields();
      props.form.setFieldsValue({ savedSelectionId: selectionId });
      onSearch();
    }
     else {
      if (props.contractId) {
        //called from the contract/borderau screen
        props.form.setFieldsValue({ date: new moment(), contractId: props.contractId });
      } else {
        //called from cessions
        props.form.setFieldsValue({ date: new moment() });
        onSearch();
      }
    }
  }, []);

  useUpdateEffect(() => {
    onSearch();
  }, [props.doReload]);
  useUpdateEffect(() => {
      if(props.paginate) onSearch();
  }, [props.pagination?.current]);

  const onSearch = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let queries = [];
        if (values.lifepolicyId) queries.push(`lifepolicyId=${values.lifepolicyId}`);
        if (values.policyId) queries.push(`lifepolicyId=${values.policyId}`);
        if (values.contractId) queries.push(`contractId=${values.contractId}`);
        if (values.id) queries.push(`id=${values.id}`);
        if (values.lob) queries.push(`lob='${values.lob}'`);
        if (values.coverageCode) queries.push(`coverageCode='${values.coverageCode}'`);
        if (values.code) queries.push(`contractId in (SELECT id from Contract where code='${values.code}')`);
        if (values.currency) queries.push(`currency='${values.currency}'`);
        if (values.sumInsured) queries.push(`sumInsured${values.sumInsured}`);
        if (values.coinsurance) queries.push(`coSumInsured>0`);
        if (values.date) queries.push(`YEAR(start)=${values.date.year()} AND MONTH(start)=${values.date.month() + 1}`);
        if (values.distributionMode) queries.push(`distributionMode=${values.distributionMode}`);
        if (values.period) {
          const year = values.period.year();
          const month = values.period.month() + 1;
          const lastDay = new Date(year, month, 0).getDate();
          queries.push(
            `YEAR(start)=${year} AND MONTH(start)=${month} AND DAY(start)=1 AND YEAR([end])=${year} AND MONTH([end])=${month} AND DAY([end])=${lastDay}`
          );
        }
        if (values.range) {
          queries.push(`start between '${values.range[0].format("YYYY-MM-DD")}' and  '${values.range[1].format("YYYY-MM-DD")}'`);
        }
        if (values.creationRange) {
          queries.push(`premiumType='NEW' AND lifepolicyId in (SELECT id FROM LifePolicy WHERE created between '${values.creationRange[0].format("YYYY-MM-DD")}' AND  '${values.creationRange[1].format("YYYY-MM-DD")}')
          OR premiumType='ANNIVERSARY' AND anniversaryId in (SELECT id FROM Anniversary WHERE created between '${values.creationRange[0].format("YYYY-MM-DD")}' AND  '${values.creationRange[1].format("YYYY-MM-DD")}')
          OR premiumType='CHANGE' AND changeId in (SELECT id FROM Change WHERE creationDate between '${values.creationRange[0].format("YYYY-MM-DD")}' AND  '${values.creationRange[1].format("YYYY-MM-DD")}')`);
        }
        if (values.policyStart) {
          queries.push(`lifepolicyId in (SELECT id FROM LifePolicy WHERE [start] between '${values.policyStart[0].format("YYYY-MM-DD")}' AND '${values.policyStart[1].format("YYYY-MM-DD")}')`);
        }
        if (values.policyEnd) {
          queries.push(`lifepolicyId in (SELECT id FROM LifePolicy WHERE [end] between '${values.policyEnd[0].format("YYYY-MM-DD")}' AND '${values.policyEnd[1].format("YYYY-MM-DD")}')`);
        }
        if(values.policyStatus===1 || values.policyStatus===0) queries.push(`lifepolicyId in (SELECT id FROM LifePolicy WHERE active=${values.policyStatus})`)
        if(values.productCode) queries.push(`lifepolicyId in (SELECT id FROM LifePolicy WHERE productCode='${values.productCode}')`)
        if(values.contractId) queries.push(`contractId=${values.contractId}`)

        if (values.participantId !== undefined) {
          queries.push(`id in (select cessionId from CessionPart where contactId=${values.participantId})`);
        }
        if (values.facultative) queries.push(`lineId='FAC'`);
        if(!values.diff){
          if (values.overwritten) queries.push(`overwritten=1`);else  queries.push(`overwritten=0`)
        }
      if(values.savedSelectionId){
        queries.push(`id in 
        (SELECT value from STRING_SPLIT((SELECT REPLACE(REPLACE(jSelection,'[',''),']','') FROM Selection WHERE id = ${values.savedSelectionId}),',') WHERE TRY_CAST(value as int) IS NOT NULL)`);
      }
      //filtering by policyholder ie. lifePolicy.holderId
      if(values.holderId) queries.push(`lifepolicyId in (SELECT id FROM LifePolicy WHERE holderId=${values.holderId})`);

        const filterQuery = queries.join(" AND ");
        console.log(queries, filterQuery);

        props.onLoading&&props.onLoading(true);
        exe("RepoCession", { operation: "GET", filter: filterQuery, include: ["Participants"],diff:values.diff, size: props.paginate?props.pagination.pageSize:undefined, page: props.paginate?(props.pagination.current - 1):undefined }).then((r) => {
          props.onLoading&&props.onLoading(false);
          if (r.ok) {
            props.onSearch(r.outData,r.total);
          } else {
            message.error(r.msg);
          }
        });
      }
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <div>
      <Drawer title={t("Cession Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Tabs>
            <Tabs.TabPane tab={t("General")} key="general">
              <Form.Item label={t("Policy")}>{getFieldDecorator("lifepolicyId")(<PolicySelect />)}</Form.Item>
              <Form.Item label={t("Policyholder")}>{getFieldDecorator("holderId")(<SimpleContactSelect />)}</Form.Item>
              <Form.Item label={t("LoB")}>{getFieldDecorator("lob")(<LobSelect />)}</Form.Item>
              <Form.Item label={t("Product")}>{getFieldDecorator("productCode")(<ProductSelect all lobFilter={props.form.getFieldValue("lob")} onlyValue />)}</Form.Item>
              <Form.Item label={t("Coverage Code")}>{getFieldDecorator("coverageCode")(<Input />)}</Form.Item>
              <Form.Item label={t("Contract")}>{getFieldDecorator("contractId")(<ContractSelect />)}</Form.Item>
              <div style={{ display: "flex" }}>
                <Form.Item label={t("Policy Id")} style={{ marginRight: 5 }}>
                  {getFieldDecorator("policyId")(<InputNumber />)}
                </Form.Item>
                <Form.Item label={t("Treaty Id")} style={{ marginRight: 5 }}>
                  {getFieldDecorator("contractId")(<InputNumber />)}
                </Form.Item>
                <Form.Item label={t("Cession Id")} style={{ marginRight: 5 }}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Contract Code")} style={{width:"100%"}}>{getFieldDecorator("code")(<ContractCodeSelect style={{width:"100%"}} />)}</Form.Item>
              </div>
              <Form.Item label={t("Policy Status")}>{getFieldDecorator("policyStatus")(<StatusSelect />)}</Form.Item>
              <Form.Item label={t("Currency")}>{getFieldDecorator("currency")(<Currency />)}</Form.Item>
              <Form.Item label={t("Sum Insured")}>{getFieldDecorator("sumInsured")(<Compare />)}</Form.Item>
              <Form.Item label={t("Distribution Mode")}>{getFieldDecorator("distributionMode")(<DistributionModeSelect />)}</Form.Item>
              
              <Form.Item label={t("Participant")}>{getFieldDecorator("participantId")(<SimpleContactSelect />)}</Form.Item>
              <div style={{display:"flex"}}>
                <Form.Item label={t("Facultative Only")}  style={{ marginRight: 15 }}>{getFieldDecorator("facultative",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                <Form.Item label={t("Coinsurance Only")}>{getFieldDecorator("coinsurance",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
              </div>
              <div style={{display:"flex"}}>
                <Form.Item label={t("Differential View")}  style={{ marginRight: 15 }}>{getFieldDecorator("diff",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                <Form.Item label={t("Overwritten")}>{getFieldDecorator("overwritten",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
              </div>
        </Tabs.TabPane>
            <Tabs.TabPane tab={t("Dates")} key="dates">
              <Form.Item label={t("Issuance Period")}>{getFieldDecorator("date")(<DatePicker.MonthPicker />)}</Form.Item>
              <Form.Item label={t("Accrued Period")}>{getFieldDecorator("period")(<DatePicker.MonthPicker />)}</Form.Item>
              <Form.Item label={t("Issuance Range")}>{getFieldDecorator("range")(<DatePicker.RangePicker />)}</Form.Item>
              <Form.Item label={t("Creation Range")}>{getFieldDecorator("creationRange")(<DatePicker.RangePicker />)}</Form.Item>
              <Form.Item label={t("Policy Start")}>{getFieldDecorator("policyStart")(<DatePicker.RangePicker />)}</Form.Item>
              <Form.Item label={t("Policy End")}>{getFieldDecorator("policyEnd")(<DatePicker.RangePicker />)}</Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Other")} key="other">
              <Form.Item label={t("Saved Selection")}>{getFieldDecorator("savedSelectionId")(<SelectionSelect entity="Cession" visible={props.visible} />)}</Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
          {t("Search")}
        </Button>
      </Drawer>
    </div>
  );
};

export default Form.create()(CessionsFilter);
