import React, { Component } from "react";
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  BlockOutlined,
  BuildOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  CoffeeOutlined,
  CopyOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  EuroOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  FolderAddOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  InsuranceOutlined,
  LineChartOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PaperClipOutlined,
  PercentageOutlined,
  PieChartOutlined,
  PushpinOutlined,
  RedoOutlined,
  ReloadOutlined,
  RightOutlined,
  RocketOutlined,
  SafetyCertificateOutlined,
  SaveOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  StopOutlined,
  TagOutlined,
  TeamOutlined,
  ToolOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
  BarChartOutlined,
  LeftOutlined,
  ClusterOutlined,
  DiffOutlined,
  LockOutlined
} from '@ant-design/icons';

import {
  Button,
  InputNumber,
  Typography,
  Steps,
  Row,
  Col,
  Tabs,
  Menu,
  Dropdown,
  Select,
  message,
  Input,
  Modal,
  Tooltip,
  Badge,
  Popconfirm,
  Checkbox,
  Switch,
} from "antd";
import { exe, safeGetRaw } from "Lib/Dal";
import moment from "moment";
import ContactSelect from "../Contact/ContactSelect";
import Status from "../Shared/Status";
import ProductSelect from "./ProductSelect";
import DefaultPage from "../Shared/DefaultPage";
import MultiContactSelect from "../Contact/MultiContactSelect";
import Coverages from "./Coverages";
import InvestmentPlans from "../InvestmentPlans";
import InvestmentPlanSelect from "../InvestmentPlans/InvestmentPlanSelect";
import Projection from "./Projection";
import Money from "../Shared/Money";
import Surcharges from "./Surcharges";
import Exclusions from "./Exclusions";
import Percentage from "../Shared/Percentage";
import Periodicty from "../Shared/Periodicty";
import PaymentMethods from "./PaymentMethods";
import PolicyDocs from "./PolicyDocs";
import RangeSelect from "../Shared/RangeSelect";
import Changes from "./Changes";
import FileUpload from "./FileUpload";
import Requirements from "./Requirements";
import PolicyHeader from "./PolicyHeader";
import { safeParse, safe, safeGet, safeValue } from "../../Lib/Dal";
import Events from "./Events";
import Accounts from "./Accounts";
import TabBadge from "../Shared/TabBadge";
import Section from "../Shared/Section";
import Currency from "../Shared/Currency";
import {
  getColor,
  formatDate,
  getModalPremium,
  copyToClipboard,
  parseFieldConfigString,
  setDate
} from "../../Lib/Helpers";
import FormLabel from "../Shared/FormLabel";
import PaymentPlan from "./PaymentPlan";
import ComContractSelect from "../Commissions/ComContractSelect";
import Related from "./Related";
import LobSelect from "../Shared/LobSelect";
import Comments from "./Comments";
import InvestmentSelection from "../Financial/InvestmentSelection";
import InvestmentStrategy from "../Financial/InvestmentStrategy";
import queryString from "query-string";
import PaymentDuration from "./PaymentDuration";
import { Link } from "react-router-dom";
import MainInsuredSelect from "../Contact/MainInsuredSelect";
import ExpiratiionField from "./ExpiratiionField";
import RiskAnalysis from "./RiskAnalysis";
import Contingent from "./Contingent";
import Operations from "../Financial/Operations";
import Anniversaries from "./Anniversaries";
import CessionBeneficiary from "./CessionBeneficiary";
import PolicyTypeSelect from "./PolicyTypeSelect";
import GroupCoverageHandlingSelect from "./GroupCoverageHandlingSelect";
import Certificates from "./Certificates";
import GroupPolicySelect from "./GroupPolicySelect";
import EntityTasks from "./EntityTasks";
import Clauses from "./Clauses";
import ContactTags from "../Contact/ContactTags";
import PolicyTags from "./PolicyTags";
import IndexationPlan from "./IndexationPlan";
import PaymentRequest from "../Payments/PaymentRequest";
import SellerSelect from "../Commissions/SellerSelect";
import TaxDetailField from "../Tax/TaxDetailField";
import InsuredObjects from "../Property/InsuredObjects";
import MonthSelect from "../Shared/MonthSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import { withTranslation } from "react-i18next";
import FeeDetailField from "./FeeDetailField";
import CoinsuranceSelect from "../Coinsurance/CoinsuranceSelect";
import PolicyCoinsurance from "../Coinsurance/PolicyCoinsurance";
import InstallmentSchemeSelect from "../Installments/InstallmentSchemeSelect";
import PolicyLink from "./PolicyLink";
import PlanOptionSelect from "./PlanOptionSelect";
import ParticipantRoleSelect from "../Commissions/ParticipantRoleSelect";
import SegmentSelect from "./SegmentSelect";
import ChannelSelect from "./ChannelSelect";
import GroupSelect from "./GroupSelect";
import CalcView from "../Shared/CalcView";
import ReceiptTypeSelect from "../Tax/ReceiptTypeSelect";
import DaySelect from "../Shared/DaySelect";
import ContactBranchSelect from "../Contact/ContactBranchSelect";
import CommissionDetailField from "./CommissionDetailField";
import FacultativeEditor from "../Contract/FacultativeEditor";
import BranchSelect from "../Financial/BranchSelect";
import CustomPolicyGroups from "./CustomPolicyGroups";
import UniqueBills from "./UniqueBills";
import CustomPlanOptions from "./CustomPlanOptions";
import ReCessionGraph from "../Re/ReCessionGraph";
import DatePickerW from "../Shared/DatePickerW";
import NewPolicyVersion from "./NewPolicyVersion";
import UmbrellaGroupSelect from "./UmbrellaGroupSelect";
import ClonePolicyOptions from "./ClonePolicyOptions";
import CoContractSelect from "../Coinsurance/CoContractSelect";
import PolicyRestrictions from "./PolicyRestrictions";
import RelationshipSelect from "../Contact/RelationsipSelect";
import SubLobSelect from "../Shared/SubLobSelect";

const { Paragraph, Title } = Typography;
const ButtonGroup = Button.Group;
const { Option } = Select;
const { Step } = Steps;

const EntityStates = [
  { name: "Draft", description: "Policy is in draft state and has no validity" },
  { name: "Aproval", subTitle: "Left 00:00:08", description: "Policy is ready for approval." },
  { name: "Actiive", description: "Policy is active and valid." },
  { name: "Inactive", description: "Policy is inactive and has no coverage." },
];
const hidesActivityPanel = ["tab3", "tab5", "tab12", "tab13"];

class LifePolicy extends Component {
  state = {
    loading: false,
    quoting: false,
    saving: false,
    currentStep: 1,
    entityState: "Draft",
    productConfig: undefined,
    policy: { Coverages: [] },
    operation: "ADD",
    collapseActivityPanel: false,
    numberOfComments: 0,
    processes: [],
    paymentRequestVisible: false,
    hasAdHocCommission: false,
    facultativeEditorVisible: false,
    cessionGraphVisible: false,
    customPolicyGroupsVisible: false,
    uniqueBillsVisible: false,
    planOptionsVisible: false,
    insuredObjectsTypes: [],
    showLabels: true,
    newVersionVisible: false,
    clonePolicyOptionsVisible: false,
    policyRestrictionsVisible: false,
  };
  componentDidMount() {
    this.baseState = this.state;
    if(this.props.snapshot){
      //loading after 1 sec to wait fro form mount
      this.setState({loading:true});
      setTimeout(()=>this.loadSnapshot(),1000);
      return;
    }

    this.load(this.props.match.params.policyId); //carga inicial
    // Check tab permissions when component mounts
    const currentTab = this.props.match.params.tab || 'tab1';
    this.props.checkTabPermissions(`/lifepolicy/${currentTab}`).then(authorized => {
      if (!authorized) {
        message.error(this.props.t("Your user does not have permission to access this tab"));
        // Redirect to the first tab or a default tab
        this.changeHash('tab1');
        this.setState({ currentStep: 1 });
      }
    });
    //conservacion de tab
    if (this.props.match.params.tab)
      this.setState({
        currentStep: +this.props.match.params.tab.split("tab")[1],
        collapseActivityPanel: hidesActivityPanel.includes(this.props.match.params.tab) ? true : this.state.hidesActivityPanel
      });
    if (window.location.hash.includes("oneclick=")) {
      const qs = queryString.parse(window.location.hash);
      console.log(qs, "QUERY");
      this.setOneClick(qs.oneclick);
    }

    this.loadProcesses();
    window.GLOBAL.checkGlobalIsLoaded().then(() => {
      const org=safeGet(["currentUser","Organization"],window.GLOBAL,undefined);
      this.setState({ organization: org });
    });
  }
  loadSnapshot(){
    const d = this.props.snapshot
    const config=this.props.config;
    const product=this.props.product;
    d.Product=product;
        if (!d) {
          message.error(`${this.props.t("Record not found")}`);
          return;
        }
        //extra
        d.productSelect = { key: d.productCode, label: product.name };
        d.start = setDate(d.start);
        if (d.end) d.end = setDate(d.end);
        if (d.proposalStartDate) d.proposalStartDate = setDate(d.proposalStartDate);
        if (d.proposalValidityDate) d.proposalValidityDate = setDate(d.proposalValidityDate);

        //if the policy is restricted, checking the current tab is not restricted
        if (d.restricted&&this.props.match.params.tab){
          this.props.checkTabPermissions(`/lifepolicy/${this.props.match.params.tab}`,true).then(authorized => {
            if (!authorized) {
              message.error(this.props.t("Your user does not have permission to access this tab"));
              // Stay on the current tab
              this.changeHash("tab1");
              this.setState({ 
                currentStep: 1, 
              });
            }
            this.props.form.setFieldsValue(d);
          })
        }else{
          console.log(d, "Field values");
          this.props.form.setFieldsValue(d);
        }

        this.setState({
          policy: d,
          operation: "UPDATE",
          productConfig: config,
          productLobCode: product.lobCode,
          productSubLobCode: product.subLobCode,
          contacts: d.Insureds,
          entityState: d.entityState,
          holderContact: d.Holder,
          hasAdHocCommission: d.ComContract && d.ComContract.isAdHoc,
          loading:false
        });
        //checking insured objects computed tab. when called from <PolicyLink tab param is not passed
        if (this.state.currentStep == 20) this.onTabClick("tab20", d);
        this.loadProcesses();
        window.GLOBAL.checkGlobalIsLoaded().then(() => {
        const org=safeGet(["currentUser","Organization"],window.GLOBAL,undefined);
        this.setState({ organization: org });
        //loading missing entities
        exe("GetContacts", { filter: `id=${d.holderId}` }).then((r) => {
          this.setState({ holderContact: r.outData[0],policy: { ...this.state.policy, Holder: r.outData[0] } });
        });
    });
  }

  setOneClick(code) {
    if (!this.props.configProfile) {
      console.error("config profile could not be parsed");
      return;
    }
    const oneClick = this.props.configProfile.OneClick.find((p) => p.code == code);
    console.log("Setting 1Click", oneClick);
    this.props.form.setFieldsValue({
      productSelect: { key: oneClick.productCode, label: oneClick.title + " 1-Click" },
      insuredSum: oneClick.insuredSum,
      plan: oneClick.plan,
      start: setDate(),
    });
    this.productChange({ key: oneClick.productCode }).then((r) => {
      this.props.form.setFieldsValue({ investmentStrategy: oneClick.strategy });
      if (oneClick.strategy) {
        this.onInvestmentStrategyChange(oneClick.strategy);
      }
      if (oneClick.plan) {
        this.onPlanChange(oneClick.plan);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.snapshot||this.props.snapshot) return;
    if (prevProps.match.params.policyId !== this.props.match.params.policyId) this.load(this.props.match.params.policyId); //update de url
    if (prevProps.match.params.tab !== this.props.match.params.tab && this.props.match.params.tab) {
      // Check tab permissions before changing tab when user changes manually url tab
      this.props.checkTabPermissions(`/lifepolicy/${this.props.match.params.tab}`,safeGet(["policy","restricted"],this.state,false)).then(authorized => {
        if (!authorized) {
          message.error(this.props.t("Your user does not have permission to access this tab"));
          // Stay on the current tab
          this.changeHash(`tab${this.state.currentStep}`);
          return;
        }
        
        // If authorized, proceed with tab change
        this.setState({
          currentStep: +this.props.match.params.tab.split("tab")[1],
          collapseActivityPanel: hidesActivityPanel.includes(this.props.match.params.tab) ? true : this.state.collapseActivityPanel
        });
      });
    }
  }
  changeHash(tab) {
    let newHash = window.location.hash;
    newHash = newHash.split("/tab")[0] + "/" + tab;
    window.history.replaceState(null, null, newHash);
  }
  async onSave() {
    const values = await this.getValues();
    if (values) {
      this.setState({ loading: true, saving: true });
      exe("RepoLifePolicy", { operation: this.state.operation, entity: values }).then((r) => {
        this.setState({ loading: false, saving: false });
        if (r.ok) {
          message.success(r.msg);
          if (r.data.operation == "ADD") {
            window.location.hash = "#/lifePolicy/" + r.outData[0].id;
          } else {
            this.reload();
          }
        } else {
          message.error(r.msg);
        }
      });
    }
  }
  getValues() {
    return new Promise((resolve) => {
      this.props.form.validateFields((err, values) => {
        console.log(values, "FORM VALUES");
        if (!err) {
          if (values.policyType !== "G" && values.MainInsured.contactId == 0) {
            message.error(this.props.t("Please specify main insured"));
            return;
          }
          //validating cession beneficiary custom form if present
           //validating custom form
           if (window.customCessionBeneficieryForm &&window.customCessionBeneficieryForm.instanceContainers&& window.customCessionBeneficieryForm.instanceContainers[0]) {
            if (window.customCessionBeneficieryForm.instanceContainers[0].reportValidity()) {
                values.jCessionBeneficiary = JSON.stringify(window.customCessionBeneficieryForm.userData);
            } else {
                message.error(this.props.t("Please check cession beneficiary form validation errors"));
                this.setState({ currentStep: 5 });//tab5 is the beneficiary tab
                this.changeHash("tab5");
                //resolving
                resolve(null);
                return;
            }
        }
          //validation OK
          values.productCode = values.productSelect.key;
          values.Coverages = this.state.policy.Coverages;

          //resolved in backend
          values.Coverages.forEach((p) => {
            if (isNaN(p.limit)) p.limit = 0;
            if (isNaN(p.premium)) p.premium = 0;
            if (isNaN(p.deductible)) p.deductible = 0;
            if (isNaN(p.ofnCode)) p.ofnCode = 0;
            if (isNaN(p.extraPremium)) p.extraPremium = 0;
            if (p.start && p.start.toString().includes("_")) p.start = "";
            if (p.end && p.end.toString().includes("_")) p.end = "";
            if (p.benefits) {
              p.benefits.forEach((b) => {
                b.coverageCode = p.code;
                const nonNumericFields = ["code", "coverageCode", "name", "description", "group", "group2", "rule"];
                Object.keys(b)
                  .filter((f) => !nonNumericFields.includes(f))
                  .forEach((k) => {
                    if (isNaN(b[k])) b[k] = 0;
                  });
              });
            }
          });
          values.Holder = this.state.Holder; //updated object
          if (values.MainInsured) values.MainInsured.Contact = null; //to avoid explciit insertion to contacts
          if (!values.Beneficiaries) values.Beneficiaries = [];
          values.Beneficiaries.forEach((p) => {
            p.Contact = null; //to avoid explciit insertion to contacts
          });
          if (!values.Coinsureds) values.Coinsureds = [];
          values.Coinsureds.forEach((p) => {
            p.role = 1;
          });
          values.Insureds = [values.MainInsured, ...values.Coinsureds.filter((p) => p.role !== 0)];
          resolve(values);
        } else {
          //validation error
          console.log(err);
          message.error(this.props.t("Missing fields. Please fill all mandatory fields."));
          resolve(null);
        }
      });
    });
  }

  load = (policyId) => {
    console.log("Loading " + policyId);
    if (policyId == 0 || policyId == undefined) {
      this.props.form.resetFields();
      this.setState(this.baseState);
      return;
    }
    this.setState({ loading: true });
    exe("RepoLifePolicy", {
      operation: "GET",
      filter: "id=" + policyId,
      include: [
        "Holder",
        "Process",
        "Product",
        "Insureds",
        "Coverages",
        "Coverages.Loadings",
        "Coverages.Benefits",
        "Insureds.Contact",
        "Insureds.Contact.Occupation",
        "Surcharges",
        "Exclusions",
        "Clauses",
        "Documents",
        "PayPlan",
        "InvestmentPlan",
        "InvestmentSelections",
        "Beneficiaries",
        "Beneficiaries.Contact",
        "Requirements",
        "Events",
        "Accounts",
        "Accounts.Movements",
        "Accounts.PendingMovements",
        "Changes",
        "Changes.Bill",
        "Changes.BillUnique",
        "Changes.CancellationBill",
        "Changes.BillDiff",
        "Changes.Process",
        "Changes.Surcharges",
        "ComContract",
        "RiskAnalysis",
        "Tags",
        "Contingents",
        "Anniversaries",
      ],
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        const d = r.outData[0];
        if (!d) {
          message.error(`${this.props.t("Record")} ${policyId} ${this.props.t("not found")}`);
          return;
        }
        //extra
        d.productSelect = { key: d.productCode, label: d.Product.name };
        d.start = setDate(d.start);
        if (d.end) d.end = setDate(d.end);
        if (d.proposalStartDate) d.proposalStartDate = setDate(d.proposalStartDate);
        if (d.proposalValidityDate) d.proposalValidityDate = setDate(d.proposalValidityDate);

        //if the policy is restricted, checking the current tab is not restricted
        if (d.restricted&&this.props.match.params.tab){
          this.props.checkTabPermissions(`/lifepolicy/${this.props.match.params.tab}`,true).then(authorized => {
            if (!authorized) {
              message.error(this.props.t("Your user does not have permission to access this tab"));
              // Stay on the current tab
              this.changeHash("tab1");
              this.setState({ 
                currentStep: 1, 
              });
            }
            this.props.form.setFieldsValue(d);
          })
        }else{
          console.log(d, "Field values");
          this.props.form.setFieldsValue(d);
        }

        this.setState({
          policy: d,
          operation: "UPDATE",
          productConfig: JSON.parse(d.Product.configJson),
          productLobCode: d.Product.lobCode,
          productSubLobCode: d.Product.subLobCode,
          contacts: d.Insureds,
          entityState: d.entityState,
          holderContact: d.Holder,
          hasAdHocCommission: d.ComContract && d.ComContract.isAdHoc
        });
        //checking insured objects computed tab. when called from <PolicyLink tab param is not passed
        if (this.state.currentStep == 20) this.onTabClick("tab20", d);
      } else {
        message.error(r.msg);
      }
    });
  };
  loadProcesses = () => {
    exe("GetDefinitions", { filter: "entity='lifePolicy' and enabled=1" }).then((r) => {
      this.setState({ processes: r.outData || [] });
    });
  };
  reload() {
    this.load(this.state.policy.id);
  }
  computeTitle(values) {
    const titleformula = safeGet(["productConfig", "Main", "title"], this.state, undefined);
    let computedTitle;
    if (titleformula) computedTitle = this.computeFormula(titleformula, values);
    return computedTitle;
  }
  onNext() {
    this.setState({ currentStep: this.state.currentStep + 1 }, () => this.changeHash("tab" + this.state.currentStep));
  }
  onTabClick(tab, policy) {
    // Check tab permissions before changing tab
    this.props.checkTabPermissions(`/lifepolicy/${tab}`,policy&&policy.restricted).then(authorized => {
      if (!authorized) {
        message.error(this.props.t("Your user does not have permission to access this tab"));
        return;
      }
      
      // If authorized, proceed with tab change
      this.changeHash(tab);
      if (tab === "tab20") {
        //Insured objects tab. Computing insured objects condition
        const types = this.computeInsuredObjectsTypes(policy);
        this.setState({ 
          currentStep: +tab.split("tab")[1], 
          collapseActivityPanel: hidesActivityPanel.includes(tab) ? true : this.state.collapseActivityPanel, 
          insuredObjectsTypes: types 
        });
      } else {
        this.setState({ 
          currentStep: +tab.split("tab")[1], 
          collapseActivityPanel: hidesActivityPanel.includes(tab) ? true : this.state.collapseActivityPanel 
        });
      }
    });
  }
  onNew() {
    window.location.hash = "#/lifePolicy/0";
    this.props.form.resetFields();
  }
  onDelete() {
    exe("RepoLifePolicy", { operation: "DELETE", entity: this.state.policy }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        window.location.hash = "#/home";
      } else {
        message.error(r.msg);
      }
    });
  }
  onHolderChange(v) {
    if (v) {
      const mainInsured = this.props.form.getFieldValue("MainInsured");
      const policyType = this.props.form.getFieldValue("policyType");
      const defaultMainInsured = safeGet(["Main", "defaultNonHumanMainInsured"], this.state.productConfig, false);
      if (!mainInsured && (defaultMainInsured || v.contact.isPerson) && policyType == "I") {
        //if no insureds selected, selecting default insured (holder)
        const defaultInsured = { id: 0, contactId: v.key, name: v.label, relationship: 0, Contact: v.contact };
        this.props.form.setFieldsValue({ MainInsured: defaultInsured });
      }
      if (policyType == "G") {
        //temp for group policies holder=main insured
        const defaultInsured = { id: 0, contactId: v.key, name: v.label, relationship: 0, Contact: v.contact };
        this.props.form.setFieldsValue({ MainInsured: defaultInsured });
      }
      //if not already set, setting default payer=policyholder
      const payerId = this.props.form.getFieldValue("payerId");
      if (!payerId) this.props.form.setFieldsValue({ payerId: v.key });

      //setting default fiscal doc type for holder, if any
      if (v.contact.receiptTypeCode) {
        this.props.form.setFieldsValue({ receiptTypeCode: v.contact.receiptTypeCode });
        //this.onReceiptTypeSelect(v.contact.receiptTypeCode); //assigns fiscal number. Number preview disabled
      } else this.props.form.setFieldsValue({ receiptTypeCode: undefined, fiscalNumber: undefined });

      this.setState({ holderContact: v.contact, holderId: v.id });
    } else {
      this.setState({ holderContact: null, holderId: null });
      this.props.form.setFieldsValue({ receiptTypeCode: undefined, fiscalNumber: undefined });
    }
  }
  onPolicyTypeChange(value) {
    if (value === "G") {
      const mainInsured = this.props.form.getFieldValue("MainInsured");
      const holderId = this.props.form.getFieldValue("holderId");
      if (!mainInsured && holderId || mainInsured && !mainInsured.contactId && holderId) {
        //temp for group policies holder=main insured
        const defaultInsured = { id: 0, contactId: this.state.holderContact.id, name: this.state.holderContact.FullName, relationship: 0, Contact: this.state.holderContact };
        this.props.form.setFieldsValue({ MainInsured: defaultInsured });
      }
    }
  }
  onProductChange(v) {
    if (!v) return;
    this.productChange(v);
  }
  productChange = (v) =>
    new Promise((resolve, reject) => {
      exe("RepoProduct", { filter: `code='${v.key}'`, operation: "GET" }).then((r) => {
        const loadedProduct = JSON.parse(r.outData[0].configJson);
        loadedProduct.Coverages.forEach((p) => (p.loading = 0)); //fix for old loading configurations. Should use Loading section
        loadedProduct.Coverages = loadedProduct.Coverages.sort((a, b) => (a.number > 0 ? a.number - b.number : 100));
        this.setState(
          {
            productConfig: loadedProduct,
            policy: { ...this.state.policy, Coverages: [] },
            productLobCode: r.outData[0].lobCode,
            productSubLobCode: r.outData[0].subLobCode,
          },
          () => {
            const myOrganizationId=safeGet(["organization","id"],this.state,0);
            this.props.form.setFieldsValue({
              Surcharges: this.state.productConfig.Surcharges ? this.state.productConfig.Surcharges.filter((p) => p.mandatory == 1) : [],
              Exclusions: this.state.productConfig.Exclusions ? this.state.productConfig.Exclusions.filter((p) => (!p.organizationIds||myOrganizationId==0||p.organizationIds.includes(myOrganizationId))&&p.mandatory == 1) : [],
              lob: r.outData[0].lobCode,
              currency: this.state.productConfig.Main.currency ? this.state.productConfig.Main.currency : undefined,
              duration: this.state.productConfig.Main.duration ? this.state.productConfig.Main.duration : 0,
              durationMonths: this.state.productConfig.Main.durationMonths ? this.state.productConfig.Main.durationMonths : 0,
              durationDays: this.state.productConfig.Main.durationDays ? this.state.productConfig.Main.durationDays : 0,
              ifrs17Code: safeGet(["productConfig", "Main", "ifrs17Code"], this.state, undefined),
              masterCode: safeGet(["productConfig", "Main", "masterCode"], this.state, undefined),
              commercial: safeGet(["productConfig", "Main", "commercial"], this.state, undefined),
              version: safeGet(["productConfig", "Main", "version"], this.state, undefined),
              comContractId: this.state.policy.comContractId
                ? this.state.policy.comContractId
                : safeGet(["productConfig", "Commissions", "default"], this.state, undefined),
            });
            const currentSelectedPlan = this.props.form.getFieldValue("plan");
            if (currentSelectedPlan) this.onPlanChange(currentSelectedPlan);
            //already selected by user
            else {
              const defaultPlan = safeGet(["Main", "defaultPlan"], loadedProduct, undefined);
              if (defaultPlan) {
                this.props.form.setFieldsValue({ plan: defaultPlan });
                this.onPlanChange(defaultPlan);
              }
            }

            if (this.state.productConfig.Main.duration || this.state.productConfig.Main.durationMonths || this.state.productConfig.Main.durationDays) {
              const start = this.props.form.getFieldValue("start");
              if (start) {
                this.onStartChange(start);
              }
            }
            resolve();
          }
        );
      });
    });
  onPlanChange(v) {
    if (!v) {
      this.props.form.setFieldsValue({
        duration: this.state.productConfig.Main.duration ? this.state.productConfig.Main.duration : 0,
        durationMonths: this.state.productConfig.Main.durationMonths ? this.state.productConfig.Main.durationMonths : 0,
        durationDays: this.state.productConfig.Main.durationDays ? this.state.productConfig.Main.durationDays : 0,
      });
      const start = this.props.form.getFieldValue("start");
      if (start) {
        this.onStartChange(start);
      }

      return;
    }
    const plan = this.state.productConfig.Plans.find((p) => p.code == v);
    if (!plan) return;

    this.props.form.setFieldsValue({
      insuredSum: plan.sumInsured,
    });
    if (plan.coverages) {
      const planCoverages = [...this.state.productConfig.Coverages.filter((p) => plan.coverages.includes(p.code))];
      if (plan.closed) planCoverages.forEach((p) => (p.mandatory = true));
      else planCoverages.forEach((p) => (p.mandatory = false));
      //checking if basic coverage is defined in plan.
      if(plan.basic){
        const basicCoverage = planCoverages.find(p=>p.code==plan.basic);
        if(basicCoverage){
          planCoverages.forEach(p=>{p.basic=p.code==basicCoverage.code;p.mandatory=p.code==basicCoverage.code;});
        }
      }
      this.setState({ policy: { ...this.state.policy, Coverages:planCoverages,plan:plan.code } });
    }
    this.setState({ closedPlan: plan.closed });
    if (plan.duration !== undefined) {
      this.props.form.setFieldsValue({
        duration: plan.duration,
      });
      const start = this.props.form.getFieldValue("start");
      if (start) {
        this.onStartChange(start);
      }
    }
    if (plan.durationMonths !== undefined) {
      this.props.form.setFieldsValue({
        durationMonths: plan.durationMonths,
      });
      const start = this.props.form.getFieldValue("start");
      if (start) {
        this.onStartChange(start);
      }
    }
    if (plan.durationDays !== undefined) {
      this.props.form.setFieldsValue({
        durationDays: plan.durationDays,
      });
      const start = this.props.form.getFieldValue("start");
      if (start) {
        this.onStartChange(start);
      }
    }
  }
  onStartResetTime(){
    const startInput = document.getElementById("start");
    const v = new moment(startInput.value);
    const defaultFutureStartTime="00:00:00";
    const hour = defaultFutureStartTime.split(":")[0];
    const minute = defaultFutureStartTime.split(":")[1];
    const second = defaultFutureStartTime.split(":")[2];
    v.set({ hour: hour, minute: minute, second: second });
    this.props.form.setFieldsValue({ start: v.clone() });
    this.onStartChange(v.clone());

    startInput.value=v.format("YYYY-MM-DD HH:mm:ss");
  }
  onStartChange(v) {
    if (v) {
      //checking default start time if start time is not set yet
      const currentStart = this.props.form.getFieldValue("start");
      if (!currentStart) {
        const defaultStart = safeGet(["Main", "defaultStartTime"], this.state.productConfig, undefined);
        if (defaultStart) {
          const hour = defaultStart.split(":")[0];
          const minute = defaultStart.split(":")[1];
          const second = defaultStart.split(":")[2];
          v.set({ hour: hour, minute: minute, second: second });
        }
      }
      // const defaultFutureStartTime = safeGet(["Main", "defaultFutureStartTime"], this.state.productConfig, null);
      // if (defaultFutureStartTime) {
      // //if selected date is in the future, setting time to default future start time
      // if(v.isAfter(moment())){
      // //if set, setting time to specified time
      //   const hour = defaultFutureStartTime.split(":")[0];
      //   const minute = defaultFutureStartTime.split(":")[1];
      //   const second = defaultFutureStartTime.split(":")[2];
      //   v.set({ hour: hour, minute: minute, second: second });
      //   this.props.form.setFieldsValue({ start: v.clone() });
      // }
      // }

      const duration = this.props.form.getFieldValue("duration") || 0;
      const durationMonths = this.props.form.getFieldValue("durationMonths") || 0;
      const durationDays = this.props.form.getFieldValue("durationDays") || 0;
      const durationOffset = safeGet(["Main", "durationOffset"], this.state.productConfig, 0);
      const durationOffsetSeconds = safeGet(["Main", "durationOffsetSeconds"], this.state.productConfig, 0);

      if (duration || durationMonths || durationDays) {
        this.props.form.setFieldsValue({ end: v.clone().add(duration, "y").add(durationMonths, "M").add(durationDays, "days").add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
      }
      else {
        this.props.form.setFieldsValue({ end: undefined });
      }
    }

  }

  onDurationChange(v) {
    const start = this.props.form.getFieldValue("start");
    const years = v ? v : 0;
    const months = this.props.form.getFieldValue("durationMonths");
    const days = this.props.form.getFieldValue("durationDays");
    const durationOffset = safeGet(["Main", "durationOffset"], this.state.productConfig, 0);
    const durationOffsetSeconds = safeGet(["Main", "durationOffsetSeconds"], this.state.productConfig, 0);

    if (start && (months || days)) {
      const mAdded = start.clone().add(years, "y").add(months, "M").add(days, "days");
      this.props.form.setFieldsValue({ end: mAdded.add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
    } else if (start) {
      this.props.form.setFieldsValue({ end: start.clone().add(years, "y").add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
    }
  }
  onDurationMonthsChange(v) {
    const start = this.props.form.getFieldValue("start");
    const years = this.props.form.getFieldValue("duration");
    const months = v ? v : 0;
    const days = this.props.form.getFieldValue("durationDays");
    const durationOffset = safeGet(["Main", "durationOffset"], this.state.productConfig, 0);
    const durationOffsetSeconds = safeGet(["Main", "durationOffsetSeconds"], this.state.productConfig, 0);

    if (start && (years || days)) {
      const yAdded = start.clone().add(years, "y").add(months, "M").add(days, "days");
      this.props.form.setFieldsValue({ end: yAdded.add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
    } else if (start) {
      this.props.form.setFieldsValue({ end: start.clone().add(months, "M").add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
    }
  }
  onDurationDaysChange(v) {
    const start = this.props.form.getFieldValue("start");
    const years = this.props.form.getFieldValue("duration");
    const months = this.props.form.getFieldValue("durationMonths");
    const days = v ? v : 0;
    const durationOffset = safeGet(["Main", "durationOffset"], this.state.productConfig, 0);
    const durationOffsetSeconds = safeGet(["Main", "durationOffsetSeconds"], this.state.productConfig, 0);

    if (start && (years || months)) {
      const yAdded = start.clone().add(years, "y").add(months, "M").add(days, "days");
      this.props.form.setFieldsValue({ end: yAdded.add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
    } else if (start) {
      this.props.form.setFieldsValue({ end: start.clone().add(days, "days").add(durationOffset, 'days').add(durationOffsetSeconds, "seconds") });
    }
  }
  onCoverageChange(v) {
    //clearing formula fields. Computed in backend.
    v.forEach((p) => {
      if (isNaN(p.limit)) p.limit = 0;
      if (isNaN(p.loading)) p.loading = 0;
      if (isNaN(p.extraPremium)) p.extraPremium = 0;
      if (isNaN(p.premium)) p.premium = 0;
      if (isNaN(p.internalPremium)) p.internalPremium = 0;
      if (isNaN(p.deductible)) p.deductible = 0;
      if (isNaN(p.periodicity)) p.periodicity = 0;
      if (!moment(p.start).isValid()) p.start = undefined;
      if (!moment(p.end).isValid()) p.end = undefined;
      if (p.benefits) {
        p.benefits.forEach((b) => {
          const nonNumericFields = ["code", "coverageCode", "name", "description", "group", "group2", "rule"];
          Object.keys(b)
            .filter((f) => !nonNumericFields.includes(f))
            .forEach((k) => {
              if (isNaN(b[k])) b[k] = 0;
            });
        });
      }
    });
    const policy = { ...this.state.policy, Coverages: v };
    this.setState({ policy });
  }

  onIssue() {
    this.setState({ loading: true });
    exe("IssuePolicy", { policyId: this.state.policy.id }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.setState({ policy: { ...this.state.policy, Process: r.outData[0].Process, active: true } });
        this.props.form.setFieldsValue({ ...r.outData[0], start: setDate(r.outData[0].start), end: setDate(r.outData[0].end) });
      } else {
        message.error(r.msg);
      }
    });
  }
  async onQuote(action = "QUOTE") {
    let policy;
    policy = await this.getValues();
    if (!policy) return; //validation error

    if (this.state.policy.id) policy = { ...this.state.policy, ...policy };
    policy.Product = null; //not sending over network

    this.setState({ loading: true, quoting: true });
    exe("QuotePolicy", { policyId: this.state.policy.id, policy, dbMode: false, save: true, action: action }).then((r) => {
      this.setState({ loading: false, quoting: false });
      if (r.ok) {
        message.success(r.msg);
        this.setState({
          policy: {
            ...policy,
            ...r.outData[0],
            start: policy.start,
            Coverages: r.outData[0].Coverages,
            Surcharges: r.outData[0].Surcharges,
            Documents: r.outData[0].Documents,
          },
        });
        this.props.form.setFieldsValue({
          ...r.outData[0],
          start: setDate(r.outData[0].start),
          end: setDate(r.outData[0].end),
          proposalStartDate: setDate(r.outData[0].proposalStartDate),
          proposalValidityDate: setDate(r.outData[0].proposalValidityDate),
          Surcharges: r.outData[0].Surcharges,
          Documents: r.outData[0].Documents,
        });
      } else {
        message.error(r.msg);
      }
    });
  }
  quoteOnEventBus = () => {
    this.setState({ loading: true });
    exe("PutMessage", {
      batch: "Policy",
      value: `{cmd:"QuotePolicy",data:{policyId:"${this.state.policy.id}",dbMode:true,save:true,action:"PREQUOTE"}}`,
      notify: true
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success("Operation succesfuly sent to event bus");
      } else message.error(r.msg);
    });
  }
  onAddDocument(files) {
    const currentFiles = this.props.form.getFieldValue("Documents");
    this.props.form.setFieldsValue({ Documents: [...currentFiles, ...files] }, () => this.onSave());
  }
  onChangePeriodicty = (v) => {
    if (!v) return;
    const values = this.props.form.getFieldsValue();
    values.periodicity = v;
    if (!values.anualTotal) return; //not set yet

    values.installment = getModalPremium(v, values.anualTotal);

    this.props.form.setFieldsValue(values);
  };
  onInvestmentStrategyChange = (v) => {
    const investmentStrategies = this.state.productConfig ? this.state.productConfig.InvestmentStrategies : [];
    if (!investmentStrategies) return;
    const selectedStrategy = investmentStrategies.filter((p) => p.code == v);
    if (selectedStrategy.length == 1) this.props.form.setFieldsValue({ InvestmentSelections: selectedStrategy[0].selections });
  };
  onCollapseActivityPanel = () => {
    this.setState({ collapseActivityPanel: !this.state.collapseActivityPanel });
  };
  onClaim() {
    if (!this.state.policy.active) {
      message.error(this.props.t("Policy must be active in order to open a claim"));
      return;
    }

    window.location.hash = "#/healthclaim/0?&policy=" + this.state.policy.id;
  }
  computeFormula(f, polValues = undefined) {
    try {
      if (typeof f == "boolean") return f;
      const context = {};
      context.pol = polValues ? polValues : this.state.policy;
      context.cfg = this.state.productConfig;
      context.pol.Plan = (context.cfg.Plans || []).find((p) => p.code == context.pol.plan); //helper

      f = f.replaceAll("_", "context.");
      console.log(f, context, "CONTEXT");
      return eval(f);
    } catch (error) {
      console.log("Unable to evaluate formula client side", error);
      return undefined;
    }
  }
  resolvePath = (object, path, defaultValue) => path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

  getConfig(field) {
    if (this.state.productConfig) {
      const fieldValue = this.resolvePath(this.state.productConfig, field, undefined);
      if (fieldValue == undefined) return undefined;
      if (fieldValue.toString().indexOf("_") > -1) {
        return this.computeFormula(fieldValue);
      } else {
        return fieldValue;
      }
    } else {
      return undefined;
    }
  }
  mainInsuredValidator(rule, value, cb) {
    this.state.policy.MainInsured = value; // test purposes
    const holderId = this.props.form.getFieldValue("holderId");
    console.log(value, holderId, "VALIDATOR MAIN INSURED");
    if (!value) return cb(this.props.t("Please select main insured"));
    if (!value.relationship && value.contactId && +value.contactId !== +holderId) return cb(this.props.t("Please select relationship")); //when same as policyholder it can be null
    if (value.relationship && !value.contactId) return cb(this.props.t("Please select main insured"));
    const mainInsuredRule = this.getConfig("Main.mainInsuredRule");
    if (mainInsuredRule !== undefined && mainInsuredRule !== "") {
      return cb(mainInsuredRule);
    }
    return cb();
  }
  coinsuredValidator(rule, value, cb) {
    if (!value) return cb(); //no coinsureds
    if (value.some((p) => p.contactId > 0 && !p.relationship)) return cb(this.props.t("Please select relationship"));
    if (value.some((p) => !p.contactId && p.relationship)) return cb(this.props.t("Please select coinsured"));
    return cb();
  }
  durationValidator(rule, value, cb, unit = "y") {
    this.state.policy.MainInsured = this.props.form.getFieldValue("MainInsured"); //fix for main insured dependandt formulas
    const minDuration = this.getConfig("Main.minDuration");
    const maxDuration = this.getConfig("Main.maxDuration");
    let errMsg = undefined;

    const yearsValue = (unit !== "y" ? this.props.form.getFieldValue("duration") : value) || 0;
    const monthsValue = (unit !== "m" ? this.props.form.getFieldValue("durationMonths") : value) || 0;
    const daysValue = (unit !== "d" ? this.props.form.getFieldValue("durationDays") : value) || 0;

    if (maxDuration !== undefined) {
      if (maxDuration.toString().includes("m") && maxDuration.toString().includes("d")) {
        const normalizedMaxDuration = maxDuration.replace("d", "m");
        const maxYears = +normalizedMaxDuration.split("m")[0];
        const maxMonths = +normalizedMaxDuration.split("m")[1];
        const maxDays = +normalizedMaxDuration.split("m")[2];
        if (yearsValue * 10000 + monthsValue * 100 + daysValue > maxYears * 10000 + maxMonths * 100 + maxDays)
          errMsg = this.props.t("Value must be lower or equal to ") + maxDuration + " " + this.props.t("for this plan");
      } else if (maxDuration.toString().includes("m")) {
        const maxYears = +maxDuration.split("m")[0];
        const maxMmonths = +maxDuration.split("m")[1];
        console.log(yearsValue * 100 + monthsValue, maxYears * 100 + maxMmonths);
        if (yearsValue * 100 + monthsValue > maxYears * 100 + maxMmonths)
          errMsg = this.props.t("Value must be lower or equal to ") + maxDuration + " " + this.props.t("for this plan");
      } else {
        if (yearsValue > maxDuration) errMsg = this.props.t("Value must be lower or equal to ") + maxDuration + " " + this.props.t("for this plan");
      }
    }

    if (minDuration !== undefined) {
      if (minDuration.toString().includes("m") && minDuration.toString().includes("d")) {
        const normalizedMinDuration = minDuration.replace("d", "m");
        const minYears = +normalizedMinDuration.split("m")[0];
        const minMonths = +normalizedMinDuration.split("m")[1];
        const minDays = +normalizedMinDuration.split("m")[2];
        if (yearsValue * 10000 + monthsValue * 100 + daysValue < minYears * 10000 + minMonths * 100 + minDays)
          errMsg = this.props.t("Value must be greater or equal to ") + minDuration + " " + this.props.t("for this plan");
      } else
        if (minDuration.toString().includes("m")) {
          const minYears = +minDuration.split("m")[0];
          const minMmonths = +minDuration.split("m")[1];
          if (yearsValue * 100 + monthsValue < minYears * 100 + minMmonths)
            errMsg = this.props.t("Value must be greater or equal to ") + minDuration + " " + this.props.t("for this plan");
        }
        else {
          if (yearsValue < minDuration) errMsg = this.props.t("Value must be greater or equal to ") + minDuration + " " + this.props.t("for this plan");
        }
    }

    return cb(errMsg);
  }
  onLaunchTask(taskId) { }
  onGroupPolicySelect = (pol) => {
    this.setState({ groupPolicy: pol });
    if (this.state.policy.active) return; //select triggered on GroupPolicySelect , no need to fire change event
    this.props.form.setFieldsValue({ holderId: pol.holderId, planOptions: pol.planOptions });
    if (pol.planOptions) this.onPlanOptionsChange(pol.planOptions); //selecting same plan options as master policy
    this.onHolderChange({ key: pol.holderId, label: pol.Holder.FullName, contact: pol.Holder });
  };
  onClone(options) {
    this.setState({ loading: true });
    exe("ClonePolicy", {
      policyId: this.state.policy.id,
      skipCertificates: options && !options.includeCertificates,
      skipInactiveCertificates: options && options.skipInactiveCertificates,
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load(r.outData[0].id);
        window.history.replaceState(null, null, "#/lifePolicy/" + r.outData[0].id);
        //window.location.hash = "#/lifepolicy/" + r.outData[0].id;
      } else {
        message.error(r.msg);
      }
    });
  }
  onNewVersion(newPolicy) {
    this.load(newPolicy.id);
    window.history.replaceState(null, null, "#/lifePolicy/" + newPolicy.id);
    this.setState({ newVersionVisible: false });
  }
  onIndexationPlanOk() {
    //checking affected period
    const jPlan = this.props.form.getFieldValue("jIndexationPlan");
    const period = this.props.form.getFieldValue("indexationPeriod");
    const plan = JSON.parse(jPlan);
    let lastYear = 0;
    plan.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "year") {
          if (row[key]) lastYear = row.year;
        }
      });
    });
    if (lastYear !== period) this.props.form.setFieldsValue({ indexationPeriod: lastYear });
    this.setState({ indexationPlanVisible: false });
  }
  onChangeIndexationPeriod(indexationPeriod) {
    const duration = this.props.form.getFieldValue("duration");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      this.state.policy.Coverages.forEach((cov) => {
        obj[cov.code] = index + 1 <= indexationPeriod ? true : false;
      });
      obj.year = index + 1;
      return obj;
    });
    this.props.form.setFieldsValue({ jIndexationPlan: JSON.stringify(buildPlan) });
  }
  onChangeIndexationPeriodWithFrequency(indexationPeriod) {
    if (this.state.policy.Coverages.length === 0) return;
    const duration = this.props.form.getFieldValue("duration");
    const start = this.props.form.getFieldValue("indexationStart");
    const frequency = this.props.form.getFieldValue("indexationFrequency");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      this.state.policy.Coverages.forEach((cov) => {
        if (((index + 1) <= indexationPeriod) && ((index + 1) >= start)) {
          if ((index + 1 - start) % frequency === 0) obj[cov.code] = true;
        } else obj[cov.code] = false;
      });
      obj.year = index + 1;
      return obj;
    });
    this.props.form.setFieldsValue({ jIndexationPlan: JSON.stringify(buildPlan) });
  }
  onChangeIndexationStart(start) {
    const duration = this.props.form.getFieldValue("duration");
    const indexationPeriod = this.props.form.getFieldValue("indexationPeriod");
    const frequency = this.props.form.getFieldValue("indexationFrequency");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      this.state.policy.Coverages.forEach((cov) => {
        if (((index + 1) <= indexationPeriod) && ((index + 1) >= start)) {
          if ((index + 1 - start) % frequency === 0) obj[cov.code] = true;
        } else obj[cov.code] = false;
      });
      obj.year = index + 1;
      return obj;
    });
    this.props.form.setFieldsValue({ jIndexationPlan: JSON.stringify(buildPlan) });
  }
  onChangeIndexationFrequency(frequency) {
    const duration = this.props.form.getFieldValue("duration");
    const indexationPeriod = this.props.form.getFieldValue("indexationPeriod");
    const start = this.props.form.getFieldValue("indexationStart");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      this.state.policy.Coverages.forEach((cov) => {
        if (((index + 1) <= indexationPeriod) && ((index + 1) >= start)) {
          if ((index + 1 - start) % frequency === 0) obj[cov.code] = true;
        } else obj[cov.code] = false;
      });
      obj.year = index + 1;
      return obj;
    });
    this.props.form.setFieldsValue({ jIndexationPlan: JSON.stringify(buildPlan) });
  }
  onSelectIndexationType(v) {
    if (v === 2) {
      //limited, building initial matrix
      const period = this.props.form.getFieldValue("indexationStart");
      this.onChangeIndexationPeriod(period);
    }
    if (v === 3) {
      //frequency, building initial matrix
      const start = this.props.form.getFieldValue("indexationStart");
      this.onChangeIndexationStart(start);
    }
  }
  onPaymentRequest() {
    this.setState({ paymentRequestVisible: true });
  }
  onCancelPaymentRequest() {
    this.setState({ paymentRequestVisible: false });
  }
  onOkPaymentRequest() {
    this.setState({ paymentRequestVisible: false });
    this.reload();
  }
  // onDailyUpdate() {
  //   this.setState({ loading: true });
  //   exe("DoDailyUpdate", { policyId: this.state.policy.id }).then((r) => {
  //     this.setState({ loading: false });
  //     if (r.ok) {
  //       message.success(this.props.t("Daily update process executed. Please check supervisor log for result detail"));
  //     } else {
  //       message.error(this.props.t("Error executing daily update process. Please check supervisor log for result detail"));
  //     }
  //   });
  // }
  onDailyUpdate() {
    this.setState({ loading: true });
    exe("PutMessage", {
      batch: "Actuarial",
      value: `{cmd:"DoDailyUpdate",data:{policyId:${this.state.policy.id}}}`,
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success("Actuarial operation successfully sent to event bus");
      } else message.error(r.msg);
    });
  }
  onTemporalStatusChange(v) {
    this.setState({ loading: true });
    exe("SetPolicyStatus", { policyId: this.state.policy.id, active: v }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.reload();
      } else message.error(r.msg);
    });
  }
  onInstallmentSchemeChange(scheme) {
    console.log(scheme);
    if (!scheme || !scheme.configJson) return;
    try {
      const schemeConfig = JSON.parse(scheme.configJson);
      if (!schemeConfig.Rules) return;
      schemeConfig.Rules.every((p) => {
        const condition = this.computeFormula(p.condition);
        if (condition) {
          if (p.defaultPremiumPeriodicity) this.props.form.setFieldsValue({ periodicity: p.defaultPremiumPeriodicity });
          if (p.defaultPaymentMethod) this.props.form.setFieldsValue({ paymentMethod: p.defaultPaymentMethod });
        }
        return !condition; //if true, exits loop
      });
    } catch (error) {
      console.error("Error evaluating installment scheme", error);
    }
  }
  onCopyPolicyCode(code) {
    copyToClipboard(code);
    message.success(this.props.t("Policy code copied to clipboard"));
  }
  onPlanOptionsChange(value) {
    if (value) {
      //setting plan coverages
      const planCode = this.props.form.getFieldValue("plan");
      const plan = safeGet(["productConfig", "Plans"], this.state, []).find((p) => p.code == planCode);
      const planCoverages = this.state.productConfig.Coverages.filter((p) => plan.coverages.includes(p.code));
      planCoverages.forEach((p) => (p.mandatory = true));

      //selecting coverages for each option
      const planOptions = safeGet(["Options"], plan, []);
      const options = JSON.parse(value);
      const optionCoverages = [];
      options.forEach((optionCode) => {
        const option = planOptions.find((p) => p.code == optionCode);
        if (option.Coverages) {
          optionCoverages.push(...this.state.productConfig.Coverages.filter((p) => option.Coverages.includes(p.code)));
        }
      });
      const mandatoryCovs = [...planCoverages, ...optionCoverages];
      let policyCovs = this.state.policy.Coverages;
      //adding if not present in policy
      const added = mandatoryCovs.filter(p => !policyCovs.map(q => q.code).includes(p.code));
      policyCovs = policyCovs.concat(added);

      //removing if not present in plan
      policyCovs = policyCovs.filter(p => mandatoryCovs.map(q => q.code).includes(p.code));
      console.log("mandatory:", mandatoryCovs, "added", added, "final", policyCovs);

      this.setState({ policy: { ...this.state.policy, Coverages: policyCovs } });
    }
  }
  gotoActuarialSheet(policyId) {
    window.location.hash = "#/calcsheet/" + policyId;
  }
  onReceiptTypeSelect(v, opt) {
    return; //number preview disabled
    this.setState({ loading: true });
    exe("GetFiscalNumber", { receiptTypeCode: v, simulation: true }).then(r => {
      this.setState({ loading: false });
      if (r.ok) {
        this.props.form.setFieldsValue({ fiscalNumber: r.outData })
      } else {
        message.error(r.msg);
        this.props.form.setFieldsValue({ fiscalNumber: undefined })
      }
    })
  }
  getGroups() {
    if (this.state.policy.jCustomPolicyGroups) {
      return JSON.parse(this.state.policy.jCustomPolicyGroups)
    } else return safeGet(["BenefitGroups"], this.state.productConfig, [])
  }
  onCustomPolicyGroupsUpdated(values) {
    if (this.state.policy.active) {
      message.error(this.props.t("Custom benefit group edition is restricted after issuance"));
      return;
    }
    this.setState({ loading: true });
    exe("UpdateCustomPolicyGroups", { policyId: this.state.policy.id, jCustomPolicyGroups: `${JSON.stringify(values)}` }).then(r => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ customPolicyGroupsVisible: false });
        this.reload();
        message.success(this.props.t("Benefit groups updated for this policy"));
      } else message.error(r.msg);
    })
  }
  onPlanOptionsUpdated(values) {
    exe("SetCustomPlanOptions", { policyId: this.state.policy.id, options: JSON.stringify(values) }).then(r => {
      if (r.ok) {
        message.success(this.props.t("Custom plan options saved"));
        this.props.form.setFieldsValue({ jCustomPlanOptions: JSON.stringify(values) });
        this.setState({ planOptionsVisible: false });
      } else message.error(r.msg)
    });
  }
  getCustomActions() {
    try {
      const customActions = safeGet(["Policy", "customActions"], this.props.configProfile, []);
      return customActions.map((p, index) => <Menu.Item key={"custom" + index} onClick={() => window.location.hash = p.url + "?policyId=" + safeGet(["policy", "id"], this.state, 0)} >
        <LegacyIcon type={p.icon} />{p.name}</Menu.Item>);
    } catch (e) {
      console.error("error computing Policy.customActions", e);
    }
  }
  computeInsuredObjectsTypes(policy) {
    const productTypes = safeGet(["productConfig", "Objects", "types"], this.state, []);
    const computed = [];
    productTypes.forEach(p => {
      if (p.condition) {
        if(!policy.InsuredObjects) policy.InsuredObjects=this.state.insuredObjects?this.state.insuredObjects:[];
        const result = this.computeFormula(p.condition, policy);
        if (result) computed.push(p);
      } else computed.push(p);
    })
    return computed;
  }
  getColapseIcon = () => {
    if (this.props.direction == "rtl") {
      return this.state.collapseActivityPanel ? <RightOutlined /> : <LeftOutlined />
    } else {
      return this.state.collapseActivityPanel ? <LeftOutlined /> : <RightOutlined />
    }

  }
  onCoinsuranceChange = (v) => {
    //updating coinsurance field in policy state (because state is used in other components)
    const policy = { ...this.state.policy, coinsurance: v };
    this.setState({ policy });
    //if value is defferent from 4, clearing coContactId field
    if (v !== 4) this.props.form.setFieldsValue({ coContractId: undefined });
  }
  onCoContractChange = v => {
    //setting value to all coverages
    const coverages = this.state.policy.Coverages;
    coverages.forEach(p => p.coContractId = v);
    this.setState({ policy: { ...this.state.policy, Coverages: coverages } });
  }
  onInsueredObjectsLoaded = (policy,objects) => {
    console.log("INSURED OBJECTS LOADED", objects);
    //Insured objects tab. Computing insured objects condition now we have the objects
    policy.InsuredObjects=objects;
    const types = this.computeInsuredObjectsTypes(policy);
    this.setState({ 
      insuredObjectsTypes: types,
      insuredObjects: objects 
    });
  }
  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const policy = this.props.form.getFieldsValue();

    const process = this.state.policy ? this.state.policy.Process : undefined;
    const currency = this.props.form.getFieldValue("currency");
    const userActions = process ? safeParse(process.userActions) || [] : [];
    const beneficiaries = this.props.form.getFieldValue("Beneficiaries") || [];
    const documents = this.props.form.getFieldValue("Documents") || [];
    const changes = this.props.form.getFieldValue("Changes") || [];
    const requirements = this.props.form.getFieldValue("Requirements") || [];
    const surcharges = this.props.form.getFieldValue("Surcharges") || [];
    const exclusions = this.props.form.getFieldValue("Exclusions") || [];
    const clauses = this.props.form.getFieldValue("Clauses") || [];
    const commisionContractName = this.state.policy && this.state.policy.ComContract && this.state.policy.ComContract.name;
    const investmentStrategies = this.state.productConfig ? this.state.productConfig.InvestmentStrategies : [];
    const periodicityOptions = this.state.productConfig
      ? this.state.productConfig.Premium
        ? this.state.productConfig.Premium.periodicity
        : undefined
      : undefined;
    const defaultPeriodicty = this.state.productConfig ? (this.state.productConfig.Premium ? this.state.productConfig.Premium.defaultPeriodicity : "m1") : "m1";
    const readOnlyCommissions = safeGet(["productConfig", "Commissions", "readOnly"], this.state, false);
    const hasPremiumPlan = safeGet(["productConfig", "Premium", "hasPremiumPlan"], this.state, false);
    const hasPenaltyInterest = safeGet(["productConfig", "Premium", "penaltyInterest"], this.state, false);
    getFieldDecorator("inactiveDate"); //registering fields, used in children views
    const holder = safeGet(["holderContact"], this.state, undefined);
    const maxCoinsureds = safeGetRaw(["productConfig", "Main", "maxCoinsureds"], this.state, -1);
    const maxMinorCoinsureds = safeGet(["productConfig", "Main", "maxMinorCoinsureds"], this.state, -1);
    const disabledBenefitOption = safeGet(["productConfig", "Main", "disabledBenefitOption"], this.state, false);
    const riskAnalysis = this.props.form.getFieldValue("RiskAnalysis") || [];
    const riskFactors = riskAnalysis.filter((p) => p.factor > 0).length;
    const coinsureds = this.props.form.getFieldValue("Coinsureds") || [];
    const isGroupPolicy = this.props.form.getFieldValue("policyType") == "G";
    const isIndividualPolicy = this.props.form.getFieldValue("policyType") == "C";
    const isCertificate = this.props.form.getFieldValue("policyType") == "C";
    const isLimited = this.props.form.getFieldValue("indexation") == 2;
    const isFrequency = this.props.form.getFieldValue("indexation") == 3;
    const hasInsuredObjects = safeGet(["productConfig", "Objects", "visible"], this.state, false);
    const maxInsuredObjects = safeGet(["productConfig", "Objects", "max"], this.state, 0);
    const editableDuration = safeGet(["productConfig", "Main", "editableDuration"], this.state, false);
    const editableDurationMonths = safeGet(["productConfig", "Main", "editableDurationMonths"], this.state, false);
    const editableDurationDays = safeGet(["productConfig", "Main", "editableDurationDays"], this.state, false);
    const proposalValidityEditable = safeGet(["productConfig", "Main", "proposalValidityEditable"], this.state, false);
    const coinsuranceOption = safeGet(["productConfig", "Main", "coinsurance"], this.state, false);
    const temporalStatusOption = safeGet(["productConfig", "Main", "temporalStatus"], this.state, false);
    const plan = safeGet(["productConfig", "Plans"], this.state, []).find((p) => p.code == policy.plan);
    const planOptions = safeGet(["Options"], plan, []);
    const hideProjection = safeGet(["productConfig", "Main", "hideProjection"], this.state, false);
    const multipleRoles = safeGet(["productConfig", "Commissions", "multipleRoles"], this.state, false);
    const showFronting = safeGetRaw(["productConfig", "Main", "showFronting"], this.state, false);
    const hideCommissions = safeGetRaw(["productConfig", "Main", "hideCommissions"], this.state, false);
    const multipleRolesMax = safeGetRaw(["productConfig", "Commissions", "multipleRolesMax"], this.state, 0);
    const segmentFieldConfig = parseFieldConfigString(safeGetRaw(["productConfig", "Main", "segment"], this.state, "000"));
    const channelFieldConfig = parseFieldConfigString(safeGetRaw(["productConfig", "Main", "channel"], this.state, "000"));
    const branchFieldConfig = parseFieldConfigString(safeGetRaw(["productConfig", "Main", "branch"], this.state, "000"));
    const umbrellaGroupFieldConfig = parseFieldConfigString(safeGetRaw(["productConfig", "Main", "umbrellaGroup"], this.state, "000"));
    const planFieldConfig = parseFieldConfigString(safeGetRaw(["productConfig", "Main", "plan"], this.state, "110"));
    const segment = this.props.form.getFieldValue("segment");
    const selectableSegments = safeGet(["productConfig", "Main", "segments"], this.state, []);
    //surcharge edition group  permissions
    const userGroups = safeGet(["GLOBAL", "currentUser", "Groups"], window, []);
    const userBranch = safeGet(["GLOBAL", "currentUser", "branchCode"], window, undefined);
    const surchargeEditionGroups = safeGet(["productConfig", "Main", "surchargeEditionGroups"], this.state, false);
    const canEditSurcharge = surchargeEditionGroups ? userGroups.map((p) => p.usrGroupId).some((p) => surchargeEditionGroups.includes(p)) : true;
    const exclusionEditionGroups = safeGet(["productConfig", "Main", "exclusionEditionGroups"], this.state, false);
    const canEditExclusion = exclusionEditionGroups ? userGroups.map((p) => p.usrGroupId).some((p) => exclusionEditionGroups.includes(p)) : true;
    const computedTitle = this.computeTitle(policy);
    const certificateInheritsFiscalNumber = isCertificate ? safeGet(["Billing", "certificateInheritsFiscalNumber"], this.props.configProfile, false) : false;
    const showBeneficiaryBranch = safeGetRaw(["productConfig", "Main", "showBeneficiaryBranch"], this.state, false);
    const duration = this.props.form.getFieldValue("duration");
    const jCertificateGroups = safeGet(["groupPolicy", "jCertificateGroups"], this.state, null);
    const certificateGroups = jCertificateGroups ? JSON.parse(jCertificateGroups).map(p => p.name) : null;
    const disabledBillingTab = !safeGetRaw(["productConfig", "Main", "editableBillingTab"], this.state, true);
    const beneficiaryAllCoveragesAllowed = safeGetRaw(["productConfig", "Main", "beneficiaryAllCoveragesAllowed"], this.state, false);
    const hidePolicyTabs = safeGetRaw(["productConfig", "Main", "hidePolicyTabs"], this.state, []);
    const showResetStartTime = safeGetRaw(["Main", "showResetStartTime"], this.state.productConfig, false);
    const capitalRequired = safeGetRaw(["productConfig", "Main", "capitalRequired"], this.state, true);
    const cessionBeneficiaryFormId = safeGetRaw(["productConfig", "Main", "cessionBeneficiaryFormId"], this.state, null);
    const disableCertificateEdition = safeGetRaw(["productConfig", "Main", "disableCertificateEdition"], this.state, false);
    const showSubLob = safeGetRaw(["productConfig", "Main", "showSubLob"], this.state, false);
    const myOrganizationId=safeGet(["organization","id"],this.state,0);
    return (
      <DefaultPage
        title={<span><LockOutlined style={{fontSize:18,display:policy.restricted?"inline":"none"}} /> {computedTitle || t("Policy")} </span>}
        subTitle={
          <span>
            {policy.code}{policy.codeSuffix ? <span style={{ color: "#4d4e4e" }}>{policy.codeSuffix}</span> : ""}
            {policy.code && (
              <Tooltip title={t("Copy policy code to clipboard")}>
                <Button type="link" icon={<CopyOutlined />} style={{ marginLeft: 2, fontSize: 12 }} onClick={() => this.onCopyPolicyCode(policy.code)} />
              </Tooltip>
            )}
          </span>
        }
        routes={{
          routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Policy List"), path: "/policies" },
            { breadcrumbName: t("Policy"), path: "" },
          ],
        }}
        icon={this.props.snapshot?"camera":"safety"}
        iconStyle={isGroupPolicy ? { backgroundColor: "#feca57" } : isCertificate ? { backgroundColor: "#74b9ff" } : undefined}
        loading={this.state.loading}
        tags={<Status process={process} color={getColor(process && process.entityState)} reload={() => this.reload()} />}
        extra={
          <div className={"defaultPageExtra"} >
            <div className={"defaultPageLinkButtons"}>
              <Dropdown
                onClick={() => null}
                overlay={
                  <Menu onClick={(item) => this.onTabClick(item.key)}>
                    <Menu.Item key="tab1">
                      <SafetyCertificateOutlined />
                      {t("General")}
                    </Menu.Item>
                    <Menu.Item key="tab2">
                      <CalculatorOutlined />
                      {t("Conditions")}
                    </Menu.Item>
                    <Menu.Item key="tab17" disabled={!isGroupPolicy}>
                      <TeamOutlined />
                      {t("Certificates")}
                    </Menu.Item>
                    <Menu.Item key="tab20" disabled={!hasInsuredObjects}>
                      <BuildOutlined />
                      {t("Objects")}
                    </Menu.Item>
                    <Menu.Item key="tab3">
                      <OrderedListOutlined />
                      {t("Coverages")}
                    </Menu.Item>
                    <Menu.Item key="tab21" disabled={!policy.coinsurance}>
                      <PieChartOutlined />
                      {t("Coinsurance")}
                    </Menu.Item>
                    <Menu.Item key="tab4">
                      <LineChartOutlined />
                      {t("Projection")}
                    </Menu.Item>
                    <Menu.Item key="tab5">
                      <UsergroupAddOutlined />
                      {t("Beneficiaries")}
                    </Menu.Item>
                    <Menu.Item key="tab6">
                      <EuroOutlined />
                      {t("Billing")}
                    </Menu.Item>
                    <Menu.Item key="tab15">
                      <DashboardOutlined />
                      {t("Risk Analysis")}
                    </Menu.Item>
                    <Menu.Item key="tab7">
                      <PercentageOutlined />
                      {t("Surcharges and Discounts")}
                    </Menu.Item>
                    <Menu.Item key="tab8">
                      <StopOutlined />
                      {t("Exclusions")}
                    </Menu.Item>
                    <Menu.Item key="tab19">
                      <CheckSquareOutlined />
                      {t("Policy Clauses")}
                    </Menu.Item>
                    <Menu.Item key="tab9">
                      <FilePdfOutlined />
                      {t("Documents")}
                    </Menu.Item>
                    <Menu.Item key="tab10">
                      <WarningOutlined />
                      {t("Requirements")}
                    </Menu.Item>
                    <Menu.Item key="tab11">
                      <GlobalOutlined />
                      {t("Accounts")}
                    </Menu.Item>
                    <Menu.Item key="tab12">
                      <EditOutlined />
                      {t("Changes")}
                    </Menu.Item>
                    <Menu.Item key="tab13">
                      <CalendarOutlined />
                      {t("Incoming Payments")}
                    </Menu.Item>
                    <Menu.Item key="tab16">
                      <PaperClipOutlined />
                      {t("Anniversaries")}
                    </Menu.Item>
                    <Menu.Item key="tab18">
                      <PushpinOutlined />
                      {t("Policy Tasks")}
                    </Menu.Item>
                    <Menu.Item key="tab14">
                      <InfoOutlined />
                      {t("More Info")}
                    </Menu.Item>
                  </Menu>
                }>
                <Button type="link">
                  {t("Sections")} <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                disabled={this.props.snapshot}
                onClick={() => null}
                overlay={
                  <Menu onClick={() => null}>
                    <Menu.Item key="1" onClick={() => this.reload()}>
                      <RedoOutlined />
                      {t("Reload")}
                    </Menu.Item>
                    <Menu.Item key="2" disabled={!this.state.policy.active}>
                      <Link
                        disabled={!this.state.policy.active}
                        to={{
                          pathname: "/healthclaim/0",
                          policy: this.state.policy,
                        }}>
                        <span>
                          <WarningOutlined style={this.props.direction == "ltr" ? { marginRight: 6 } : { marginLeft: 6 }} />
                          {t("Open Claim")}
                        </span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="4">
                      <Popconfirm title={t("Are you sure you want to delete this record?")} okText={t("Yes")} onConfirm={() => this.onDelete()}>
                        <DeleteOutlined />
                        {t("Delete")}
                      </Popconfirm>
                    </Menu.Item>
                    <Menu.Item key="6" onClick={() => this.setState({ showLabels: !this.state.showLabels })}>
                      <TagOutlined />
                      {t("Toggle Labels")}
                    </Menu.Item>
                    <Menu.Item key="5" onClick={() => this.onQuote("PREQUOTE")} disabled={!policy.id || policy.active} loading={this.state.quoting}>
                      <DollarOutlined />
                      {t("Quote Now")}
                    </Menu.Item>
                    <Menu.Item key="18" onClick={() => this.quoteOnEventBus()} disabled={!policy.id || policy.active} >
                      <ClusterOutlined />
                      {t("Quote in Background")}
                    </Menu.Item>
                    <Menu.Item key="15" onClick={() => policy.policyType != "G" ? this.onClone() : this.setState({ clonePolicyOptionsVisible: true })}>
                      <CopyOutlined />
                      {t("Clone")}
                    </Menu.Item>
                    <Menu.Item key="19" onClick={() => this.setState({ newVersionVisible: true })}>
                      <DiffOutlined />
                      {t("New Version")}
                    </Menu.Item>
                    <Menu.Item key="7" onClick={() => (window.location.hash = "#/cessions?policyId=" + policy.id)} disabled={!policy.id}>
                      <InsuranceOutlined />
                      {t("Reinsurance")}
                    </Menu.Item>
                    <Menu.Item key="12" onClick={() => this.setState({ facultativeEditorVisible: true })} disabled={!policy.id}>
                      <ToolOutlined />
                      {t("Facultative Editor")}
                    </Menu.Item>
                    <Menu.Item key="17" onClick={() => this.setState({ cessionGraphVisible: true })} disabled={!policy.id}>
                      <BarChartOutlined />
                      {t("Cession Graph")}
                    </Menu.Item>
                    <Menu.Item key="11" onClick={() => (window.location.hash = "#/coCessions?policyId=" + policy.id)} disabled={!policy.id}>
                      <PieChartOutlined />
                      {t("Coinsurance")}
                    </Menu.Item>
                    <Menu.Item key="10" onClick={() => (window.location.hash = "#/commissions?policyId=" + policy.id)} disabled={!policy.id}>
                      <ShoppingOutlined />
                      {t("Commissions")}
                    </Menu.Item>
                    <Menu.Item key="8" onClick={() => this.onPaymentRequest()} disabled={!policy.id}>
                      <UploadOutlined />
                      {t("Money Out Request")}
                    </Menu.Item>
                    <Menu.Item key="9" onClick={() => this.onDailyUpdate()} disabled={!policy.id}>
                      <CalendarOutlined />
                      {t("Update Now")}
                    </Menu.Item>
                    <Menu.Item key="13" onClick={() => this.gotoActuarialSheet(policy.id)} disabled={!policy.id}>
                      <SolutionOutlined />
                      {t("Actuarial Data")}
                    </Menu.Item>
                    <Menu.Item key="14" onClick={() => this.setState({ customPolicyGroupsVisible: true })} >
                      <FolderAddOutlined />
                      {t("Custom Benefit Groups")}
                    </Menu.Item>
                    <Menu.Item key="16" onClick={() => this.setState({ planOptionsVisible: true })} >
                      <BuildOutlined />
                      {t("Custom Plan Options")}
                    </Menu.Item>
                    <Menu.Item key="restrictions" onClick={() => this.setState({ policyRestrictionsVisible: true })} >
                      <LockOutlined /> 
                      {t("Restrictions")}
                    </Menu.Item>
                    {this.getCustomActions()}
                  </Menu>
                }>
                <Button type="link">
                  {t("Actions")} <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                onClick={() => null}
                disabled={this.props.snapshot}
                overlay={
                  <Menu onClick={() => null}>
                    {this.state.processes.map((p, index) => (
                      <Menu.Item key={"task" + index}>
                        <Link to={{ pathname: "/activity/0", proceso: p, entity: "lifePolicy", entityId: policy.id, entityPath: "LifePolicy/" + policy.id }}>
                          <LegacyIcon type={p.icon || "rocket"} style={{ marginRight: 5, marginLeft: 5 }} />
                          {p.id}.{p.name}
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu>
                }>
                <Button type="link">
                  {t("Tasks")} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
            <div className={"defaultPageButtons"}>
              <ButtonGroup style={{ marginLeft: 5, marginRight: 5 }} >
                <Button icon={<ReloadOutlined />} onClick={() => this.reload()} disabled={this.props.snapshot}>
                  {t("Reload")}
                </Button>
                <Button icon={<FileOutlined />} onClick={() => this.onNew()} disabled={this.props.snapshot}>
                  {t("New")}
                </Button>

                <Button
                  type={!userActions.includes("QUOTE") && !userActions.includes("ISSUE") ? "primary" : "default"}
                  loading={this.state.saving}
                  onClick={() => this.onSave()}
                  disabled={this.props.snapshot}
                  icon={<SaveOutlined />}>
                  {t("Save")}
                </Button>
              </ButtonGroup>
              <Button
                disabled={!userActions.includes("QUOTE")}
                type="primary"
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={() => this.onQuote()}
                loading={this.state.quoting}
                icon={<EuroOutlined />}>
                {t("Quote")}
              </Button>
              <Button disabled={!userActions.includes("ISSUE")} type="primary" onClick={() => this.onIssue()} icon={<RocketOutlined />}>
                {t("Issue")}
              </Button>
            </div>
          </div>
        }
        content={
          <Row className="content" type="flex">
            <div style={{ flex: 1 }}>
              {/* <Paragraph>Welcome to the life insurance issuance process. Please follow instructions or click on help for assistance.</Paragraph> */}

              <PolicyHeader policy={this.state.policy} />
              {/* <IconLink src="https://gw.alipayobjects.com/zos/rmsportal/MjEImQtenlyueSmVEfUD.svg" text="Quick Start" />
                <IconLink src="https://gw.alipayobjects.com/zos/rmsportal/NbuDUAuBlIApFuDvWiND.svg" text=" Product Info" />
                <IconLink src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg" text="Product Doc" /> */}
            </div>
            <div>{/* <img src="https://gw.alipayobjects.com/mdn/mpaas_user/afts/img/A*KsfVQbuLRlYAAAAAAAAAAABjAQAAAQ/original" alt="content" /> */}</div>
          </Row>
        }>
        <div style={{ position: "absolute", top: -15, zIndex: 9, ...(this.props.direction == "rtl" ? { left: 0 } : { right: 0 }) }}>
          <Tooltip title={this.state.collapseActivityPanel ? t("Expand Activity Panel") : t("Collapse Activity Panel")} placement={this.props.direction == "rtl" ? "right" : "left"}>
            <Button
              type="link"
              icon={this.getColapseIcon()}
              onClick={() => this.onCollapseActivityPanel()}
              className="collapseButton"></Button>
          </Tooltip>
        </div>
        {/* <Card type="inner" size="small" title="New Life Policy"> */}
        <Row gutter={32}>
          <Col span={this.state.collapseActivityPanel ? 24 : 16}>
            <Form layout="vertical">
              <Tabs type="card" tabBarExtraContent={{ left: this.state.currentStep == 1 ? null : <Button type="link" icon={<LeftOutlined style={{ fontSize: 14 }} />} onClick={() => this.onTabClick("tab1", policy)} /> }}
                activeKey={"tab" + this.state.currentStep}
                onTabClick={(tab) => this.onTabClick(tab, policy)} tabBarGutter={4} >
                {/* ////////////////////////////////STEP 1 ////////////////////////////////////////////////////////////////// */}
                <Tabs.TabPane
                  key="tab1"
                  tab={
                    <span>
                      <SafetyCertificateOutlined />
                      {this.state.showLabels && t("General")}
                    </span>
                  }>
                  <Row gutter={16} className={isCertificate&&disableCertificateEdition?"disabled":""} >
                    <Col span={8}>
                      <Title level={4}>{t("Product Info")}</Title>
                      <Form.Item label={t("LoB")}>{getFieldDecorator("lob", {})(<LobSelect filterLobBySection excludedLobs={v => this.setState({ excludedLobs: v })} />)}</Form.Item>
                      <Form.Item label={t("Product")}>
                        {getFieldDecorator("productSelect", { rules: [{ required: true }] })(
                          <ProductSelect lobFilter={policy.lob} excludedLobs={this.state.excludedLobs} onChange={(v) => this.onProductChange(v)} />
                        )}
                      </Form.Item>
                      <div style={{ display: showSubLob ? "block" : "none" }}>
                        <Form.Item label={t("Sub LoB")}>
                          <SubLobSelect lobCode={safeGet(["productLobCode"],this.state,undefined)} value={safeGet(["productSubLobCode"],this.state,undefined)}   />
                        </Form.Item>
                      </div>

                      <div style={{ display: planFieldConfig.visible ? "block" : "none" }}>
                        <Form.Item label={t("Plan")}>
                          {getFieldDecorator("plan", { initialValue: planFieldConfig.value, rules: [{ required: planFieldConfig.required }] })(
                            <Select allowClear onChange={(v) => this.onPlanChange(v)} placeholder={t("No plan selected")} disabled={!planFieldConfig.editable}>
                              {this.state.productConfig
                                ? this.state.productConfig.Plans
                                  ? this.state.productConfig.Plans.filter(p=>!p.organizationIds||myOrganizationId==0||p.organizationIds.includes(myOrganizationId)).map((plan) => (
                                    <Select.Option value={plan.code} key={plan.code} disabled={plan.disabled}>
                                      {plan.name}
                                    </Select.Option>
                                  ))
                                  : null
                                : null}
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                      <div style={{ display: planOptions.length == 0 ? "none" : "block" }}>
                        <Form.Item label={t("Plan Options")}>
                          {getFieldDecorator("planOptions")(
                            <PlanOptionSelect plan={plan} planOptions={planOptions} onChange={(v) => this.onPlanOptionsChange(v)} />
                          )}
                        </Form.Item>
                      </div>
                      <Form.Item label={t("Policy Type")}>
                        {getFieldDecorator("policyType", { initialValue: "I" })(
                          <PolicyTypeSelect options={safeValue("productConfig.Main.policyType", this.state, ["I"])} onChange={(v) => this.onPolicyTypeChange(v)} />
                        )}
                      </Form.Item>
                      <Form.Item label={t("Umbrella Group")} hidden={isCertificate || !umbrellaGroupFieldConfig.visible}>
                        {getFieldDecorator("umbrellaGroup", { initialValue: umbrellaGroupFieldConfig.value, rules: [{ required: umbrellaGroupFieldConfig.required }] })(<UmbrellaGroupSelect visible={!isCertificate && umbrellaGroupFieldConfig.visible} />)}
                      </Form.Item>


                      <div style={{ display: isCertificate ? "block" : "none" }}>
                        <Form.Item label={t("Group Policy")}>
                          {getFieldDecorator("groupPolicyId", { rules: [{ required: isCertificate }] })(
                            <GroupPolicySelect onSelect={this.onGroupPolicySelect} refresh />
                          )}
                        </Form.Item>
                        <Form.Item label={t("Certificate Group")}>{getFieldDecorator("group")(<GroupSelect config={this.state.productConfig} groups={certificateGroups} />)}</Form.Item>
                        <Form.Item label={t("Contact Branch")}>{getFieldDecorator("contactBranchId")(<ContactBranchSelect contactId={policy.holderId} isCertificate={isCertificate} />)}</Form.Item>
                      </div>
                      <Form.Item label={t("Category")}>{getFieldDecorator("Tags")(<PolicyTags policyId={this.state.policy.id} />)}</Form.Item>
                      <div style={{ display: segmentFieldConfig.visible ? "block" : "none" }}>
                        <Form.Item label={t("Sales Segment")}>
                          {getFieldDecorator("segment", { initialValue: segmentFieldConfig.value, rules: [{ required: segmentFieldConfig.required }] })(
                            <SegmentSelect disabled={!segmentFieldConfig.editable} selectableSegments={selectableSegments} />
                          )}
                        </Form.Item>
                      </div>
                      <div style={{ display: channelFieldConfig.visible ? "block" : "none" }}>
                        <Form.Item label={t("Sales Channel")}>
                          {getFieldDecorator("channel", { initialValue: channelFieldConfig.value, rules: [{ required: channelFieldConfig.required }] })(
                            <ChannelSelect disabled={!channelFieldConfig.editable} segment={segment} />
                          )}
                        </Form.Item>
                      </div>
                      <div style={{ display: branchFieldConfig.visible ? "block" : "none" }}>
                        <Form.Item label={t("Branch")}>
                          {getFieldDecorator("branchCode", { initialValue: userBranch || branchFieldConfig.value, rules: [{ required: branchFieldConfig.required }] })(
                            <BranchSelect disabled={!branchFieldConfig.editable} />
                          )}
                        </Form.Item>
                      </div>
                      <Form.Item label={t("Description")}>
                        {getFieldDecorator("description")(<Input.TextArea disabled={policy.active} placeholder={t("Optional Description")} />)}
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Title level={4}>{t("Role Info")}</Title>
                      {getFieldDecorator("Holder")}
                      <Form.Item label={t("Policyholder")}>
                        {getFieldDecorator("holderId", { rules: [{ required: true }] })(
                          <ContactSelect
                            disabled={isCertificate}
                            display={safeValue("FullName", holder, undefined)}
                            contactChange={(v) => this.onHolderChange(v)}
                            contact={holder}
                            configProfile={this.props.configProfile}
                          />
                        )}
                      </Form.Item>
                      <div style={{ display: safeValue("productConfig.Main.payerVisible", this.state, false) ? "block" : "none" }}>
                        <Form.Item label={t("Payer")}>
                          {getFieldDecorator("payerId", { rules: [{ required: safeValue("productConfig.Main.payerMandatory", this.state, false) }] })(
                            <SimpleContactSelect />
                          )}
                        </Form.Item>
                        <div style={{ display: safeValue("productConfig.Main.payerRelationshipVisible", this.state, false) ? "block" : "none" }}>
                        <Form.Item label={t("Payer Relationship")}>
                          {getFieldDecorator("payerRelationshipId", { rules: [{ required: safeValue("productConfig.Main.payerRelationshipMandatory", this.state, false)&&(this.props.form.getFieldValue("payerId") !== this.props.form.getFieldValue("holderId"))} ] })(
                            <RelationshipSelect principalType={(holder&&holder.isPerson) ? "PERSON" : "LEGAL"} disabled={this.props.form.getFieldValue("payerId") === this.props.form.getFieldValue("holderId")} />
                          )}
                        </Form.Item>
                        </div>
                      </div>
                      <div style={{ display: safeValue("productConfig.Main.guarantorVisible", this.state, false) ? "block" : "none" }}>
                        <Form.Item label={t("Guarantor")}>
                          {getFieldDecorator("guarantorId", { rules: [{ required: safeValue("productConfig.Main.guarantorMandatory", this.state, false) }] })(
                            <SimpleContactSelect />
                          )}
                        </Form.Item>
                      </div>
                      <div style={{ visibility: isGroupPolicy ? "hidden" : "visible" }}>
                        <Form.Item
                          label={
                            <span style={{ display: "flex" }}>
                              <span style={{ flex: 1, marginLeft: 3, marginRight: 3 }}>{t("Main Insured")}</span>
                              <span style={{ width: 174 }}>
                                {t("Relationship")}
                                <Tooltip title={t("Relationship of the policyholder with the main insured")}>
                                  <InfoCircleOutlined
                                    style={{ marginLeft: 5, marginRight: 5, verticalAlign: "middle", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
                                </Tooltip>
                              </span>
                            </span>
                          }>
                          {getFieldDecorator("MainInsured", {
                            rules: [
                              {
                                validator: (rule, value, cb) => this.mainInsuredValidator(rule, value, cb),
                              },
                            ],
                          })(<MainInsuredSelect holder={holder} disables={isGroupPolicy} configProfile={this.props.configProfile} />)}
                        </Form.Item>
                        <Form.Item
                          label={
                            coinsureds.length > 0 && (
                              <span style={{ display: "flex" }}>
                                <span style={{ flex: 1, marginLeft: 3, marginRight: 3 }}>{t("Coinsureds")}</span>
                                <span style={{ width: 174 }}>
                                  {t("Relationship")}
                                  <Tooltip title={t("Relationship of the main insured with the co-insured")}>
                                    <InfoCircleOutlined
                                      style={{ marginLeft: 5, marginRight: 5, verticalAlign: "middle", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
                                  </Tooltip>
                                </span>
                              </span>
                            )
                          }>
                          {getFieldDecorator("Coinsureds", {
                            rules: [{ required: false }, { validator: (rule, value, cb) => this.coinsuredValidator(rule, value, cb) }],
                          })(<MultiContactSelect showRelationship maxContacts={maxCoinsureds} maxMinorContacts={maxMinorCoinsureds} configProfile={this.props.configProfile} />)}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                {/* ////////////////////////////////STEP Conditions ////////////////////////////////////////////////////////////////// */}
                <Tabs.TabPane
                  forceRender
                  key="tab2"
                  className={isCertificate&&disableCertificateEdition?"disabled":""} 
                  tab={
                    <span>
                      <CalculatorOutlined />
                      {this.state.showLabels && t("Conditions")}
                    </span>
                  }>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Title level={4}>{t("Basic Conditions")}</Title>
                      <Form.Item label={t("Basic Coverage Amount")}>
                        {getFieldDecorator("insuredSum", {
                          rules: [
                            {
                              required: !isGroupPolicy && capitalRequired,
                              type: "number",
                              max: safeGet(["productConfig", "Main", "maxCapital"], this.state, undefined),
                              min: 0,
                            },
                          ],
                        })(<Money currency={currency} disabled={policy.plan && !(plan && plan.sumInsuredEditable)} />)}
                      </Form.Item>
                      <Form.Item label={t("Currency")}>
                        {getFieldDecorator("currency")(
                          <Currency filter={safeGet(["productConfig", "Main", "currencies"], this.state, "")} />
                        )}
                      </Form.Item>
                      <Form.Item label={t("Start")}>
                        {getFieldDecorator("start", { rules: [{ required: true }] })(
                          <DatePickerW onChange={(v) => this.onStartChange(v)} 
                          renderExtraFooter={() =>showResetStartTime?<Button style={{padding:0}} type="link" 
                          onClick={()=>this.onStartResetTime()}>{t("Reset Time")}</Button>:null} style={{ width: "100%" }}
                          showTime={safeGetRaw(["productConfig", "Main", "showTime"], this.state, false)}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={t("End")}>{getFieldDecorator("end", {})(<DatePickerW style={{ width: "100%" }} disabled showTime={safeGetRaw(["productConfig", "Main", "showTime"], this.state, false)} />)}</Form.Item>

                      <Section text={t("Duration")}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Item label={""}>
                            {getFieldDecorator("duration", {
                              rules: [
                                {
                                  validator: (rule, value, cb) => this.durationValidator(rule, value, cb),
                                },
                              ],
                            })(<InputNumber onChange={(v) => this.onDurationChange(v)} disabled={!editableDuration} />)}

                          </Form.Item>

                          <div style={{ marginLeft: 5 }}>{t("Years")}</div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Item label={""}>
                            {getFieldDecorator("durationMonths", {
                              rules: [
                                {
                                  validator: (rule, value, cb) => this.durationValidator(rule, value, cb, "m"),
                                },
                              ],
                            })(<MonthSelect style={{ width: 88 }} onChange={(v) => this.onDurationMonthsChange(v)} disabled={!editableDurationMonths} />)}

                          </Form.Item>
                          <div style={{ marginLeft: 5 }}>{t("Months")}</div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Item label={""}>
                            {getFieldDecorator("durationDays", {
                              rules: [
                                {
                                  validator: (rule, value, cb) => this.durationValidator(rule, value, cb, "d"),
                                },
                              ],
                            })(<DaySelect style={{ width: 88 }} onChange={(v) => this.onDurationDaysChange(v)} disabled={!editableDurationDays} />)}

                          </Form.Item>
                          <div style={{ marginLeft: 5 }}>{t("Days")}</div>
                        </div>


                      </Section>

                      <Form.Item label={t("Proposal Creation")}>
                        {getFieldDecorator("proposalStartDate", {})(
                          <DatePickerW disabled style={{ width: "100%" }} placeholder="" />
                        )}
                      </Form.Item>
                      <Form.Item label={t("Proposal Expiration")}>
                        {getFieldDecorator("proposalValidityDate", {})(
                          <ExpiratiionField disabled={!proposalValidityEditable} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Title level={4}>{t("Policy Conditions")}</Title>
                      <div style={{ display: safeValue("productConfig.Main.investmentOptionsHidden", this.state, false) ? "none" : "block" }}>
                        <Section text={t("Investment Options")}>
                          <Form.Item label={t("Investment Strategy")}>
                            {getFieldDecorator("investmentStrategy")(
                              <InvestmentStrategy options={investmentStrategies} onChange={(v) => this.onInvestmentStrategyChange(v)} />
                            )}
                          </Form.Item>

                          <Form.Item label={t("Investment Selection")}>
                            {getFieldDecorator("InvestmentSelections", {
                              rules: [
                                {
                                  validator: (rule, value, cb) => {
                                    if (value && Array.isArray(value) && value.length > 0) {
                                      const hasEmptyValue = value.some(obj => obj.investmentPlanCode === null || obj.investmentPlanCode === undefined);
                                      if (hasEmptyValue) {
                                        cb(t("Invalid value"));
                                      } else {
                                        cb();
                                      }
                                    } else cb();
                                  },
                                },
                              ],
                            })(
                              <InvestmentSelection
                                disabled={investmentStrategies && investmentStrategies.some((p) => p.default)}
                                strategies={safeGet(["productConfig", "InvestmentStrategies"], this.state, [])}
                              />
                            )}
                          </Form.Item>
                        </Section>
                      </div>
                      <div style={{ display: this.computeFormula(hideCommissions, policy) ? "none" : "block" }}>
                        <Section text={t("Commissions")}>
                          <Form.Item label={t("Commission Scheme")}>
                            {getFieldDecorator("comContractId", { normalize: (v) => (v ? v : undefined) })(
                              <ComContractSelect onChange={r => this.props.form.setFieldsValue({ sellerId: undefined })} onSelect={(r) => this.setState({ hasAdHocCommission: r.isAdHoc })} display={commisionContractName} disabled={readOnlyCommissions || policy.active} active />
                            )}
                          </Form.Item>
                          <div style={{ display: multipleRoles ? "none" : "block" }}>
                            <Form.Item label={t("Seller")}>
                              {getFieldDecorator("sellerId", { normalize: (v) => (v ? v : undefined) })(
                                <SellerSelect comContractId={policy.comContractId} disabled={policy.active} />
                              )}
                            </Form.Item>
                          </div>
                          <div style={{ display: multipleRoles ? "block" : "none" }}>
                            <Form.Item label={t("Participants")}>
                              {getFieldDecorator("jComParticipants")(
                                <ParticipantRoleSelect comContractId={policy.comContractId} disabled={policy.active} maxParticipants={multipleRolesMax} />
                              )}
                            </Form.Item>
                          </div>
                          <div style={{ display: this.state.hasAdHocCommission ? "block" : "none" }}>
                            <Form.Item label={t("Ad-Hoc Commission")}>
                              {getFieldDecorator("comPercentage")(
                                <Percentage />
                              )}
                            </Form.Item>
                          </div>
                        </Section>
                      </div>
                      <Section text={t("Options")}>
                        <div style={{ display: showFronting ? "block" : "none" }}>
                          <Form.Item label={t("Fronting")}>
                            {getFieldDecorator("fronting", { valuePropName: "checked" })(<Switch disabled={policy.active} />)}
                          </Form.Item>
                        </div>
                        <div style={{ display: disabledBenefitOption ? "none" : "block" }}>
                          <Form.Item label={t("Benefit Option")}>
                            {getFieldDecorator("option", { initialValue: 1 })(
                              <Select>
                                <Select.Option value={1}>{t("1-Benefit")}</Select.Option>
                                <Select.Option value={2}>{t("2-Benefit+Cash Value")}</Select.Option>
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                        <div style={{ display: safeValue("productConfig.Main.indexationHidden", this.state, false) ? "none" : "block" }}>
                          <Form.Item label={t("Indexation")} style={{ marginRight: 10 }}>
                            {getFieldDecorator("indexation", { initialValue: 0 })(
                              <Select disabled={policy.active} onSelect={v => this.onSelectIndexationType(v)}>
                                <Select.Option value={0}>{t("No")}</Select.Option>
                                <Select.Option value={1}>{t("Yes")}</Select.Option>
                                <Select.Option value={2}>{t("Limited")}</Select.Option>
                                <Select.Option value={3}>{t("Frequency")}</Select.Option>
                                <Select.Option value={4}>{t("Insured Sum")}</Select.Option>
                                <Select.Option value={5}>{t("Premium")}</Select.Option>
                                <Select.Option value={6}>{t("Insured Sum and Premium")}</Select.Option>

                              </Select>
                            )}
                          </Form.Item>
                          {isLimited && (
                            <div style={{ display: "flex" }}>
                              <Form.Item label={t("Indexation Period")} style={{ marginRight: 10 }}>
                                {getFieldDecorator("indexationPeriod", { initialValue: 0, rules: [{ required: true }] })(
                                  <InputNumber
                                    onChange={(v) => this.onChangeIndexationPeriod(v)}
                                    min={1}
                                    max={duration}
                                    disabled={policy.active}
                                  />
                                )}
                                <Button type="link" onClick={() => this.setState({ indexationPlanVisible: true })}>
                                  {t("Indexation plan")}
                                </Button>
                              </Form.Item>
                            </div>
                          )}
                          <div style={{ display: (policy.indexation == 4 || policy.indexation == 6) ? "flex" : "none" }}>
                            <Form.Item label={t("Insured Sum Indexation")} style={{ marginRight: 10 }}>
                              {getFieldDecorator("indexationInsuredSum", { rules: [{ required: (policy.indexation == 4 || policy.indexation == 6) }] })(
                                <Percentage disabled={policy.active}
                                />
                              )}
                            </Form.Item>
                          </div>
                          <div style={{ display: (policy.indexation == 5 || policy.indexation == 6) ? "flex" : "none" }}>
                            <Form.Item label={t("Premium Indexation")} style={{ marginRight: 10 }}>
                              {getFieldDecorator("indexationPremium", { rules: [{ required: (policy.indexation == 5 || policy.indexation == 6) }] })(
                                <Percentage disabled={policy.active}
                                />
                              )}
                            </Form.Item>
                          </div>
                          <div style={{ display: isFrequency ? "flex" : "none" }}>
                            <Form.Item label={t("Indexation Period")} style={{ marginRight: 10 }}>
                              {getFieldDecorator("indexationPeriod", { initialValue: duration, rules: [{ required: true }] })(
                                <InputNumber
                                  onChange={(v) => this.onChangeIndexationPeriodWithFrequency(v)}
                                  min={1}
                                  max={duration}
                                  disabled={policy.active}
                                />
                              )}
                            </Form.Item>
                            <Form.Item label={t("Start")} style={{ marginRight: 10 }}>
                              {getFieldDecorator("indexationStart", { initialValue: 1, rules: [{ required: isFrequency }] })(
                                <InputNumber
                                  onChange={(v) => this.onChangeIndexationStart(v)}
                                  min={1}
                                  max={duration}
                                  disabled={policy.active}
                                />
                              )}
                            </Form.Item>
                            <Form.Item label={t("Frequency")} style={{ marginRight: 10 }}>
                              {getFieldDecorator("indexationFrequency", { initialValue: 1, rules: [{ required: isFrequency }] })(
                                <InputNumber
                                  onChange={(v) => this.onChangeIndexationFrequency(v)}
                                  min={1}
                                  max={duration}
                                  disabled={policy.active}
                                />
                              )}
                              <Button type="link" onClick={() => this.setState({ indexationPlanVisible: true })}>
                                {t("Indexation plan")}
                              </Button>
                            </Form.Item>
                          </div>

                        </div>
                        <Modal
                          title={t("Indexation Plan")}
                          width={800}
                          visible={this.state.indexationPlanVisible}
                          onCancel={() => this.setState({ indexationPlanVisible: false })}
                          onOk={() => this.onIndexationPlanOk()}>
                          {getFieldDecorator("jIndexationPlan")(
                            <IndexationPlan
                              disabled={policy.active}
                              coverages={this.state.policy.Coverages}
                              duration={duration}
                              indexationPeriod={this.props.form.getFieldValue("indexationPeriod")}
                            />
                          )}
                        </Modal>

                        <div style={{ display: coinsuranceOption ? "block" : "none" }}>
                          <Form.Item label={t("Coinsurance")}>{getFieldDecorator("coinsurance", { initialValue: 0 })(<CoinsuranceSelect onChange={this.onCoinsuranceChange} />)}</Form.Item>
                          <div style={{ display: policy.coinsurance == 4 ? "block" : "none" }}>
                            <Form.Item label={t("Coinsurance Contract")}>{getFieldDecorator("coContractId", { rules: [{ required: policy.coinsurance == 4 }] })(<CoContractSelect onChange={this.onCoContractChange} />)}</Form.Item>
                          </div>
                        </div>
                      </Section>

                      {hasPremiumPlan && (
                        <Section text={t("Premium Options")}>
                          <Form.Item label={t("Premium")}>
                            {getFieldDecorator("plannedPremium", { initialValue: 2500 })(<Money currency={currency} />)}
                          </Form.Item>
                          <Form.Item label={t("Premium Plan")}>{getFieldDecorator("premiumPlan")(<RangeSelect />)}</Form.Item>
                        </Section>
                      )}
                      <div style={{ visibility: isGroupPolicy ? "visible" : "hidden" }}>
                        <Section text={t("Group Policy Options")}>
                          <Form.Item label={t("Coverage Handling")}>
                            {getFieldDecorator("groupCoverageType")(
                              <GroupCoverageHandlingSelect options={safeValue("productConfig.Main.certificateCoverages", this.state, ["DIFFERENT"])} />
                            )}
                          </Form.Item>
                        </Section>
                      </div>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                {/* ////////////////////////////////STEP Insured Objects ////////////////////////////////////////////////////////////////// */}

                {!hidePolicyTabs.includes(t("Objects")) && hasInsuredObjects && (
                  <Tabs.TabPane
                    key="tab20"
                    tab={
                      <span>
                        <BuildOutlined />
                        {this.state.showLabels && t("Objects")}
                      </span>
                    }>
                    {/* <Title level={4}>Objects</Title> */}
                    <InsuredObjects policyId={policy.id} active={policy.active} objectTypes={this.state.insuredObjectsTypes} 
                      max={maxInsuredObjects} 
                      onLoaded={(objs)=>this.onInsueredObjectsLoaded(policy,objs)} 
                      snapshot={this.props.snapshot} />
                  </Tabs.TabPane>
                )}
                {/* ////////////////////////////////STEP Certifictes ////////////////////////////////////////////////////////////////// */}

                {isGroupPolicy && (
                  <Tabs.TabPane
                    key="tab17"
                    tab={
                      <span>
                        <TeamOutlined />
                        {this.state.showLabels && t("Certificates")}
                      </span>
                    }>
                    <Title level={4}>{t("Certificates")}</Title>
                    <Certificates currency={currency} groupPolicyId={this.state.policy.id} onUpdateCertificateGroups={v => this.props.form.setFieldsValue({ jCertificateGroups: v ? JSON.stringify(v) : undefined })}
                      config={this.state.productConfig} active={policy.active} jCertificateGroups={policy.jCertificateGroups} 
                      mainInsuredId={safeGet(["MainInsured","contactId"],policy,undefined)}
                      payerId={safeGet(["payerId"],policy,undefined)}
                       />
                  </Tabs.TabPane>
                )}
                {/* ////////////////////////////////STEP Coverages ////////////////////////////////////////////////////////////////// */}
                <Tabs.TabPane
                  forceRender
                  key="tab3"
                  tab={
                    <span>
                      <OrderedListOutlined />
                      {this.state.showLabels && t("Coverages")}
                    </span>
                  }>
                  <Title level={4}>{t("Coverages")}</Title>

                  <Coverages
                    coverages={this.state.policy && this.state.policy.Coverages}
                    policy={this.state.policy}
                    config={this.state.productConfig}
                    groups={this.getGroups()}
                    onChange={(v) => this.onCoverageChange(v)}
                    currency={currency}
                    readOnly={!userActions.includes("QUOTE") && policy.active}
                    closedPlan={this.state.closedPlan}
                    excluded={plan && plan.excluded}
                  />
                </Tabs.TabPane>
                {/* ////////////////////////////////STEP Coinsurance ////////////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Coinsurance")) && policy.coinsurance && (
                  <Tabs.TabPane
                    key="tab21"
                    disabled={!this.state.policy.id||this.props.snapshot}
                    tab={
                      <span>
                        <PieChartOutlined />
                        {this.state.showLabels && t("Coinsurance")}
                      </span>
                    }>
                    <Title level={4}>{t("Coinsurance")}</Title>
                    <PolicyCoinsurance policy={policy} configProfile={this.props.configProfile} doQuote={() => this.onQuote("PREQUOTE")} />
                  </Tabs.TabPane>
                )}
                {/* ////////////////////////////////STEP Projection ////////////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Projection")) && !hideProjection && (
                  <Tabs.TabPane
                    key="tab4"

                    disabled={!this.state.policy.id}
                    tab={
                      <span>
                        <LineChartOutlined />
                        {this.state.showLabels && t("Projection")}
                      </span>
                    }>
                    <Title level={4}>{t("Projection")}</Title>
                    <Projection policy={this.state.policy} currency={currency} config={this.state.productConfig} projections={this.state.policy.jProjections} />
                  </Tabs.TabPane>
                )}
                {/* ////////////////////////////////STEP Beneficiaries ////////////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Beneficiaries")) && <Tabs.TabPane
                  key="tab5"
                  tab={
                    <Tooltip title={t("Beneficiaries")}>
                      <TabBadge count={beneficiaries.length}>
                        <UsergroupAddOutlined />
                        {this.state.showLabels && t("Beneficiaries")}
                      </TabBadge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Beneficiaries")}</Title>
                  <Form.Item className="noFlex">
                    {getFieldDecorator("Beneficiaries", {
                      rules: [{ required: false }],
                    })(
                      <MultiContactSelect
                        showPercentage
                        coverages={this.state.policy.Coverages}
                        productCode={this.state.policy.productCode}
                        config={this.state.productConfig}
                        incompatible={safeGet(["MainInsured", "contactId"], policy, null)}
                        showContactBranch={showBeneficiaryBranch}
                        configProfile={this.props.configProfile}
                        beneficiaryAllCoveragesAllowed={beneficiaryAllCoveragesAllowed}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="">
                    {getFieldDecorator("Contingents")(
                      <Contingent disabled={!policy.id || beneficiaries.length == 0 || beneficiaries.some((p) => p.id == 0)} beneficiaries={beneficiaries} />
                    )}
                  </Form.Item>
                  <Form.Item label="">{getFieldDecorator("cessionBeneficiary")(<CessionBeneficiary disabled={policy.active} formId={cessionBeneficiaryFormId} jCessionBeneficiary={policy.jCessionBeneficiary} />)}</Form.Item>
                  {getFieldDecorator("jCessionBeneficiary")}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Billing ////////////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Billing")) && <Tabs.TabPane
                  key="tab6"
                  forceRender
                  tab={
                    <Tooltip title={t("Billing")}>
                      <EuroOutlined />
                      {this.state.showLabels && t("Billing")}
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Cost of Insurance")}</Title>
                  <Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={() => this.setState({ uniqueBillsVisible: true })}>{t("Change Bills") + " (" + changes.length + ")"}</Button>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label={t("Coverage Premiums")}>{getFieldDecorator("coverages", {})(<Money currency={currency} disabled={policy.active || disabledBillingTab} />)}</Form.Item>
                      <Form.Item label={t("Surcharges")}>
                        {getFieldDecorator("surcharges", {})(<Money currency={currency} disabled={policy.active || disabledBillingTab} />)}
                      </Form.Item>
                      <Form.Item label={t("Discounts")}>{getFieldDecorator("discounts", {})(<Money currency={currency} disabled={policy.active || disabledBillingTab} />)}</Form.Item>
                      <Form.Item label={t("Annual Premium")}>
                        {getFieldDecorator("anualPremium", {})(<Money currency={currency} disabled={policy.active || disabledBillingTab} />)}
                      </Form.Item>
                      <Form.Item label={t("Tax")}>
                        {getFieldDecorator("tax", {})(<TaxDetailField currency={currency} lifePolicyId={policy.id} disabled={policy.active || disabledBillingTab} />)}
                      </Form.Item>
                      <Form.Item label={t("Total")}>{getFieldDecorator("anualTotal", {})(<Money currency={currency} disabled={policy.active || disabledBillingTab} />)}</Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label={t("Payment Duration")}>
                        {getFieldDecorator("paymentDuration", { initialValue: 0 })(<PaymentDuration disabled={policy.active} />)}
                      </Form.Item>
                      <Form.Item label={t("Payment Method")}>{getFieldDecorator("paymentMethod", {})(<PaymentMethods module={"ISSUANCE"} disabled={policy.active} />)}</Form.Item>
                      <Form.Item label={t("Premium Periodicity")}>
                        {getFieldDecorator("periodicity", { initialValue: defaultPeriodicty })(
                          <Periodicty periodicityOptions={periodicityOptions} onChange={this.onChangePeriodicty} disabled={policy.active} />
                        )}
                      </Form.Item>
                      <Form.Item label={t("Fee")}>
                        {getFieldDecorator("fee", {})(<FeeDetailField currency={currency} lifePolicyId={policy.id} disabled={policy.active || disabledBillingTab} />)}
                      </Form.Item>
                      <Form.Item label={t("Modal Premium")}>
                        {getFieldDecorator("installment", {})(<Money currency={currency} disabled={policy.active || disabledBillingTab} />)}
                      </Form.Item>
                      <div style={{ display: safeGet(["productConfig", "Premium", "installmentScheme"], this.state, false) ? "block" : "none" }}>
                        <Form.Item label={t("Installment Scheme")}>
                          {getFieldDecorator(
                            "installmentSchemeId",
                            {}
                          )(
                            <InstallmentSchemeSelect
                              onSchemeChange={(scheme) => this.onInstallmentSchemeChange(scheme)}
                              disabled={policy.active || safeGet(["productConfig", "Premium", "installmentSchemeReadOnly"], this.state, false)}
                              defaultScheme={safeGet(["productConfig", "Premium", "installmentScheme"], this.state, undefined)}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={8}>
                      {/* <Form.Item label="Last Insurance Charge">
                        {getFieldDecorator("lastInsuranceCharge", {})(<FormLabel type="Date" emptyValue="Never" />)}
                      </Form.Item> */}
                      <div style={{ display: certificateInheritsFiscalNumber ? "none" : "block" }}>
                        <Form.Item label={t("Fiscal Document Type")}>
                          {getFieldDecorator("receiptTypeCode")(<ReceiptTypeSelect onSelect={(v, opt) => this.onReceiptTypeSelect(v, opt)} disabled={policy.active} />)}
                        </Form.Item><Form.Item label={t("Fiscal Document Number")}>
                          {getFieldDecorator("fiscalNumber")(<Input disabled />)}
                        </Form.Item>
                      </div>
                      {isCertificate && <div>
                        <Form.Item label={t("Group Fiscal Document Type")}>
                          <ReceiptTypeSelect value={safeGet(["groupPolicy", "receiptTypeCode"], this.state, undefined)} disabled={policy.active} />
                        </Form.Item>
                        <Form.Item label={t("Group Fiscal Document Number")}>
                          <Input value={safeGet(["groupPolicy", "fiscalNumber"], this.state, undefined)} disabled />
                        </Form.Item>
                      </div>}
                      <Form.Item label={t("Issuance Commissions")}>
                        {getFieldDecorator("commissions", {})(<CommissionDetailField currency={currency} lifePolicyId={policy.id} disabled />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Modal width={800} title={t("Change Bills")} visible={this.state.uniqueBillsVisible} onOk={() => this.setState({ uniqueBillsVisible: false })} onCancel={() => this.setState({ uniqueBillsVisible: false })}>
                    <UniqueBills changes={policy.Changes} currency={policy.currency} />
                  </Modal>
                  <div style={{ visibility: "hidden" }}>
                    {getFieldDecorator("restricted")}
                    {getFieldDecorator("organizationId")}
                    {getFieldDecorator("jCertificateGroups")}
                    {getFieldDecorator("jCustomPolicyGroups")}
                    {getFieldDecorator("codeSuffix")}
                    {getFieldDecorator("inactiveReason")}
                    <Form.Item label={t("Internal ID")}>{getFieldDecorator("id", {})(<Input />)}</Form.Item>
                    <Form.Item label={t("Created")}>{getFieldDecorator("created", {})(<Input />)}</Form.Item>
                    <Form.Item label={t("Code")}>{getFieldDecorator("code", {})(<Input />)}</Form.Item>
                    <Form.Item label={t("ProcessId")}>{getFieldDecorator("processId", {})(<Input />)}</Form.Item>
                    <Form.Item label={t("Entity State")}>{getFieldDecorator("entityState", {})(<Input />)}</Form.Item>
                  </div>
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Risk Analysis////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Risk Analysis")) && <Tabs.TabPane
                  key="tab15"
                  tab={
                    <Tooltip title={t("Risk Analysis")}>
                      <Badge style={{ height: 15, lineHeight: "15px" }} offset={[4, 0]} count={riskFactors}>
                        <DashboardOutlined />
                        {this.state.showLabels && t("Risk Analysis")}
                      </Badge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Risk Analysis & Flags")}</Title>
                  {getFieldDecorator("RiskAnalysis")(<RiskAnalysis policyId={policy.id} />)}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Surcharges////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Surcharges and Discounts")) && <Tabs.TabPane
                  key="tab7"
                  tab={
                    <Tooltip title={t("Surcharges and Discounts")}>
                      <TabBadge count={surcharges.filter(p => p.value).length}>
                        <PercentageOutlined />
                        {this.state.showLabels && t("Surcharges and Discounts")}
                      </TabBadge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Surcharges and Discounts")}</Title>
                  {getFieldDecorator("Surcharges")(<Surcharges config={this.state.productConfig} readOnly={policy.active || !canEditSurcharge} policy={this.state.policy} />)}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Exclusions////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Exclusions")) && <Tabs.TabPane
                  key="tab8"
                  tab={
                    <Tooltip title={t("Exclusions")}>
                      <TabBadge count={exclusions.length}>
                        <StopOutlined />
                        {this.state.showLabels && t("Exclusions")}
                      </TabBadge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Exclusions")}</Title>
                  {getFieldDecorator("Exclusions")(
                    <Exclusions config={this.state.productConfig} coverages={this.state.policy.Coverages} readOnly={policy.active || !canEditExclusion} myOrganizationId={myOrganizationId}  />
                  )}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Clauses////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Policy Clauses")) && <Tabs.TabPane
                  forceRender
                  key="tab19"
                  tab={
                    <Tooltip title={t("Policy Clauses")}>
                      <TabBadge count={clauses.length}>
                        <CheckSquareOutlined />
                        {this.state.showLabels && t("Clauses")}
                      </TabBadge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Clauses")}</Title>
                  {getFieldDecorator("Clauses")(<Clauses config={this.state.productConfig} policy={this.state.policy} readOnly={policy.active} myOrganizationId={myOrganizationId} />)}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Documents////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Documents")) && <Tabs.TabPane
                  key="tab9"
                  tab={
                    <Tooltip title={t("Documents")}>
                      <TabBadge count={documents.length}>
                        <FilePdfOutlined />
                        {this.state.showLabels && t("Docs")}
                      </TabBadge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Documents")}</Title>
                  <Button
                    type="link"
                    icon={<UploadOutlined />}
                    onClick={() => this.setState({ uploadVisible: !this.state.uploadVisible })}
                    style={{ position: "relative", float: "left" }}>
                    {t("Upload")}
                  </Button>
                  <FileUpload hidden={!this.state.uploadVisible} onChange={(file) => this.onAddDocument(file)} />
                  {getFieldDecorator("Documents")(
                    <PolicyDocs
                      requirements={this.state.policy && this.state.policy.Requirements}
                      policyId={policy.id}
                      reload={() => this.reload()}
                      config={this.state.productConfig}
                    />
                  )}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Requirements////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Requirements")) && <Tabs.TabPane
                  key="tab10"
                  tab={
                    <Tooltip title={t("Requirements")}>
                      <Badge count={requirements.length} dot>
                        <WarningOutlined />
                        {this.state.showLabels && t("Requirements")}
                      </Badge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Requirements")}</Title>
                  {getFieldDecorator("Requirements")(
                    <Requirements config={this.state.productConfig} policyId={policy.id} policy={policy} onAddPolicyFile={(file) => this.onAddDocument(file)} />
                  )}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Accounts////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Accounts")) && <Tabs.TabPane
                  key="tab11"
                  tab={
                    <Tooltip title={t("Accounts")}>
                      <GlobalOutlined />
                      {this.state.showLabels && t("Accounts")}
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Accounts")}</Title>
                  {getFieldDecorator("Accounts")(<Accounts />)}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Changes////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Changes")) && <Tabs.TabPane
                  key="tab12"
                  disabled={this.props.snapshot}
                  tab={
                    <Tooltip title={t("Changes")}>
                      <TabBadge count={changes.length}>
                        <EditOutlined />
                        {this.state.showLabels && t("Changes")}
                      </TabBadge>
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Changes")}</Title>
                  {getFieldDecorator("Changes")(
                    <Changes policy={this.state.policy} config={this.state.productConfig} userActions={userActions} reload={() => this.reload()} configProfile={this.props.configProfile} multipleRoles={multipleRoles} checkTabPermissions={this.props.checkTabPermissions} />
                  )}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Collection////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Incoming Payments")) && <Tabs.TabPane
                  key="tab13"
                  tab={
                    <Tooltip title={t("Incoming Payments")}>
                      <CalendarOutlined />
                      {this.state.showLabels && t("Incoming Payments")}
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Incoming Payments")}</Title>
                  {getFieldDecorator("PayPlan")(
                    <PaymentPlan
                      policyId={policy.id}
                      policyCode={policy.code}
                      currency={currency}
                      hasPremiumPlan={hasPremiumPlan}
                      hasPenaltyInterest={hasPenaltyInterest}
                      policyType={this.state.policy.policyType}
                      config={this.state.productConfig}
                      reload={() => this.reload()}
                      readOnly={policy.active || policy.inactiveDate}
                      shortTerm={policy.duration == 0 && (policy.durationMonths > 0 || policy.durationDays > 0)}
                      parsedValues={this.props.snapshot}
                    />
                  )}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Anniversaries ////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Anniversaries")) && <Tabs.TabPane
                  key="tab16"
                  tab={
                    <Tooltip title={t("Anniversaries")}>
                      <PaperClipOutlined />
                      {this.state.showLabels && t("Anniversaries")}
                    </Tooltip>
                  }>
                  <Title level={4}>{t("Anniversary Procedures")}</Title>
                  {getFieldDecorator("Anniversaries")(
                    <Anniversaries policyId={this.state.policy.id} currency={currency} shortTerm={policy.duration == 0 && (policy.durationMonths > 0 || policy.durationDays > 0)}
                      changes={this.state.policy.Changes} reload={() => this.reload()} />
                  )}
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP tasks////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Policy Tasks")) && <Tabs.TabPane
                  key="tab18"
                  tab={
                    <Tooltip title={t("Policy Tasks")}>
                      <PushpinOutlined />
                      {this.state.showLabels && t("Policy Tasks")}
                    </Tooltip>
                  }>
                  <EntityTasks entity="lifePolicy" entityId={policy.id} />
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP Info////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("More Info")) && <Tabs.TabPane
                  key="tab14"
                  forceRender
                  tab={
                    <Tooltip title={t("More Info")}>
                      <InfoOutlined />
                      {this.state.showLabels && t("More Info")}
                    </Tooltip>
                  }>
                  <Title level={4}>{t("More Info")}</Title>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={t("IFRS 17 Code")}>{getFieldDecorator("ifrs17Code", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Master Product Code")}>{getFieldDecorator("masterCode", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Product Version")}>{getFieldDecorator("version", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Policy Version")}>{getFieldDecorator("policyVersion")(<FormLabel code paragraph showZero />)}</Form.Item>
                      <Form.Item label={t("Commercial Product Name")}>{getFieldDecorator("commercial", {})(<FormLabel paragraph />)}</Form.Item>
                      <Form.Item label={t("Proposal Package Status")}>{getFieldDecorator("proposal_package_status", {})(<FormLabel paragraph />)}</Form.Item>
                      <Form.Item label={t("Proposal Package Reason")}>{getFieldDecorator("proposal_package_reason", {})(<FormLabel paragraph />)}</Form.Item>
                      <Form.Item label={t("Last Actuarial Update")}>
                        {getFieldDecorator("lastActuarialUpdate", {})(<FormLabel code paragraph />)}
                      </Form.Item>
                      <Form.Item label={t("Change ID")}>{getFieldDecorator("changeId", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Re-Adjustment")}>{getFieldDecorator("reAdjustment", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Re-Evaluation")}>{getFieldDecorator("reEvaluation", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Alias")}>{getFieldDecorator("alias", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("External Policy Code")}>{getFieldDecorator("extPolicy", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("External Certificate Code")}>{getFieldDecorator("extCertificate", {})(<FormLabel code paragraph />)}</Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("Days insured")}>{getFieldDecorator("daysInsured", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Contract year")}>{getFieldDecorator("contractYear", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("UW Flag")}>{getFieldDecorator("uwFlag", { valuePropName: "checked" })(<Checkbox disabled />)}</Form.Item>
                      <Form.Item label={t("AML Flag")}>{getFieldDecorator("amlFlag", { valuePropName: "checked" })(<Checkbox disabled />)}</Form.Item>
                      <Form.Item label={t("Indexation Option Flag")}>
                        {getFieldDecorator("indexationOption", { valuePropName: "checked" })(<Checkbox disabled />)}
                      </Form.Item>
                      <Form.Item label={t("Limited Indexation Flag")}>
                        {getFieldDecorator("limitedIndexation", { valuePropName: "checked" })(<Checkbox disabled />)}
                      </Form.Item>
                      <Form.Item label={t("Certificate")}>{getFieldDecorator("certificate", {})(<FormLabel code paragraph />)}</Form.Item>
                      <Form.Item label={t("Temporal Status")}>
                        {getFieldDecorator("active", { valuePropName: "checked" })(
                          <Switch onChange={(v) => this.onTemporalStatusChange(v)} disabled={!temporalStatusOption} />
                        )}
                      </Form.Item>
                      <Form.Item label={t("Paid-Up")}>{getFieldDecorator("paidUp", { valuePropName: "checked" })(<Switch disabled />)}</Form.Item>
                      <Form.Item label={t("Original Policy")}>{getFieldDecorator("originalPolicyId")(<PolicyLink />)}</Form.Item>
                      <Form.Item label={t("External Bank Certificate Code")}>{getFieldDecorator("extBankCertificate", {})(<FormLabel code editable={{ onChange: v => this.props.form.setFieldsValue({ extBankCertificate: v }) }} />)}</Form.Item>
                      <Form.Item label={t("Custom Pay Plan")}>{getFieldDecorator("customPayPlan", { valuePropName: "checked" })(<Checkbox disabled />)}</Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>}
                {/* ////////////////////////////////STEP calcsheet (beta)////////////////////////////////////////////////////////// */}
                {!hidePolicyTabs.includes(t("Calc Views")) && <Tabs.TabPane
                  key="tab22"
                  tab={
                    <Tooltip title={t("Calc Views")}>
                      <FileExcelOutlined />
                      {this.state.showLabels && t("Calc Views")}
                    </Tooltip>
                  }>
                  <CalcView config={this.state.productConfig} policyId={policy.id} />
                </Tabs.TabPane>}
              </Tabs>
            </Form>

            <Button type="primary" hidden={this.state.currentStep !== 1} onClick={() => this.onNext()}>
              {t("Next")}
              <RightOutlined />
            </Button>
          </Col>

          <Col span={this.state.collapseActivityPanel ? 0 : 8}>
            <Tabs>
              <Tabs.TabPane
                key="panel1"
                tab={
                  <span>
                    <CoffeeOutlined />
                    {t("Activity")}
                  </span>
                }>
                {getFieldDecorator("Events")(<Events />)}
              </Tabs.TabPane>

              <Tabs.TabPane
                key="panel2"
                disabled={!policy.id}
                tab={
                  <Tooltip title="Related">
                    <BlockOutlined />
                    {t("Related")}
                  </Tooltip>
                }>
                <Related policyId={policy.id} />
              </Tabs.TabPane>
              <Tabs.TabPane
                forceRender
                key="panel3"
                tab={
                  <span>
                    <MessageOutlined />
                    {t("Comments")} <Badge count={this.state.numberOfComments}></Badge>
                  </span>
                }>
                <Comments policyId={policy.id} numberOfComments={(q) => this.setState({ numberOfComments: q })} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <PaymentRequest
          visible={this.state.paymentRequestVisible}
          onCancel={() => this.onCancelPaymentRequest()}
          policyId={policy.id}
          currency={currency}
          holderId={policy.holderId}
          Accounts={policy.Accounts}
          noWorkflow={true}
          onOk={() => this.onOkPaymentRequest()}
        />
        <FacultativeEditor visible={this.state.facultativeEditorVisible} policyId={policy.id} onCancel={() => this.setState({ facultativeEditorVisible: false })} />
        <CustomPolicyGroups policyId={policy.id} groups={() => this.getGroups()} visible={this.state.customPolicyGroupsVisible} onOk={(values) => this.onCustomPolicyGroupsUpdated(values)} onCancel={() => this.setState({ customPolicyGroupsVisible: false })} />
        {this.props.form.getFieldDecorator("jCustomPlanOptions")(<CustomPlanOptions
          planOptionsConfig={planOptions}
          planOptionsValue={this.props.form.getFieldValue("planOptions")}
          visible={this.state.planOptionsVisible}
          onOk={(values) => this.onPlanOptionsUpdated(values)}
          onCancel={() => this.setState({ planOptionsVisible: false })} />)}

        <Modal title={t("Cession Graph")} visible={this.state.cessionGraphVisible} onCancel={() => this.setState({ cessionGraphVisible: false })}
          width={900} onOk={() => this.setState({ cessionGraphVisible: false })} >
          {this.state.cessionGraphVisible && <ReCessionGraph policyId={policy.id} />}
        </Modal>
        <NewPolicyVersion visible={this.state.newVersionVisible} onOk={newPolicy => this.onNewVersion(newPolicy)}
          onCancel={() => this.setState({ newVersionVisible: false })} policy={policy} />
        <ClonePolicyOptions config={this.state.productConfig} visible={this.state.clonePolicyOptionsVisible} onCancel={() => this.setState({ clonePolicyOptionsVisible: false })}
          onOk={v => { this.setState({ clonePolicyOptionsVisible: false }); this.onClone(v) }} />
        
        <PolicyRestrictions visible={this.state.policyRestrictionsVisible} 
        onCancel={() => this.setState({ policyRestrictionsVisible: false })} 
        onOk={() => this.setState({ policyRestrictionsVisible: false })} 
        policyId={policy.id} />
      </DefaultPage>
    );
  }
}

const WrappedLifePolicy = Form.create()(LifePolicy);
export default withTranslation()(WrappedLifePolicy);
