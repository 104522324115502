import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Table, message, Statistic, Space, Spin } from 'antd';
import { useTranslation } from "react-i18next";
import PolicyCoverageSelect from '../Health/PolicyCoverageSelect';
import { exe } from '../../Lib/Dal';
import Spreadsheet from '../Shared/Spreadsheet';
import { formatMoney } from '../../Lib/Helpers';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';

const CessionEditor = (props) => {
    const [t]= useTranslation();
  const [loading, setLoading] = useState(false);
  const [coverageId, setCoverageId] = useState();
  const [data,setData]=useState([]);
  const [spreadsheet,setSpreadsheet]=useState();
  const [policy,setPolicy]=useState();

  useEffect(() => {
    if (props.visible) {
      loadPolicy(props.policyId);
    }
  }, [props.visible]);

  useEffect(() => {
    if (coverageId) {
        loadCessions(props.policyId,coverageId);
        }
    }, [coverageId,props.visible]);

  const loadPolicy = (policyId) => {
    setLoading(true);
    exe("RepoLifePolicy", { operation: "GET", filter: `id=${policyId}`,include:["Coverages"]}).then((r) => {
      setLoading(false);
      if (r.ok) {
        setPolicy(r.outData[0]);
      } else {
        message.error(r.msg);
      }
    }
    );

  }
  const loadCessions=(policyId,coverageId)=>{
    setLoading(true);
    exe("RepoCession", {operation:"GET",filter:`lifePolicyId=${policyId} AND coverageId=${coverageId} AND overwritten=0 AND np=0`}).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
        }
    });
    }
    const handleOk = () => {
        props.onCancel();
       };
       const handleCancel = () => {
         props.onCancel();
       };

       const setTableStyle=(records,instance)=>{
        const styleObj={};
        for (let i = 0; i < records; i++) {
          styleObj["B"+(i+1)]="font-weight:bold;text-align:left";
          styleObj["C"+(i+1)]="text-align:left";
        }
        instance.setStyle(styleObj);
      }
      const sumInsured=data[0]?.sumInsured;
      const premium=data[0]?.premium;

      const onSave=()=>{
        const newdata=spreadsheet.getData();
        const cessions=[];
        for (let i = 0; i < newdata.length; i++) {
          if(!newdata[i][3]&&!newdata[i][4]&&!newdata[i][5]&&!newdata[i][6]) continue; //blank line, ignoring
          const cession={
            //if id is empty, then it is a new cession ie id=0
            id:newdata[i][0]||0,
            contractId:newdata[i][1],
            lineId:newdata[i][2]||"NEW",

            sumInsuredCedant:newdata[i][3]||0,
            premiumCedant:newdata[i][4]||0,
            sumInsuredRe:newdata[i][5]||0,
            premiumRe:newdata[i][6]||0,
            reserve:newdata[i][7]||0,
            lifePolicyId:policy.id,
            coverageId:coverageId
          }
          cessions.push(cession);
        }
        //ensuring all cessions have a contract id
        if(cessions.filter(p=>!p.contractId).length>0){
          message.error(t("All cessions must have a contract id"));
          return;
        }
        console.log(cessions);
        setLoading(true);
        exe("EditCessions",{jCessions:JSON.stringify(cessions),policyId:props.policyId,coverageId:coverageId}).then(r=>{
          setLoading(false);
          if(r.ok){
            message.success(r.msg);
            props.onOk();
          }else message.error(r.msg);
        });

      }
  return (
      <Modal
        visible={props.visible}
        title={t("Cession Editor")}
        onOk={handleOk}
        onCancel={handleCancel}
        
        width={"90%"}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
         
        ]}
      >
        <div style={{display:"flex",justifyContent: "space-between"}}>
        <PolicyCoverageSelect coverages={policy?.Coverages} value={coverageId} onChange={v=>setCoverageId(v)} />
            {coverageId&&<Space>
                <Statistic title={t("Sum Insured")} value={formatMoney(sumInsured,policy.currency)} style={{marginRight:5}} />
                <Statistic title={t("Premium")} value={formatMoney(premium,policy.currency)} />
                </Space>}

        </div>
        {coverageId&&<div>
          <Button type='primary' onClick={onSave} icon={<SaveOutlined />} style={{marginBottom:5}} loading={loading} >{t("Save")}</Button>
          <Button type='link' icon={<ReloadOutlined />} onClick={()=>loadCessions(props.policyId,coverageId)}>{t("Reload")}</Button>
          </div>}
        {coverageId&&<Spreadsheet
          instance={(t) => {window.spreadsheet=t;setSpreadsheet(t); setTableStyle(data.length,t);}}
          data={data.sort(p=>p.id).map(p=>[p.id,p.contractId,p.lineId,p.sumInsuredCedant,p.premiumCedant,p.sumInsuredRe,p.premiumRe,p.reserve])}
          columns={[
            { title: t("Id"), width: 90 },
            { title: t("Contract Id"), width: 90 },
            { title: t("Line Id"), width: 90 },
            { title: t("Sum Insured Retained"), width: 200 },
            { title: t("Premium Retained"), width: 200 },
            // { title: t("Commission"), width: 200 },
            { title: t("Sum Insured Ceded"), width: 200 },
            { title: t("Premium Ceded"), width: 200 },
            { title: t("Reserve"), width: 200 },



           
          ]}
      />}
       
      </Modal>
  );
};

export default CessionEditor;