import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space, Typography } from "antd";
import React, { useState } from "react";

const EditableFormFieldNumber = (props) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(props.value);
  const [dirty, setDirty] = useState(false);

  const onChange = (v) => {
    setValue(v);
  };
  const onSubmitChange = () => {
    console.log("submitting", value);
    props.onChange(value);
    setEditable(false);
    setDirty(true);
  };
  const onCancelChange = (e) => {
    setEditable(false);
  };
  return (
    <div className="editable-cell">
      {editable ? (
        <Space>
            <InputNumber
            value={value}
            onChange={(v) => onChange(v)}
            onPressEnter={(v) => onSubmitChange()}
            autoFocus
            style={{ minWidth: 200, maxWidth: 800, width: value ? value.length * 10 : 200 }}
            onBlur={(e) => onCancelChange(e)}
            suffix={
                <div>
                <Button
                    type="link"
                    icon={<CheckOutlined />}
                    onMouseDown={(e) => {
                    onSubmitChange();
                    }}
                />
                {/* <Button type="link" icon="close" onClick={(v) => onCancelChange()} /> */}
                </div>
            }
            /> 
            <Button type="link" icon={<CheckOutlined />} onMouseDown={(e) => {
            onSubmitChange();
            }}
      />
        </Space>
      ) : (
        <div style={{ ...{ paddingRight: 24, ...props.style } }}>
          {/* <span style={{color:"#428bca",borderBottom:"dashed 1px #0088cc",cursor:"pointer" }}>{value || ""}</span> */}
          <Typography.Text code={!dirty} mark={dirty}>
            {props.value}
          </Typography.Text>
          {!props.readOnly && <Button type="link" icon={<EditOutlined />} style={{ marginLeft: 5 }} onClick={() => setEditable(true)} />}
        </div>
      )}
    </div>
  );
};

export default EditableFormFieldNumber;
