import React, { useState, useEffect } from "react";
import { CheckCircleOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Button,
  Steps,
  DatePicker,
  message,
  Result,
  Collapse,
  Popconfirm,
} from "antd";
import Section from "../Shared/Section";
import Bill from "./Bill";
import { exe, safeParse } from "../../Lib/Dal";
import moment from "moment";
import { load } from "js-yaml";
import BillUnique from "./BillUnique";
import Text from "antd/lib/typography/Text";
import { formatDate } from "../../Lib/Helpers";
import EffectiveDate from "./EffectiveDate";
import Amendments from "./Amendments";
import { useTranslation } from "react-i18next";
import CertificateRemove from "./CertificateRemove";
import CommonChangeSection from "./CommonChangeSection";

const ChangeRemoveCertificate = (props) => {
  const [t] = useTranslation();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setValues(props.value);
    if (!values.id) load();
  }, [props.value]);
  useEffect(() => {if(props.common.customEffect!==undefined) props.common.customEffect(setStep)}, [props.common.customEffectDeps]);

  const field = props.form.getFieldDecorator;
  const currency = props.currency;
  const policyId = props.policyId;

  const onQuote = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const operation = values.id ? "UPDATE" : "ADD";
        console.log(values);
        //validation OK
        setLoading(true);
        exe("ChangeRemoveCertificate", {
          ...values, policyId: policyId,
          jRemovedCertificates: values.jRemovedCertificates,
          effectiveDate: values.effectiveDate,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            if (operation == "ADD") {
              props.form.setFieldsValue({
                ...r.outData,
                jRemovedCertificates: values.jRemovedCertificates,
              });
            } else {
              //if the user re-quotes after saving
              props.form.setFieldsValue({
                ...r.outData,
                jRemovedCertificates: values.jRemovedCertificates,
                id: values.id,
                code: values.code,
                note: values.note,
              });
            }
            setStep(step + 1);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };

  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        const operation = values.id == 0 ? "ADD" : "UPDATE";
        if (operation == "UPDATE") {
          onUpdate(values);
          return;
        }
        setLoading(true);
        exe("ChangeRemoveCertificate", {
          ...values,
          ...values, policyId: policyId,
          jRemovedCertificates: values.jRemovedCertificates,
          effectiveDate: values.effectiveDate,
          operation: "ADD",
          code: values.code,
          note: values.note,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue({
              ...r.outData,
              jRemovedCertificates: values.jRemovedCertificates,
            });
            setStep(step + 1);
            setResult({
              status: "success",
              title: t("Successfully Saved Policy Change"),
              subTitle: t("Change number") + ":" + r.outData.id,
            });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onUpdate = (values) => {
    const updated = {
      ...values,
      jRemovedCertificates: values.jRemovedCertificates,
    };
    setLoading(true);
    exe("ChangeRemoveCertificate", { Entity: updated, operation: "UPDATE" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setStep(step + 1);
        setResult({ status: "success", title: t("Successfully Updated Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("ExeChangeRemoveCertificate", { changeId: values.id, operation: "EXECUTE" }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setValues(r.outData);
          setResult({
            status: "success",
            title: r.outData.status == 1 ? t("Successfully Executed Policy Change") : t("Policy Change Scheduled"),
            subTitle:
              t("Change number") +
              ":" +
              r.outData.id +
              (r.outData.status == 1
                ? t(". Please refresh policy or click on 'Change List'")
                : t("Execution date") + ":" + formatDate(r.outData.effectiveDate)),
          });
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const setValues = (r) => {
    props.form.setFieldsValue(r);

    if (r.status == 0) {
      setResult({ status: "info", title: t("Saved Policy Change Ready To Be Executed"), subTitle: t("Change number") + ":" + r.id });
    } else if (r.status == 1 || r.status == 3) {
      setResult({ status: "info", title: t("Policy Change Executed"), subTitle: t("Change Execution Date") + ":" + r.executionDate });
      setReadOnly(true);
    }
  };
  const values = props.form.getFieldsValue();

  return (
    <div style={{ marginTop: 10 }}>
      <Form layout="vertical">
        <Steps current={step} onChange={(v) => setStep(v)} style={{ margin: 5, marginBottom: 15 }}>
          <Steps.Step title={t("Change")} description={t("Change options")} />
          <Steps.Step title={t("Quote")} description={t("Cost of changes")} />
          <Steps.Step title={t("Result")} description={t("Execution")} />
        </Steps>
        <Row gutter={16} hidden={step !== 0}>
          <Col span={24}>
            <Section text={t("Change Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={<Text strong>{t("Removed Certificates")}</Text>}>
                {field("jRemovedCertificates", { initialValue: "" })(
                  <CertificateRemove currency={props.currency} groupPolicyId={policyId} readOnly={readOnly} />
                )}
              </Form.Item>

              <Form.Item label={t("Effective Date")}>
                {field("effectiveDate", {
                  rules: [
                    {
                      required: true,
                      validator: (rule, v, cb) => (v ? cb(v.validationError) : cb(t("Effective date is required"))),
                    },
                  ],
                })(<EffectiveDate anniversary={props.anniversary} allowPastDates={props.common.allowPastEffectDate} />)}
              </Form.Item>
             <CommonChangeSection t={t} form={props.form} common={props.common} change={props.value}  /> 
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
            <Button style={{ marginTop: 5 }} type="primary" onClick={onQuote} loading={loading} disabled={readOnly}>
              {t("Quote")}
              <RightOutlined />
            </Button>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 1}>
          <Col span={16}>
            <Collapse defaultActiveKey={["cancellationBill"]}>
              <Collapse.Panel header={t("Cancellation Bill")} key="cancellationBill">
                <div className="disabled">
                  <Form.Item>{field("CancellationBill", {})(<Bill currency={currency} config={props.config} />)}</Form.Item>
                </div>
              </Collapse.Panel>
              <Collapse.Panel header={t("Change Bill Unique")} key="changeBillUnique">
                <Form.Item>{field("BillUnique", {})(<BillUnique currency={currency} />)}</Form.Item>
              </Collapse.Panel>
              {/* <Collapse.Panel header="Pro Rata Cancellation" key="cancellationBill">
                <Form.Item>{field("CancellationBill", {})(<Bill currency={currency} />)}</Form.Item>
              </Collapse.Panel> */}
            </Collapse>
          </Col>
          <Col span={8}>
            <Section text={t("Options")}>
              {field("processId")}
              <Form.Item label={t("Id")}>{field("id", {})(<Input disabled />)}</Form.Item>
              <Form.Item label={t("Code")}>{field("code", {})(<Input placeholder={t("Optional manual code")} />)}</Form.Item>
              <Form.Item label={t("Note")}>
                {field("note", {})(<Input.TextArea rows={4} placeholder={t("Optional printable change note or remark")} />)}
              </Form.Item>
              <div style={{ visibility: "hidden" }}>
                {field("lifePolicyId", {})(<Input disabled />)}
                {field("jOldBeneficiaries", {})(<Input disabled />)}
                {field("jNewBeneficiaries", {})(<Input disabled />)}
              </div>
              <div style={{ marginTop: 20 }}>
                <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={() => setStep(step - 1)}>
                  {t("Back")}
                </Button>
                <Button type="primary" icon={<SaveOutlined />} style={{ marginRight: 5 }} onClick={onSave} loading={loading} disabled={readOnly}>
                  {t("Save")}
                </Button>
              </div>
            </Section>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 2}>
          <Col span={24}>
            <Result
              {...result}
              extra={[
                <Button key="console" onClick={() => props.reload()}>
                  {readOnly ? t("Change List") : t("Execute later")}
                </Button>,
                <Popconfirm
                  title={t("Are you sure you want to execute this policy change?")}
                  onConfirm={onExecute}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  disabled={readOnly}>
                  <Button icon={<CheckCircleOutlined />} type="primary" style={{ marginRight: 5 }} disabled={readOnly} loading={loading}>
                    {t("Execute")}
                  </Button>
                </Popconfirm>,
              ]}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ChangeRemoveCertificate);
