import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Space,
  Checkbox,
  InputNumber,
  message,
  Tabs,
} from "antd";
import { exe, safeGetRaw } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import ProductSelect from "./ProductSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import PolicySelect from "../Health/PolicySelect";
import GroupPolicySelect from "./GroupPolicySelect";
import PolicyTypeSelect from "./PolicyTypeSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import HoldingSelect from "../Contact/HoldingSelect";
import { useTranslation } from "react-i18next";
import SellerSelect from "../Commissions/SellerSelect";
import ContactSelect from "../Contact/ContactSelect";
import DatePickerW from "../Shared/DatePickerW";
import ChangeSelect from "./ChangeSelect";
import LobTypeSelect from "./LobTypeSelect";
import useUpdateEffect, { getConfigProfile } from "../../Lib/Helpers";
import queryString from 'query-string';
import SelectionSelect from "../Contract/SelectionSelect";
import Currency from "../Shared/Currency";
import PolicyRestrictedStatusSelect from "./PolicyRestrictedStatusSelect";
import OrganizationSelect from "../Settings/OrganizationSelect";

const LifePolicyListFilter = (props) => {
  const [t] = useTranslation();
  const [matches, setMatches] = useState(null);
  const [loading,setLoading]=useState(false);

  useUpdateEffect(() => {
    submit();
  }, [props.pagination.current]);

  useEffect(() => {
      if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
    }
    getConfigProfile().then((profile) => {
      const filterLobBySection=safeGetRaw(["Main","filterLobBySection"],profile,false);
      if(filterLobBySection){
        const qs = queryString.parse(window.location.hash);
        if (qs.section) props.form.setFieldsValue({lobType:qs.section.toUpperCase()});
      }
      submit();
    });
  }, [window.location.hash]);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      if (values.date)
        queries.push(`YEAR(created)=${values.date.format("YYYY")} AND MONTH(created)=${values.date.format("M")} AND DAY(created)=${values.date.format("D")}`);
      if (values.lob !== undefined) queries.push(`lob='${values.lob}'`);
      if (values.product !== undefined) queries.push(`productCode='${values.product.key}'`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);
      if (values.status !== undefined) queries.push(`entityState='${values.status}'`);
      if (values.active !== undefined) queries.push(`active='${values.active}'`);
      if (values.grossValue !== undefined) queries.push(`grossValue${values.grossValue}`);
      if (values.insuredSum !== undefined) queries.push(`insuredSum${values.insuredSum}`);
      if (values.paid !== undefined) queries.push(`id in (SELECT lifePolicyId FROM PayPlan GROUP BY lifePolicyId HAVING SUM(payed)${values.paid})`);
      if (values.id !== undefined && values.id !== null) queries.push(`id=${values.id}`);
      if (values.code !== undefined && values.code !== null) queries.push(`id=${values.code}`);
      if (values.policyType !== undefined) queries.push(`policyType='${values.policyType}'`);
      if (values.groupPolicyId !== undefined) queries.push(`groupPolicyId=${values.groupPolicyId}`);
      if (values.holderId !== undefined) queries.push(`holderId=${values.holderId}`);
      if (values.sellerId !== undefined) queries.push(`sellerId=${values.sellerId}`);
      if (values.holdingId !== undefined) queries.push(`holderId in (select id from Contact where holdingId=${values.holdingId})`);
      if (values.categories !== undefined && values.categories.length > 0) {
        values.categories.forEach((category) => queries.push(`id in (select LifePolicyId from PolicyTag where tag='${category}')`));
      }
      if (values.anniversary !== undefined) {
        const startRange = values.anniversary.startOf("month").format("YYYY-MM-DD");
        const endRange = moment(startRange).endOf("month").format("YYYY-MM-DD");

        queries.push(`CASE WHEN GETUTCDATE()<DATEFROMPARTS(Year(GETUTCDATE()),month([start]),day([start])) THEN
        DATEFROMPARTS(Year(GETUTCDATE()),month([start]),day([start])) ELSE
        DATEFROMPARTS(Year(GETUTCDATE())+1,month([start]),day([start])) END
        BETWEEN '${startRange}' AND '${endRange}'`);
      }

      if (values.extPolicy) queries.push(`extPolicy='${values.extPolicy}'`);
      if (values.extCertificate) queries.push(`extCertificate='${values.extCertificate}'`);
      if (values.extBankCertificate) queries.push(`extBankCertificate='${values.extBankCertificate}'`);
      if (values.expirationDate) queries.push(`YEAR([end])=${values.expirationDate.format("YYYY")} AND MONTH([end])=${values.expirationDate.format("M")}`);
      if (values.startDate) queries.push(`YEAR([start])=${values.startDate.format("YYYY")} AND MONTH([start])=${values.startDate.format("M")}`);
      
      if(values.expirationDays!==undefined){
        queries.push(`[end]<(GETDATE()+${values.expirationDays}) and [end]>GETDATE() and active=1`);
      }
      if(values.change){
        queries.push(`id in (select lifePolicyId from Change where Discriminator='${values.change}')`);
      }
      if(values.anniversaryCount!==undefined){
        queries.push(`
            id in (
                SELECT lifePolicyId 
                FROM Anniversary 
                GROUP BY lifePolicyId 
                HAVING COUNT(*) = ${values.anniversaryCount}
            )
        `);

      }
      if(values.lobType){
        queries.push(`lob in (SELECT code FROM lob WHERE productType='${values.lobType}')`);
      }
      if(values.savedSelectionId){
        queries.push(`id in 
        (SELECT value from STRING_SPLIT((SELECT REPLACE(REPLACE(jSelection,'[',''),']','') FROM Selection WHERE id = ${values.savedSelectionId}),',') WHERE TRY_CAST(value as int) IS NOT NULL)`);
      }
      if(values.restrictedStatus){
        queries.push(`restricted='${values.restrictedStatus==='RESTRICTED'?1:0}'`);
      }
      if(values.organizationId){
        queries.push(`organizationId=${values.organizationId}`);
      }
      if(values.certificates){
        queries.push(`id in (SELECT groupPolicyId FROM lifepolicy where policyType='C' GROUP BY groupPolicyId HAVING COUNT(*)${values.certificates})`);
      }

      const filterQuery = queries.join(" AND ");
      setLoading(true);
      props.onLoading&&props.onLoading(true);
      exe("RepoLifePolicy", {
        operation: "GET",
        filter: filterQuery,
        include: ["Holder", "Process"],
        size: props.pagination.pageSize,
        page: props.pagination.current - 1,
      }).then((r) => {
        setLoading(false);
        props.onLoading&&props.onLoading(false);
        if (r.ok) {
          props.onResults(r.outData, r.total);
          setMatches(r.total);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const values=props.form.getFieldsValue();

  return (
    <div>
      <Drawer title={t("Policy Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{marginTop:-12 }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>
        <Form layout="vertical">
          <Tabs>
            <Tabs.TabPane tab={t("General")} key="general">
          <div style={{ display: "flex" }}>
            <Form.Item label={t("LoB")} style={{ width: "100%" }}>
              {getFieldDecorator("lob")(<LobSelect />)}
            </Form.Item>
            <Form.Item label={t("Product")} style={{ width: "100%", marginLeft: 5 }}>
              {getFieldDecorator("product")(<ProductSelect lobFilter={values.lob} />)}
            </Form.Item>
          </div>

          <div style={{ display: "flex" }}>
            <Form.Item label={t("Status")} style={{ width: "100%" }}>
              {getFieldDecorator("status")(<Input placeholder={t("Workflow Status")} />)}
            </Form.Item>
            <Form.Item label={t("In Force")} style={{ width: "100%", marginLeft: 5 }}>
              {getFieldDecorator("active")(
                <Select allowClear placeholder={t("Current in force status")}>
                  <Select.Option value="true">{t("In Force")}</Select.Option>
                  <Select.Option value="false">{t("Not In Force")}</Select.Option>
                </Select>
              )}
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Code")} style={{ width: "100%" }}>
              {getFieldDecorator("code")(<PolicySelect proposals />)}
            </Form.Item>
            <Form.Item label={t("Id")} style={{ width: "100%", marginLeft: 5 }}>
              {getFieldDecorator("id")(<InputNumber style={{ width: "100%" }} placeholder={t("Policy ID")} />)}
            </Form.Item>
          </div>

          <div style={{ display: "flex" }}>
          <Form.Item label={t("Policy Type")} style={{ width: "100%" }}>{getFieldDecorator("policyType")(<PolicyTypeSelect allowClear />)}</Form.Item>
          <Form.Item label={t("LoB Type")} style={{ width: "100%", marginLeft: 5  }}>{getFieldDecorator("lobType")(<LobTypeSelect />)}</Form.Item>
          </div>
          <Form.Item label={t("Group Policy")} style={{ width: "100%" }}>{getFieldDecorator("groupPolicyId")(<GroupPolicySelect />)}</Form.Item>
          <div style={{display:"flex"}}>
            <Form.Item label={t("Policyholder")} style={{width: "100%" }}>{getFieldDecorator("holderId")(<ContactSelect disableAddContact />)}</Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Seller")} style={{ width: "100%"}}>{getFieldDecorator("sellerId")(<SimpleContactSelect />)}</Form.Item>
          </div>
          
          <Form.Item label={t("Restricted Status")} style={{ width: "100%"}}>{getFieldDecorator("restrictedStatus")(<PolicyRestrictedStatusSelect />)}</Form.Item>
          <Form.Item label={t("Organization")} style={{ width: "100%"}}>{getFieldDecorator("organizationId")(<OrganizationSelect />)}</Form.Item>
          
            
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Dates")} key="dates">
              <Space>
                <Form.Item label={t("Creation Date")}>
                  {getFieldDecorator("date")(<DatePickerW placeholder={t("Select date")} />)}
                  </Form.Item>
                <Form.Item label={t("Next Anniversary")}>
                  {getFieldDecorator("anniversary")(<DatePicker.MonthPicker placeholder={t("Select month")} />)}
                </Form.Item>
              </Space>

              <Space>
                <Form.Item label={t("Start Date")} >
                  {getFieldDecorator("startDate")(<DatePicker.MonthPicker placeholder={t("Select month")} />)}
                </Form.Item> 
                <Form.Item label={t("Expiration Date")} >
                {getFieldDecorator("expirationDate")(<DatePicker.MonthPicker placeholder={t("Select month")}/>)}
              </Form.Item>
              </Space>

            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Other")} key="other">
            <Form.Item label={t("Insured Sum")}>
                    {getFieldDecorator("insuredSum")(<Compare />)}
              </Form.Item>
              <Form.Item label={t("Installment Premium Paid")}>{getFieldDecorator("paid")(<Compare />)}</Form.Item>
              <Form.Item label={t("Gross Value")}>{getFieldDecorator("grossValue")(<Compare />)}</Form.Item>
              <div style={{display:"flex"}}>
                <Form.Item label={t("External Policy")} style={{width: "100%" }}>{getFieldDecorator("extPolicy")(<Input />)}</Form.Item>
                <Form.Item label={t("External Certificate")} style={{width: "100%",marginLeft: 5  }}>{getFieldDecorator("extCertificate")(<Input />)}</Form.Item>
              </div>
              <div style={{display:"flex"}}>
                <Form.Item label={t("External Bank Certificate")} style={{width: "100%" }}>{getFieldDecorator("extBankCertificate")(<Input />)}</Form.Item>
                <Form.Item label={t("Days to expiration")} style={{width: "100%",marginLeft: 5  }}>{getFieldDecorator("expirationDays")(<InputNumber min={0} style={{width: "100%" }} placeholder={t("Insert days")} />)}</Form.Item>
              </div>
              <div style={{display:"flex"}}>
                <Form.Item label={t("Holding")} style={{width: "100%"  }}>{getFieldDecorator("holdingId")(<HoldingSelect />)}</Form.Item>
                <Form.Item label={t("Categories")} style={{ width: "100%", marginLeft: 5  }}>
                  {getFieldDecorator("categories")(
                      <Select mode="tags" style={{ width: "100%" }} tokenSeparators={[","]} placeholder={t("Type and enter to add category")} />
                  )}
                </Form.Item>
            
              </div>
              <Form.Item label={t("Change Type")}>
                    {getFieldDecorator("change")(<ChangeSelect />)}
              </Form.Item>
              <Form.Item label={t("Currency")}>
                    {getFieldDecorator("currency")(<Currency />)}
              </Form.Item>
              <Form.Item label={t("Certificate Count")}>{getFieldDecorator("certificates")(<Compare />)}</Form.Item>
              <Form.Item label={t("Saved Selection")}>{getFieldDecorator("savedSelectionId")(<SelectionSelect entity="LifePolicy" visible={props.visible} />)}</Form.Item>

              <Form.Item label={t("Anniversary Count")}>
                    {getFieldDecorator("anniversaryCount")(<InputNumber min={0} />)}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
                
        </Form>
        <Button style={{marginTop:10}} type="primary" icon={<SearchOutlined />} onClick={() => submit()} loading={loading}>
          {t("Search")}
        </Button>
        
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(LifePolicyListFilter);
