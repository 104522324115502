import React from 'react';
import {Select} from "antd";
import {useTranslation} from "react-i18next";

const StatusSelect = (props) => {
    const [t]=useTranslation();
    
    return (
            <Select style={props.style} value={props.value} onChange={props.onChange} allowClear={props.allowClear}>
                <Select.Option value={undefined}>{t("All")}</Select.Option>
                <Select.Option value={1}>{t("Active")}</Select.Option>
                <Select.Option value={0}>{t("Inactive")}</Select.Option>
            </Select>
    );
};

export default StatusSelect;