import React from "react";

import {
  AuditOutlined,
  BlockOutlined,
  BranchesOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CoffeeOutlined,
  CompassOutlined,
  CopyOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  EuroOutlined,
  FileOutlined,
  FilePdfOutlined,
  FormOutlined,
  GlobalOutlined,
  HeartOutlined,
  HistoryOutlined,
  InboxOutlined,
  MedicineBoxOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  PushpinOutlined,
  RedoOutlined,
  ReloadOutlined,
  SaveOutlined,
  ScissorOutlined,
  SoundOutlined,
  StopOutlined,
  SwapOutlined,
  ToolOutlined,
  UndoOutlined,
  UserOutlined,
  WarningOutlined,
  LeftOutlined
} from '@ant-design/icons';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Table,
  Select,
  Tabs,
  Row,
  Col,
  Input,
  message,
  Radio,
  Badge,
  Divider,
  Upload,
  Switch,
  Tag,
  Typography,
  InputNumber,
  Alert,
  Tooltip,
  Dropdown,
  Menu,
  ConfigProvider,
} from "antd";
import {exe, safe, safeGetRaw} from "Lib/Dal";
import ContactSelect from "../Contact/ContactSelect";
import Status from "../Shared/Status";
import ProcedureTable from "./ProcedureTable";
import RequirementsTable from "./RequirementsTable";
import ClaimTimeline from "./ClaimTimeline";
import DiagnosisSearch from "./DiagnosisSearch";
import ProviderSelect from "./ProviderSelect";
import PolicySelect from "./PolicySelect";
import Report from "./Report";
import DefaultPage from "../Shared/DefaultPage";
import Events from "../Life/Events";
import Payouts from "./Payouts";
import ClaimPolicyLife from "./ClaimPolicyLife";
import ClaimPayment from "./ClaimPayment";
import ClaimHeader from "./ClaimHeader";
import Related from "../Life/Related";
import Comments from "../Life/Comments";
import { safeGet, safeValue } from "../../Lib/Dal";
import InsuredSelect from "../Life/InsuredSelect";
import InsuredEventSelect from "./InsuredEventSelect";
import EventReason from "./EventReason";
import FormLabel from "../Shared/FormLabel";
import FraudAnalysis from "./FraudAnalysis";
import ClaimDocs from "./ClaimDocs";
import config from "../../config";
import AffectedCoverages from "./AffectedCoverages";
import { withTranslation } from "react-i18next";
import ReEventSelect from "../Contract/ReEventSelect";
import { Link } from "react-router-dom";
import EntityTasks from "../Life/EntityTasks";
import ClosureTypeSelect from "./ClosureTypeSelect";
import RelatedClaims from "./RelatedClaims";
import CustomForm from "../Shared/CustomForm";
import ClaimSalvage from "./ClaimSalvage";
import ClaimTypeSelect from "./ClaimTypeSelect";
import Accounts from "../Life/Accounts";
import PaymentRequest from "../Payments/PaymentRequest";
import CheckFacultative from "./CheckFacultative";
import Audits from "./Audits";
import MapRender from "../Property/MapRender";
import ClosureUndoMotiveSelect from "./ClosureUndoMotiveSelect";
import SubClaims from "./SubClaims";
import {getConfigProfile, setDate} from "../../Lib/Helpers";
import DatePickerW from "../Shared/DatePickerW";
import ClaimStageSelect from "./ClaimStageSelect";

const { Text, Title } = Typography;
const { Dragger } = Upload;
const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;

class HealthClaim extends React.Component {
  static contextType = ConfigProvider.ConfigContext;
  state = {
    claim: {},
    loading: false,
    activeKeyTab: "notification",
    blocked: false, //entity validity for wf advance. ie. doc requirements
    blockedMsg: null,
    numberOfComments: 0,
    policy: undefined,
    collapseActivityPanel: false,
    validInsuredEvents: "",
    processes: [],
    customForms: [], //tabbed forms definition
    customFormsValues: undefined, //tabbed form values
    moneyOutRequest:false,
    insuredEventsTable:[], //SysInsuredEventsPerCoverage table
    validEventReasons:[],
    hasHealthProcedures:false,
    conditionDisabledTabs:[], //tabs to disable when condition in config profile evaluated to true
    refreshPayouts:0
  };
  componentDidMount() {
    this.load(this.props.match.params.claimId); //carga inicial
    if (this.props.match.params.tab) this.setState({ activeKeyTab: this.props.match.params.tab });
    this.loadProcesses();
    this.loadInsuredEventsTable();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.claimId !== this.props.match.params.claimId) this.load(this.props.match.params.claimId); //update de url
  }
  loadInsuredEventsTable(){
    exe("GetFullTable", { table: "SysInsuredEventsPerCoverage" }).then((r) => {
      if (r.ok) {
        let table = r.outData;
        //converting the third column to array
        try{
        if (table.length > 0) {
          table.forEach((p) => {
            if (p[2]) p[2] = p[2].split(",").map((p) => p.trim());
            else p[2] = [];
          });
      }
    }catch(e){
      console.error("Error parsing SysInsuredEventsPerCoverage",e);
    }
        this.setState({insuredEventsTable: table});
      }else {
        console.warn("No SysInsuredEventsPerCoverage loaded");
      }
    })
  }
  loadProcesses = () => {
    exe("GetDefinitions", { filter: "entity='Claim' and enabled=1" }).then((r) => {
      this.setState({ processes: r.outData || [] });
    });
  };
  save = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //validating multiforms
        const customFields = {};
        let customFieldsWithError = false;
        this.state.customForms.forEach((p) => {
          const containterName = "customClaimForm_" + p.name;
          if (window[containterName] && window[containterName].instanceContainers[0]) {
            if (window[containterName].instanceContainers[0].reportValidity()) {
              customFields[p.name] = JSON.stringify(window[containterName].userData);
            } else {
              message.error(this.props.t("Please check custom form validation errors"));
              this.setState({ activeKeyTab: "custom" });
              customFieldsWithError = true;
            }
          }
        });
        if (customFieldsWithError) return;
        if (Object.keys(customFields).length > 0) values.jCustomForms = JSON.stringify(customFields);

        //validation OK
        values.id = this.props.match.params.claimId;
        const operation = values.id == 0 ? "ADD" : "UPDATE";
        console.log("Received values of form: ", values);

        if (values.Tags && values.Tags.length > 0)
          values.Tags = values.Tags.map((p) => ({
            tag: p,
            claimId: values.id,
          })); //mapenado tags

        values.contactId = +values.nameOfPatient;

        this.setState({ loading: true });

        exe("RepoClaim", { entity: values, operation: operation }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            if (r.data.operation == "ADD") window.location.hash = "#/healthclaim/" + r.outData[0].id;
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fiedls in all tabs"));
      }
    });
  };
  new = () => {
    window.location.hash = "#/healthclaim/0";
    this.state.customForms.forEach((p) => window["customClaimForm_" + p.name].load(p.formId));
    this.setState({ isPerson: true, claim: {}, procedures: [],customFormsValues:undefined });
    this.reset();
  };
  reset = () => {
    this.props.form.resetFields();
  };
  load = (claimId) => {
    getConfigProfile().then((profile) => {
      const allCustomForms=safeGet(["Claim", "customForms"], profile, []);
      const commonCustomForms = allCustomForms.filter((p) => !p.condition);
  
      if (claimId == 0) {
        console.log("SETTING CLAIM 0");
        //new claim
        if (this.props.location.policy) {
          //new claim from policy
          const policy = this.props.location.policy;
          this.props.form.setFieldsValue({ lifePolicyId: policy.id, claimerId: policy.holderId, nameOfPatient: policy.Principal.id });
          this.setState({ claim: { ...this.state.claim, Claimer: policy.Holder, Contact: policy.Principal },customForms:commonCustomForms,customFormsValues:undefined });
        } else {
            //new claim from scratch
          commonCustomForms.forEach((p) => window["customClaimForm_" + p.name]&&window["customClaimForm_" + p.name].load(p.formId));
          //checking if default values passed in url
          if(window.location.hash.includes("/0?")){
            const params = Object.fromEntries(new URLSearchParams(window.location.hash.split("/0?")[1]));
            //iterate through object properties and convert numbers to int
            Object.keys(params).forEach((p) => {
                if(!isNaN(params[p])) params[p]=+params[p];
            });
            console.log("setting default values",params);
            try {
                this.props.form.setFieldsValue(params);
            } catch (error) {
                console.warn("Error parsing defaultValues",error);
            }
          }else this.reset();
          
          this.setState({ isPerson: true, claim: {}, procedures: [],customForms:commonCustomForms,customFormsValues:undefined });
        }
        return; //nothing to load
      }
      //loading claim
      this.setState({ loading: true, reloading: claimId == this.state.claim.id,activeKeyTab:claimId == this.state.claim.id ? this.state.activeKeyTab : "notification" });
      exe("RepoClaim", {
        operation: "GET",
        filter: "id=" + claimId,
        include: [
          "Contact",
          "Claimer",
          "Process",
          "Process.Pasos",
          "Policy",
          "Policy.Coverages",
          "Policy.Coverages.Benefits",
          "Policy.Coverages.Claims",
          "Policy.Exclusions",
          "Policy.Beneficiaries",
          "Policy.Holder",
          "Policy.Product",
          "Policy.Accounts",
          "Policy.Accounts.Movements",
          "Payouts",
          "Payments",
          "InsuredEvent",
          "Events",
          "FraudAnalysis",
            "Requirements"
        ],
      }).then((r) => {
        this.setState({ loading: false, reloading: false });
        if (r.ok) {
          const d = r.outData[0];
          if (!d) {
            message.error(r.msg);
          } else {
            if (d.occurrence) d.occurrence = setDate(d.occurrence);
            if (d.notification) d.notification = setDate(d.notification);
            d.firstConsultation = setDate(d.firstConsultation);
            d.dateOfInjury = setDate(d.dateOfInjury);
            d.deliveryDate = setDate(d.deliveryDate);
            if (d.insuredEvent) {
              d.claimType = d.InsuredEvent.mode;
              this.setState({hasHealthProcedures:d.InsuredEvent.hasHealthProcedures||d.claimType==="HEALTH"}) //backward compatibility because it may not have hasHealthProcedures
            }
  
            this.props.form.setFieldsValue(d);
            
            //computing custom forms conditions
            const finalCustomForms = [];
            allCustomForms.forEach((p) => {
                if(p.condition){
                  const result= this.computeFormula(p.condition, d);
                  console.log("result",result);
                  if(result) finalCustomForms.push(p);
                }else finalCustomForms.push(p);
              })
            
            //computing condition disabled tabs
            const disabledTabs = [];
            //checking conditions defined in config profile
            const conditions=safeGet(["Claim", "disabledTabs"], profile, []);
            conditions.forEach((p) => {
              if(p.condition){
                const result= this.computeFormula(p.condition, d);
                if(result) disabledTabs.push(p.name);
              }else disabledTabs.push(p.name);
            });
            this.setState({ 
              claim: d,
              policy:d.policy, 
              procedures: d.Procedures, 
              config: JSON.parse(d.Policy.Product.configJson), 
              conditionDisabledTabs:disabledTabs,
              customForms: finalCustomForms,
              customFormsValues: d.jCustomForms });
          }
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  computeFormula(f, claim) {
    try {
      const context = {};
      context.pol = claim.Policy;
      context.cla = claim;

      f = f.replaceAll("_", "context.");
      console.log("Computing formula",f, context);
      return eval(f);
    } catch (error) {
      console.log("Unable to evaluate formula client side", error);
      return undefined;
    }
  }
  requirementsTableChange = (values) => {
    const unMet = values.filter((p) => p.type == 3 && !p.response).length;
    if (unMet > 0) {
      this.setState({
        reqBadge: unMet,
        blocked: true,
        blockedMsg: `${this.props.t("There are ")}${unMet} ${this.props.t("blocking requirements. Please check requirements tab. Action canceled.")}`,
      });
    } else {
      this.setState({ reqBadge: unMet, blocked: false, blockedMsg: null });
    }
  };
  reload() {
    this.load(this.state.claim.id);
  }
  onClaimClosure() {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      exe("DoClaimClosure", { claimId: values.id, ...values }, null, true).then((r) => {
        if (r.ok) {
          this.reload();
        }
      });
    });
  }
  onClaimReopen() {
    if (!this.state.reopenMotiveVisible) {
      this.setState({ reopenMotiveVisible: true });
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (err) return;
      exe("UndoClaimClosure", { claimId: values.id, ...values }, null, true).then((r) => {
        if (r.ok) {
          this.setState({ reopenMotiveVisible: false });
          this.reload();
        }
      });
    });
  }
  onUpdateClaim(payouts) {
    this.setState({ claim: { ...this.state.claim, Payouts: payouts } });
  }
  onPolicyChange(v) {
    if (!v) return;
    console.log("policy changed to:" + v);
    exe("RepoLifePolicy", { operation: "GET", filter: "id=" + v, include: ["Coverages","Coverages.Benefits","Coverages.Claims","Exclusions","Beneficiaries", "Insureds", "Insureds.Contact", "Holder","Product","Accounts","Accounts.Movements"] }).then((r) => {
      if (r.ok) {
        const policy = r.outData[0];
        let Claimer;
        let Contact;
        if(v!==this.state.claim.lifePolicyId){
        //if policy change
          Claimer=policy.Holder;
          Contact=policy.Principal
        }else{
          Claimer=this.state.claim.Claimer;
          Contact=this.state.claim.Contact;
        }
        this.setState({ policy: policy, 
          claim: { ...this.state.claim,
            Policy:policy,  
            Claimer: Claimer, 
            Contact: Contact }, 
          validEventReasons: (this.state.insuredEventsTable).filter((p) => (p[2].includes("ALL") || p[2].includes(policy.productCode))).map(p=>p[3]),
          config: JSON.parse(policy.Product.configJson) });
        
        this.props.form.setFieldsValue({
          lifePolicyId: policy.id,
          claimerId: Claimer.id,
          nameOfPatient: Contact.id,
        });
        this.onEventReasonChange(this.state.claim.eventReason);
      } else {
        message.error(r.msg);
      }
    });
  }
  onCollapseActivityPanel = () => {
    this.setState({ collapseActivityPanel: !this.state.collapseActivityPanel });
  };
  uploadProps = (claimId = this.state.claim.id) => ({
    name: "file",
    multiple: true,
    action: config.ssApiUrl + "/proxy/upload",
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} ${this.props.t("file uploaded successfully")}.`);

        const document = { claimId: claimId, name: "uploaded", filename: info.file.name, url: info.file.response.url };
        exe("RepoClaimDocument", { operation: "ADD", entity: document }).then((r) => {
          if (r.ok) message.success(r.msg);
          else message.error(r.msg);
        });
      } else if (status === "error") {
        message.error(`${info.file.name} ${this.props.t("file upload failed")}.`);
      }
    },
  });
  onEventReasonChange(v) {
    if (!this.state.policy) return;
    const filtered = (this.state.insuredEventsTable).filter((p) => (p[2].includes("ALL") || p[2].includes(this.state.policy.productCode)) && p[3] == v);
    console.log(filtered);
    this.setState({ validInsuredEvents: filtered.map((p) => p[4]).join(","), validCoverages: filtered });

  }
  onInsuredEventChange(v) {
    if (!v) return;
    if (this.state.validCoverages) {
      const elegibleCovs = this.state.validCoverages
        .filter((p) => p[4].indexOf(v.code) > -1)
        .map((p) => p[1])
        .join(",");
      console.log(v, elegibleCovs, "elegible covs");

      this.props.form.setFieldsValue({
        claimType: v ? v.mode : undefined,
        elegibleCoverages: elegibleCovs,
      });
      this.setState({hasHealthProcedures:v.hasHealthProcedures||v.mode==="HEALTH"});//backward compatibility because it may not have hasHealthProcedures
    }
  }
  onTabChange(tab) {
    // Check tab permissions before changing tab
    this.props.checkTabPermissions(`/claim/${tab}`,safeGet([,"policy","restricted"],this.state,false)).then(authorized => {
      if (!authorized) {
        message.error(this.props.t("Your user does not have permission to access this tab"));
        return;
      }
      this.setState({ activeKeyTab: tab });
  })
  }
  onOkMoneyOutRequest()  {
    this.setState({moneyOutRequest:false});
    this.onPolicyChange(this.state.policy.id);//refreshing account balance
  }
  onClone() {
    this.setState({ loading: true });
    exe("CloneClaim", { claimId: this.state.claim.id }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        //this.load(r.outData[0].id);
        window.location.hash = "#/healthclaim/" + r.outData[0].id;
      } else {
        message.error(r.msg);
      }
    });
  }
  onClaimerChange(newContact)  {
    this.setState({claim:{...this.state.claim,Claimer:newContact}});
  }
  onMapChange(v){
    this.props.form.setFieldsValue({jMap:JSON.stringify(v)});
  }
  render() {
    const { t, i18n } = this.props;
    const direction=this.context.direction;
    const evalInContext = (js, context) => {
      return function () {
        return eval(js);
      }.call(context);
    };
    const productRules=safeGet(["Main","claimFieldRules"],this.state.config,[]); 
  
    const getFieldDecorator=(id,options)=>{
      const newRules=[];
      if(productRules.length){
       const fieldProductRule=productRules.find(p=>p.field===id);
        if(fieldProductRule){
          fieldProductRule.rules.forEach(rule=>{
            Object.keys(rule).forEach(key=>{
              if(typeof rule[key]==='string'&&(rule[key]).startsWith("(")){ //needs computation
                newRules.push({[key]:evalInContext(rule[key],{claim:claim})})
              }else newRules.push({[key]:rule[key]});
            });
        })}
      }
      if(newRules.length) console.log("prules",id,newRules);
        return this.props.form.getFieldDecorator(id,newRules.length?{...options,rules:newRules}:options);
    } 
    const claim = this.props.form.getFieldsValue();
    const claimId = this.props.match.params.claimId; 
    const insuredFullName = this.state.claim.Contact && this.state.claim.Contact.FullName;
    const claimerFullName = this.state.claim.Claimer && this.state.claim.Claimer.FullName;

    const entityState = this.state.claim.Process ? this.state.claim.Process.entityState : "";
    const claimSalvageTabVisible = safeGet(["config", "Main", "claimSalvageTabVisible"], this.state, false);

    let disabledTabs = [];
    switch (entityState) {
      case "VALIDATION":
        disabledTabs = ["procedures", "reserves", "decision", "payment", "closure", "report", "timeline"];
        break;
      case "ADJUDICATION":
        disabledTabs = ["closure", "report", "timeline"];
        break;
      case "CLOSURE":
        disabledTabs = ["report", "timeline"];
        break;
      case "CLOSED":
        disabledTabs = ["report", "timeline"];
        break;

      default:
        disabledTabs = ["affectedCoverages", "procedures", "lifePolicy", "reserves", "decision", "payment", "closure", "files", "report", "timeline"];
        break;
    }
    disabledTabs.push(...this.state.conditionDisabledTabs);

    const isSubClaim=safeGet(["claim","masterClaimId"],this.state,false);
    const isMasterClaim=safeGet(["claim","isMasterClaim"],this.state,false);
    const disabledStageCodes=safeGet(["config","Claims","disabledStageCodes"],this.state,[]);
    return (
      <div style={{}}>
        <DefaultPage
          routes={{
            routes: [
              { breadcrumbName: t("Home"), path: "/" },
              { breadcrumbName: t("Claim List"), path: "claimlist" },
              { breadcrumbName: t("Claim"), path: "" },
            ],
          }}
          title={claimId == 0 ? t("New Claim") : <span>{`${t("Claim")} ${claimId}`}</span>}
          tags={<Status process={this.state.claim.Process} reload={() => this.reload()} blocked={this.state.blocked} blockedMsg={this.state.blockedMsg} />}
          icon="warning"
          iconStyle={isMasterClaim ? { backgroundColor: "#feca57" } : isSubClaim ? { backgroundColor: "#74b9ff" } : undefined}
          loading={this.state.loading}
          content={
            <div style={{ flex: 1 }}>
              <ClaimHeader claim={this.state.claim} />
            </div>
          }
          extra={
            <Row type="flex" justify="end" gutter={12}>
              <Col>
                <Tag>{entityState}</Tag>
              </Col>
              <Col>
                <ClaimStageSelect disabled={!claim.id} value={claim.stageCode} onChange={(v) => this.props.form.setFieldsValue({ stageCode: v })} claimId={claim.id} disabledStageCodes={disabledStageCodes}  />
              </Col>
              <Col>
                <Dropdown
                    onClick={() => null}
                    overlay={
                      <Menu onClick={() => null}>
                        <Menu.Item key="clone" onClick={() => this.onClone()} disabled={!claim.id}>
                          <CopyOutlined />
                          {t("Clone")}
                        </Menu.Item>
                      </Menu>
                    }>
                  <Button type="link">
                    {t("Actions")} <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  onClick={() => null}
                  overlay={
                    <Menu onClick={() => null}>
                      {this.state.processes.map((p) => (
                        <Menu.Item key="1">
                          <Link
                            to={{
                              pathname: "/activity/0",
                              proceso: p,
                              entity: "Claim",
                              entityId: this.state.claim.id,
                              entityPath: "healthClaim/" + this.state.claim.id,
                            }}>
                            <LegacyIcon type={p.icon || "rocket"} style={{ marginRight: 5 }} />
                            {p.id}.{p.name}
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }>
                  <Button type="link">
                    {t("Tasks")} <DownOutlined />
                  </Button>
                </Dropdown>
                <ButtonGroup>
                  <Button onClick={this.new} icon={<FileOutlined />}>
                    {t("New")}
                  </Button>
                  <Button onClick={this.save} icon={<SaveOutlined />}>
                    {t("Save")}
                  </Button>
                  <Button onClick={this.reset} icon={<UndoOutlined />}>
                    {t("Reset")}
                  </Button>
                  <Button onClick={() => this.reload()} icon={<ReloadOutlined />}>
                    {t("Reload")}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          }>
          <div style={{ position: "absolute", top: -15, zIndex: 9,...(direction=="rtl"?{left:0}:{right:0}) }}>
            <Tooltip title={this.state.collapseActivityPanel ? t("Expand Activity Panel") : t("Collapse Activity Panel")} placement={direction=="rtl"?"right":"left"}>
              <Button
                type="link"
                icon={<LegacyIcon type={this.state.collapseActivityPanel ? "left" : "right"} />}
                onClick={() => this.onCollapseActivityPanel()}
                className="collapseButton"></Button>
            </Tooltip>
          </div>
          <Row gutter={32}>
            <Col span={this.state.collapseActivityPanel ? 24 : 16}>
              <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Tabs type="card" defaultActiveKey="notification" tabBarExtraContent={{left:this.state.activeKeyTab=="notification"?null:<Button type="link" icon={<LeftOutlined style={{fontSize:14}}  />} onClick={() =>this.onTabChange("notification")}/>}}
                 activeKey={this.state.activeKeyTab} onTabClick={(tab) => this.onTabChange(tab)}>
                  {/* *********************NOTIFICATION************************ */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("notification")}
                    tab={
                      <span>
                        <SoundOutlined />
                        {t("Notification")}
                      </span>
                    }
                    key="notification">
                    <Tabs size="small">
                      <Tabs.TabPane
                        tab={
                          <span>
                            <UserOutlined />
                            {t("Main")}
                          </span>
                        }
                        key="01">
                        {/* ////////////////////////////////TOP MENU ////////////////////////////////////////////////////////////////// */}
                        <Title level={4}>{t("Main section")}</Title>
                        <Row gutter={16}>
                          {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                          <Col span={12}>
                            <div style={{ display: "none" }}>
                              <Form.Item label={t("id")}>{getFieldDecorator("id")(<Input />)}</Form.Item>
                              <Form.Item label={t("code")}>{getFieldDecorator("code")(<Input />)}</Form.Item>
                              {getFieldDecorator("masterClaimId")}
                              {getFieldDecorator("paymentMethodCode")}
                              {getFieldDecorator("organizationId")}
                              {getFieldDecorator("stageCode")}
                              {getFieldDecorator("externalId")}
                            </div>
                            <Form.Item label={t("Policy")}>
                              {getFieldDecorator("lifePolicyId", {
                                rules: [{ required: true }],
                              })(<PolicySelect onChange={(v) => this.onPolicyChange(v)}  />)}
                            </Form.Item>
                            <Form.Item label={t("Claimer")}>
                              {getFieldDecorator("claimerId", {
                                rules: [{ required: true }],
                              })(<ContactSelect display={claimerFullName} contactChange={(v)=>this.onClaimerChange(v.contact)} />)}
                            </Form.Item>
                            <Form.Item label={t("Insured Affected")} disabled={!this.state.policy}>
                              {getFieldDecorator("nameOfPatient", {
                                rules: [{ required: true }],
                              })(
                                <InsuredSelect
                                  insureds={safeGet(["policy", "Insureds"], this.state, [])}
                                  holder={safeGet(["policy", "Holder"], this.state, [])}
                                />
                              )}
                            </Form.Item>
                            <Form.Item label={t("Company name")}>
                              {getFieldDecorator("companyName", {
                                rules: [{}],
                              })(<Input />)}
                            </Form.Item>
                           
                      
                            <div style={{ display: "flex" }}>
                              <Form.Item label={t("Cash Call")}>
                                {getFieldDecorator("cashCall", {
                                  valuePropName: "checked",
                                })(<Switch />)}
                              </Form.Item>
                              <Form.Item label={t("Cooperation Clause")} style={{ marginLeft: 15,marginRight: 15 }}>
                                {getFieldDecorator("claimsCooperationClause", { valuePropName: "checked" })(<Switch />)}
                              </Form.Item>
                              <CheckFacultative policyId={safeGet(["policy","id"],this.state,0)} />
                            </div>
                            <div style={{display:claim.cashCall?"block":"none"}}>
                              <Form.Item label={t("Cash Call Received")}>
                                {getFieldDecorator("cashCallReceived", {
                                  valuePropName: "checked",
                                })(<Switch />)}
                              </Form.Item>
                            </div>
                    
                          </Col>
                          {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}>
                            <Form.Item label={t("Event Reason")}>
                              {getFieldDecorator("eventReason", {
                                rules: [{ required: true }],
                              })(<EventReason disabled={!claim.lifePolicyId} onChange={(v) => this.onEventReasonChange(v)}  validValues={this.state.validEventReasons} noValidation={this.state.insuredEventsTable.length===0} />)}
                            </Form.Item>
                            <Form.Item label={t("Insured Event")}>
                              {getFieldDecorator("InsuredEvent", {
                                rules: [{ required: true }],
                              })(<InsuredEventSelect disabled={!claim.lifePolicyId} onChange={(v) => this.onInsuredEventChange(v)} validValues={this.state.validInsuredEvents} noValidation={this.state.insuredEventsTable.length===0} />)}
                            </Form.Item>
                            {getFieldDecorator("elegibleCoverages")}
                            <Form.Item label={t("Claim Type")}>
                              {getFieldDecorator("claimType", {
                                rules: [{ required: true }],
                              })(<ClaimTypeSelect disabled />)}
                            </Form.Item>
                            <Form.Item label={t("Emergency Number")}>
                              {getFieldDecorator("contactNumber", {
                                rules: [{}],
                              })(<Input placeholder={t("Telephone or cellular number")} />)}
                            </Form.Item>
                            <Form.Item label={t("Reinsurance Event")}>{getFieldDecorator("reEventId", {})(<ReEventSelect />)}</Form.Item>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}>
                            <Form.Item label={t("Date of occurrence")}>
                              {getFieldDecorator("occurrence", { initialValue: setDate(), rules: [{ required: true }] })(
                                <DatePickerW style={{ width: "100%" }} showTime />
                              )}
                            </Form.Item>
                            <Form.Item label={t("Date of notification")}>
                              {getFieldDecorator("notification", { initialValue: setDate(), rules: [{ required: true }] })(
                                <DatePickerW style={{ width: "100%" }} showTime />
                              )}
                            </Form.Item>
                            <Form.Item label={t("Employee ID")}>
                              {getFieldDecorator("employeeId", {
                                rules: [{}],
                              })(<Input />)}
                            </Form.Item>

                            <Form.Item label={t("ID card num")}>
                              {getFieldDecorator("insuredId", {
                                rules: [{}],
                              })(<Input />)}
                            </Form.Item>
                            <Form.Item label={t("Created")}>{getFieldDecorator("created", { initialValue: setDate() })(<FormLabel type="Date" />)}</Form.Item>
                          </Col>
                        </Row>
                          <Row gutter={16}>
                              <Col span={24}>
                                  <Form.Item label={t("Description")}>
                                      {getFieldDecorator("description", {rules: [{}],
                                  })(<TextArea autoSize={{minRows:3}} placeholder={t("Free text description of the claim")} />)}
                                  </Form.Item>
                              </Col>
                          </Row>
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <MedicineBoxOutlined />
                            {t("Medical")}
                          </span>
                        }
                        key="02">
                        <Title level={4}>{t("Medical section")}</Title>
                        <Row gutter={16}>
                          {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                          <Col span={12}>
                            <Form.Item label={t("Name of provider")}>
                              {getFieldDecorator("nameOfDoctor", {
                                rules: [{}],
                              })(<ProviderSelect />)}
                            </Form.Item>
                            <Form.Item label={t("ICD Code")}>
                              {getFieldDecorator("icd", {
                                rules: [{ required: this.state.hasHealthProcedures }],
                              })(<DiagnosisSearch />)}
                            </Form.Item>

                            <Form.Item label={t("Nature of illness / disease with presenting complaints")}>
                              {getFieldDecorator("disease", {
                                rules: [{}],
                              })(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
                            </Form.Item>
                            <Form.Item label={t("Relevant clinical findings")}>
                              {getFieldDecorator("clinicalFindings", {
                                rules: [{}],
                              })(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
                            </Form.Item>
                            <Form.Item label={t("Provisional Diagnosis")}>
                              {getFieldDecorator("provisionalDiagnosis", {
                                rules: [{}],
                              })(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
                            </Form.Item>
                            <Form.Item label={t("Past history of present ailment, if any")}>
                              {getFieldDecorator("pastHistory", {
                                rules: [{}],
                              })(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
                            </Form.Item>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}>
                            <Form.Item label={t("Date of first consultation")}>
                              {getFieldDecorator("firstConsultation", {})(<DatePickerW style={{ width: "100%" }} />)}
                            </Form.Item>

                            <Form.Item label={t("Proposed line of treatment")}>
                              {getFieldDecorator("proposedLOT", {
                                rules: [{}],
                              })(
                                <Select style={{ width: "100%" }} placeholder={t("Please select...")}>
                                  <Option value="medical">{t("Medical Management")}</Option>
                                  <Option value="surgical">{t("Surgical Management")}</Option>
                                  <Option value="icu">{t("Intensive Care Unit")}</Option>
                                  <Option value="investigation">{t("Investigation")}</Option>
                                  <Option value="nat">{t("Non allopathic treatment")}</Option>
                                </Select>
                              )}
                            </Form.Item>
                            <Form.Item label={t("Date of injury")}>{getFieldDecorator("dateOfInjury", {})(<DatePickerW style={{ width: "100%" }} />)}</Form.Item>
                            <Form.Item label={t("ICD 10 PCS Code")}>
                              {getFieldDecorator("icd10pcs", {
                                rules: [{}],
                              })(<Input />)}
                            </Form.Item>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                              <Form.Item label={t("Accident")}>
                                {getFieldDecorator("isRTA", {
                                  rules: [{ type: "boolean", valuePropName: "checked" }],
                                })(<Switch />)}
                              </Form.Item>
                              <Form.Item label={t("In Network")}>
                                <Switch defaultChecked />
                              </Form.Item>
                            </div>

                            <Form.Item label={t("Duration of present ailment in days")}>
                              {getFieldDecorator("ailmentDuration", {
                                rules: [{ type: "number" }],
                              })(<InputNumber min={1} max={100} />)}
                            </Form.Item>
                            <Form.Item label={t("Doctor Contact Number")}>
                              {getFieldDecorator("drContactNumber", {
                                rules: [{}],
                              })(<Input placeholder={t("Telephone or cellular number")} />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <EuroOutlined />
                            {t("Preliminary Fees")}
                          </span>
                        }
                        key="04">
                        <Title level={4}>{t("Fees section")}</Title>
                        <Row gutter={16}>
                          {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}>
                            <Form.Item label={t("Consultation")}>
                              {getFieldDecorator("feeConsultation", {
                                rules: [{ type: "number" }],
                              })(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label={t("Treatment")}>
                              {getFieldDecorator("feeTreatment", {
                                rules: [{ type: "number" }],
                              })(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label={t("Surgery")}>
                              {getFieldDecorator("feeSurgery", {
                                rules: [{ type: "number" }],
                              })(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label={t("Other")}>
                              {getFieldDecorator("feeOther", {
                                rules: [{ type: "number" }],
                              })(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label={t("Total")}>
                              {getFieldDecorator("feeTotal", {
                                rules: [{ type: "number" }],
                              })(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label={t("Fee Instructions")}>
                              {getFieldDecorator("feeDescription", {
                                rules: [{}],
                              })(<Input.TextArea placeholder={t("Please insert fee related instructions if any")} autosize />)}
                            </Form.Item>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                          <Col span={12}>
                            <Form.Item>{getFieldDecorator("processId")(<Text />)}</Form.Item>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}></Col>
                        </Row>
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <ProfileOutlined />
                            {t("Scanned Request")}
                          </span>
                        }
                        key="0">
                        <Title level={4}>{t("Request files section")}</Title>

                        <Row gutter={16}>
                          {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                          <Col span={12}>
                            <Dragger {...this.uploadProps()}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                              <p className="ant-upload-hint">{t("Support for a single or bulk upload. UB04/CMS1500 Form supported")}</p>
                            </Dragger>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}></Col>
                          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                          <Col span={6}></Col>
                        </Row>
                      </Tabs.TabPane>
                      <Tabs.TabPane  tab={<span><CompassOutlined />{t("Map")}</span>} key="map" >
                        {getFieldDecorator("jMap")}
                        <MapRender onChange={v=>this.onMapChange(v)} value={claim.jMap} />
                      </Tabs.TabPane>
                    </Tabs>
                  </Tabs.TabPane>
                  {/* *********************REQUIREMENTS************************* */}
                  <Tabs.TabPane
                    forceRender
                    disabled={disabledTabs.includes("requirements")}
                    tab={
                      <span>
                        <CheckOutlined />
                        {t("Requirements")}
                        <Badge style={{ marginLeft: 5 }} count={this.state.reqBadge} />
                      </span>
                    }
                    key="requirements">
                    <RequirementsTable claimId={this.state.claim.id} onChange={this.requirementsTableChange} reload={this.state.reloading} />
                  </Tabs.TabPane>
                  {this.state.customForms.length > 0 && (
                    <Tabs.TabPane
                      forceRender
                      tab={
                        <span>
                          <FormOutlined />
                          {t("Custom")}
                        </span>
                      }
                      key="custom">
                      {this.state.customForms.length > 0 && (
                        <Tabs>
                          {this.state.customForms.map((p) => (
                            <Tabs.TabPane
                              forceRender
                              key={"customTab_" + p.name}
                              tab={
                                <span>
                                  <LegacyIcon type={p.icon} />
                                  {p.name}
                                </span>
                              }>
                              <CustomForm
                                formId={p.formId}
                                variable={"customClaimForm_" + p.name}
                                value={this.state.customFormsValues && JSON.parse(this.state.customFormsValues)[p.name]}
                              />
                            </Tabs.TabPane>
                          ))}
                        </Tabs>
                      )}
                    </Tabs.TabPane>
                  )}
                  {/* *********************PROCEDURES************************** */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("procedures") || !this.state.hasHealthProcedures}
                    tab={
                      <Tooltip title={t("Procedures (Health Claim)")}>
                        <MedicineBoxOutlined />
                        {t("Procedures")}
                      </Tooltip>
                    }
                    key="procedures">
                    <Title level={4}>{t("Procedure Table")}</Title>
                    <ProcedureTable claimId={this.state.claim.id} claim={this.state.claim} policy={this.state.claim.Policy} config={this.state.config} />

                    <Form.Item label={t("Created")}>{getFieldDecorator("created")(<Input disabled style={{ width: 200 }} />)}</Form.Item>
             
                  </Tabs.TabPane>

                  {/* *********************LIFE POLICY****************************** */}
                  <Tabs.TabPane
                    tab={
                      <Tooltip title={t("Policy")}>
                        <HeartOutlined />
                        {t("Policy")}
                      </Tooltip>
                    }
                    disabled={disabledTabs.includes("lifePolicy")}
                    key="lifePolicy">
                    <ClaimPolicyLife claim={this.state.claim} policy={this.state.claim.Policy} config={this.state.config} />
                  </Tabs.TabPane>
                  {/* *********************Affected Coverages****************************** */}
                  <Tabs.TabPane
                    tab={
                      <Tooltip title={t("Affected Coverages")}>
                        <OrderedListOutlined />
                        {t("Coverages")}
                      </Tooltip>
                    }
                    disabled={disabledTabs.includes("affectedCoverages")}
                    key="affectedCoverages">
                    <Title level={4}>{t("Affected Coverages")}</Title>
                    <AffectedCoverages claim={this.state.claim} policy={this.state.claim.Policy} config={this.state.config} />
                  </Tabs.TabPane>
                  {/* *********************RESERVES***************************** */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("reserves")}
                    tab={
                      <Tooltip title={t("Reserves")}>
                        <ScissorOutlined />
                        {t("Reserves")}
                      </Tooltip>
                    }
                    key="reserves">
                    <Title level={4}>{t("Reserves")}</Title>
                    <Payouts claim={this.state.claim} updateClaim={(p) => this.onUpdateClaim(p)} doRefresh={this.state.refreshPayouts} config={this.state.config}  />
                  </Tabs.TabPane>
                  {/* *********************DECISION****************************** */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("decision")}
                    tab={
                      <Tooltip title={t("Decision")}>
                        <CheckCircleOutlined />
                        {t("Decision")}
                      </Tooltip>
                    }
                    key="decision">
                    <Title level={4}>{t("Decision")}</Title>
                    <Row gutter={16}>
                      {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                      <Col span={14}>
                        <Form.Item label={t("Response")}>
                          {getFieldDecorator("approvalResponse")(
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={(v) => {
                                if (!["d", "r"].includes(v.target.value)) this.props.form.setFieldsValue({ approvalMotive: undefined });
                              }}>
                              <Radio.Button value="a">
                                <CheckOutlined style={{ marginRight: 5 }} />
                                {t("Approved")}
                              </Radio.Button>
                              <Radio.Button value="l">
                                <WarningOutlined style={{ marginRight: 5 }} />
                                {t("Limit Approved")}
                              </Radio.Button>
                              <Radio.Button value="d">
                                <StopOutlined style={{ marginRight: 5 }} />
                                {t("Declined")}
                              </Radio.Button>
                              <Radio.Button value="c">
                                <DeleteOutlined style={{ marginRight: 5 }} />
                                {t("Canceled")}
                              </Radio.Button>
                            </Radio.Group>
                          )}
                        </Form.Item>

                        {["d", "c"].includes(this.props.form.getFieldValue("approvalResponse")) && (
                          <Form.Item label="Motive">
                            {getFieldDecorator("approvalMotive", {
                              rules: [{}],
                            })(
                              <Select style={{ width: "100%" }} placeholder={t("Plase select...")}>
                                <Option value="INC">{t("Incorrect Patient Identifier Information")}</Option>
                                <Option value="COT">{t("Coverage Terminated")}</Option>
                                <Option value="RPA">{t("Requires Prior Authorization or Precertification")}</Option>
                                <Option value="SE">{t("Services Excluded or Non-covered")}</Option>
                                <Option value="RMR">{t("Request for Medical Records")}</Option>
                                <Option value="COB">{t("Coordination of Benefits")}</Option>
                                <Option value="BLC">{t("Bill Liability Carrier")}</Option>
                                <Option value="MIC">{t("Missing or Invalid CPT or HCPCS Codes")}</Option>
                                <Option value="TF">{t("Timely Filing")}</Option>
                                <Option value="NRF">{t("No Referral on File")}</Option>
                              </Select>
                            )}
                          </Form.Item>
                        )}
                        <Form.Item label={t("Comments")}>
                          {getFieldDecorator("approvalComments", {
                            rules: [{}],
                          })(<TextArea />)}
                        </Form.Item>

                        {/* <Button icon="mail" onClick={() => window.open(URLSIS10, "_blank")}>
                          Send to claimer
                        </Button> */}
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  {/* *********************PAYMENT****************************** */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("payment")}
                    tab={
                      <Tooltip title={t("Payment")}>
                        <DollarOutlined />
                        {t("Payment")}
                      </Tooltip>
                    }
                    key="payment">
                    <Title level={4}>{t("Payment Liquidation")}</Title>
                    <ClaimPayment claim={this.state.claim} reload={() => {this.reload();this.setState({refreshPayouts:this.state.refreshPayouts+1})}} config={this.state.config} />
                  </Tabs.TabPane>
                  {/* *********************Accounts****************************** */}
                  {this.state.policy&&<Tabs.TabPane
                      disabled={disabledTabs.includes("accounts")}
                      tab={
                        <Tooltip title={t("Accounts")}>
                          <GlobalOutlined />
                          {t("Accounts")}
                        </Tooltip>
                      }
                      key="accounts">
                    <Title level={4}>{t("Policy Accounts")}</Title>
                    <Button type="link" icon={<LegacyIcon type={"upload"} />} onClick={()=>this.setState({moneyOutRequest:true})}>{t("Money out request")}</Button>
                    <Accounts value={this.state.policy.Accounts} />
                    <PaymentRequest
                        visible={this.state.moneyOutRequest}
                        onCancel={() => this.setState({moneyOutRequest:false})}
                        policyId={this.state.policy.id}
                        currency={this.state.policy.currency}
                        holderId={this.state.policy.holderId}
                        Accounts={this.state.policy.Accounts}
                        claimId={this.state.claim.id}
                        onOk={() => this.onOkMoneyOutRequest()}
                    />
                  </Tabs.TabPane>}
                  {/* *********************SALVAGE****************************** */}
                  {claimSalvageTabVisible && (
                    <Tabs.TabPane
                      disabled={disabledTabs.includes("salvage")}
                      tab={
                        <Tooltip title={t("Salvage")}>
                          <ToolOutlined />
                          {t("Salvage")}
                        </Tooltip>
                      }
                      key="salvage">
                      <Title level={4}>{t("Salvage Procedures")}</Title>
                      <ClaimSalvage claim={this.state.claim} config={this.state.config} />
                    </Tabs.TabPane>
                  )}
                  {/* *********************CLOSURE****************************** */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("closure")}
                    tab={
                      <Tooltip title={t("Closure")}>
                        <PoweroffOutlined />
                        {t("Closure")}
                      </Tooltip>
                    }
                    key="closure">
                    <Title level={4}>{t("Closure")}</Title>
                    <Row gutter={16}>
                      {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                      <Col span={24}>
                        <div style={this.state.claim.closed ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                          <Form.Item label={t("Closure Type")}>{getFieldDecorator("closureType")(<ClosureTypeSelect />)}</Form.Item>
                          <Form.Item label={t("Comments")}>
                            {getFieldDecorator("closureComments", {
                              rules: [{}],
                            })(<TextArea />)}
                          </Form.Item>
                          <Button type="danger" icon={<PoweroffOutlined />} onClick={() => this.onClaimClosure()}>
                            {t("Close")}
                          </Button>
                        </div>
                        {this.state.reopenMotiveVisible && (
                          <div>
                            <Form.Item label={t("Motive")}>
                              {getFieldDecorator("closureMotive", {
                                rules: [{}],
                              })(
                               <ClosureUndoMotiveSelect lob={safeGet(["policy","lob"],this.state,null)} />
                              )}
                            </Form.Item>
                          </div>
                        )}
                        {this.state.claim.closed && (
                          <Button style={{ marginTop: 5 }} icon={<RedoOutlined />} onClick={() => this.onClaimReopen()}>
                            {t("Reopen")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  {/* *********************FILES******************************* */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("files")}
                    tab={
                      <Tooltip title={t("Files")}>
                        <FilePdfOutlined />
                        {t("Files")}
                      </Tooltip>
                    }
                    key="files">
                    <Alert
                      message={t("Informational Notes")}
                      description={t("Files sent to approvals@axxis-systems.com with the request ID as subject will automatically be attached here")}
                      type="info"
                      showIcon
                      style={{ marginBottom: 10 }}
                    />
                    <Title level={4}>{t("Attached files section")}</Title>

                    <ClaimDocs claimId={claim.id} config={this.state.config} requirements={safeGet(["claim","Requirements"],this.state,[])} reload={()=>this.reload()} />
                  </Tabs.TabPane>
                  {/* *********************REPORT****************************** */}
                  <Tabs.TabPane 
                    tab={
                      <Tooltip title={t("Report")}>
                        <ProfileOutlined />
                        {t("Report")}
                      </Tooltip>
                    }
                    disabled={!this.state.hasHealthProcedures || disabledTabs.includes("report")}
                    key="report">
                    <Report claimId={claimId} />
                  </Tabs.TabPane>
                  {/* *********************TIMELINE**************************** */}
                  <Tabs.TabPane
                    disabled={disabledTabs.includes("timeline")}
                    tab={
                      <Tooltip title={t("Timeline")}>
                        <HistoryOutlined />
                        {t("Timeline")}
                      </Tooltip>
                    }
                    key="timeline">
                    <Title level={4}>{t("Public Event Notification Timeline")}</Title>
                    <ClaimTimeline data={this.state.claim.Process && this.state.claim.Process.Pasos} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////STEP Fraud Analysis////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    key="fraudAnalaysis"
                    tab={
                      <Tooltip title={t("Fraud Analysis")}>
                        <Badge
                          style={{ height: 15, lineHeight: "15px" }}
                          offset={[4, 0]}
                          count={safeValue("FraudAnalysis", claim, []).filter((p) => p.factor > 0).length}>
                          <DashboardOutlined />
                          {t("Fraud Analysis")}
                        </Badge>
                      </Tooltip>
                    }>
                    <Title level={4}>{t("Fraud Analysis & Flags")}</Title>
                    {getFieldDecorator("FraudAnalysis")(<FraudAnalysis claimId={claim.id} />)}
                  </Tabs.TabPane>
                  {/* ////////////////////////////////STEP tasks////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    key="tasks"
                    tab={
                      <Tooltip title={t("Claim Tasks")}>
                        <PushpinOutlined />
                        {t("Tasks")}
                      </Tooltip>
                    }>
                    <EntityTasks entity="Claim" entityId={this.state.claim.id} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////STEP Relationships ////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    key="relationships"
                    tab={
                      <Tooltip title={t("Related Claims")}>
                        <SwapOutlined />
                        {t("Related")}
                      </Tooltip>
                    }>
                    {getFieldDecorator("jRelated")}
                    <Title level={4}>{t("Related Claims")}</Title>
                    <RelatedClaims claimId={claim.id} jRelated={claim.jRelated} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////STEP Audit ////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                      key="audits"
                      tab={
                        <Tooltip title={t("Audit Records")}>
                          <AuditOutlined />
                          {t("Audit")}
                        </Tooltip>
                      }>
                    <Title level={4}>{t("Audit Records")}</Title>
                    <Audits claimId={claim.id}  />
                  </Tabs.TabPane>
                  {/* *********************Sub-Claims************************** */}
                  <Tabs.TabPane  disabled={disabledTabs.includes("subClaims") || this.state.claim.masterClaimId}
                                 tab={
                                   <Tooltip title={t("Sub-Claims")}>
                                     <BranchesOutlined />
                                      {t("Sub-Claims")}
                                   </Tooltip>
                                 }
                                 key="subClaims">
                    <Title level={4}>{t("Sub-Claims")}</Title>
                    <SubClaims claimId={this.state.claim.id} currency={safeGet(["claim","Policy","currency"],this.state,"USD")} 
                    insuredEventsTable={this.state.insuredEventsTable}
                    validEventReasons={this.state.validEventReasons}
                    productCode={safeGet(["policy","productCode"],this.state,"")} />
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            </Col>
            <Col span={this.state.collapseActivityPanel ? 0 : 8}>
              <Tabs>
                <Tabs.TabPane
                  key="panel1"
                  tab={
                    <span>
                      <CoffeeOutlined />
                      {t("Activity")}
                    </span>
                  }>
                  {getFieldDecorator("Events")(<Events />)}
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="panel2"
                  tab={
                    <span>
                      <BlockOutlined />
                      {t("Related")}
                    </span>
                  }>
                  <Related policyId={claim.lifePolicyId} />
                </Tabs.TabPane>
                <Tabs.TabPane
                  forceRender
                  key="panel3"
                  tab={
                    <span>
                      <MessageOutlined />
                      {t("Comments")} <Badge count={this.state.numberOfComments}></Badge>
                    </span>
                  }>
                  <Comments policyId={claim.lifePolicyId} numberOfComments={(q) => this.setState({ numberOfComments: q })} />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </DefaultPage>
      </div>
    );
  }
}

export default withTranslation()(Form.create()(HealthClaim));
