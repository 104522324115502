import '@ant-design/compatible/assets/index.css';
import { Button, Collapse, message } from "antd";
import { EnterOutlined } from "@ant-design/icons";
import React from "react";
import CustomForm from '../Shared/CustomForm';
import { useTranslation } from 'react-i18next';


const CoverageTechEdition = (props) => {
    const [t]=useTranslation();

  const onChangeCoverage = (coverage, index) => {
    //getting the new value from the form
     const covConfig = getConfig(coverage.code);
     if (covConfig.customFormId && window["customCoverageForm"+"-"+coverage.code+"-NEW"] && window["customCoverageForm"+"-"+coverage.code+"-NEW"].instanceContainers[0]) {
       if (window["customCoverageForm"+"-"+coverage.code+"-NEW"].instanceContainers[0].reportValidity()) {
           coverage.jCustom = JSON.stringify(window["customCoverageForm"+"-"+coverage.code+"-NEW"].userData);
           message.success(t("Changes submitted successfully"));
       } else {
           message.error(t("Please check custom form validation errors"));
           return;
       }
   }
    const newCovs = [...coverages];
    newCovs[index] = coverage;
    props.onChange(JSON.stringify(newCovs));
  };
  const coverages = JSON.parse(props.value || []);
  const getConfig = (code) => props.config.Coverages.find((p) => p.code == code);

  return (
    <div>
      <Collapse>
        {coverages.map((coverage, index) => (
          <Collapse.Panel header={`${coverage.code}-${coverage.name}`} key={coverage.code}>
            <Button type='link' icon={<EnterOutlined />} onClick={() => onChangeCoverage(coverage,index)}>{t("Submit Changes")}</Button>
            <CustomForm value={coverage.jCustom} 
            disabled={props.disabled || getConfig(coverage.code).restrictUserEdition}
            formId={getConfig(coverage.code)?.customFormId} variable={"customCoverageForm"+"-"+coverage.code+"-"+(props.new?"NEW":"OLD")}/>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default CoverageTechEdition;
