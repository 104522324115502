import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import InsuredObjects from '../Property/InsuredObjects';
import { safeGet } from '../../Lib/Dal';


const AmendmentInsuredObject = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) {
        props.form.setFieldsValue(props.value);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const insuredObjectsTypes = safeGet(["Objects", "types"], props.config, []);
  const maxInsuredObjects = safeGet(["Objects", "max"], props.config, 0);
  const readOnly = false;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={t("Previous Insured Objects")}>
                {field(
                  "jOldInsuredObjects",
                  {}
                )(<InsuredObjects
                    change
                    newChange={true}
                    policyId={props.policy.id}
                    active={props.policy.active}
                    objectTypes={insuredObjectsTypes}
                    max={maxInsuredObjects}
                    dynamicFormId="oldInsuredObjects"
                  />
                )}
              </Form.Item>
              <Form.Item label={t("New Insured Objects")}>
                {field(
                  "jNewInsuredObjects",
                  {}
                )(<InsuredObjects
                    change
                    newChange={props.new}
                    policyId={props.policy.id}
                    active={readOnly}
                    objectTypes={insuredObjectsTypes}
                    max={maxInsuredObjects}
                    dynamicFormId="newInsuredObjects"
                  />
                )}
              </Form.Item>
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentInsuredObject);
