import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import Exclusions from "./Exclusions";

const AmendmentExclusion = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);
  const currentExclusions = props.currentExclusions || [];
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Section text={t("Additional Amendment Options")}>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
            <Form.Item label={t("Old Exclusions")}>{field("oldExclusions", { initialValue: currentExclusions })(<Exclusions readOnly />)}</Form.Item>
            <Form.Item label={t("New Exclusions")}>
              {field("newExclusions", { initialValue: currentExclusions, rules: [{ required: true }] })(
                <Exclusions config={props.config} coverages={props.policy.Coverages} allowRemove />
              )}
            </Form.Item>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
          </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentExclusion);
