import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import SimpleContactSelect from '../Contact/SimpleContactSelect';
import { safeGetRaw } from '../../Lib/Dal';
import CustomForm from '../Shared/CustomForm';


const AmendmentCessionBeneficiary = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) {
        props.form.setFieldsValue(props.value);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const cessionBeneficiaryFormId = safeGetRaw([ "Main", "cessionBeneficiaryFormId"], props.config, null);
  const values = props.form.getFieldsValue();
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
                 {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={t("Current Cession Beneficiary Value")}>
                {field("oldCessionBeneficiary", { initialValue: props.policy.cessionBeneficiary })(<SimpleContactSelect disabled filter="isPerson=0 AND " />)}
              </Form.Item>
              {field("oldJCessionBeneficiary")}
              {cessionBeneficiaryFormId&&<CustomForm disabled formId={cessionBeneficiaryFormId} variable="customCessionBeneficiaryFormOld" value={props.policy.jCessionBeneficiary} />}
              <Form.Item label={t("New Cession Beneficiary Value")} >
                {field("newCessionBeneficiary")(<SimpleContactSelect filter="isPerson=0 AND " />)}
              </Form.Item>
              {field("newJCessionBeneficiary")}
              {cessionBeneficiaryFormId&&<CustomForm formId={cessionBeneficiaryFormId} variable="customCessionBeneficiaryFormNew" value={values.newJCessionBeneficiary||props.policy.jCessionBeneficiary} />}
             
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentCessionBeneficiary);
