import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
} from "antd";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import ProductSelect from "../Life/ProductSelect";
import Currency from "../Shared/Currency";
import ClaimSelect from "../Shared/ClaimSelect";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import AccountSelect from "../Shared/AccountSelect";
import useUpdateEffect from "../../Lib/Helpers";
import PaymentMethods from "../Life/PaymentMethods";
import OriginSelect from "./OriginSelect";
import YesNoSelect from "../Shared/YesNoSelect";
import DatePickerW from "../Shared/DatePickerW";

const PaymentFilter = (props) => {
  const [t] = useTranslation();
  const [matches, setMatches] = useState(null);
  const [loading,setLoading]=useState(false);

  useEffect(() => {
    if (props.filter) {
      props.form.setFieldsValue(props.filter);
      submit();
    } else {
      if (window.location.hash == "#/payments") submit();
    }
  }, [props.filter]);

  useEffect(() => {
    if (props.reload && props.reload > 0) submit();
  }, [props.reload]);

  useUpdateEffect(() => {
    if (!isNaN(props.currentPage)) submit(true);
  }, [props.currentPage]);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      let queries = [];
      if (values.date)
        queries.push(`YEAR(date)=${values.date.format("YYYY")} AND MONTH(date)=${values.date.format("M")} AND DAY(date)=${values.date.format("D")}`);
      if (values.month) queries.push(`YEAR(date)=${values.month.format("YYYY")} AND MONTH(date)=${values.month.format("M")}`);
      if (values.total !== undefined) queries.push(`total${values.total}`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);
      if (values.claimId !== undefined) queries.push(`claimId=${values.claimId}`);
      if (values.id !== undefined && values.id > 0) queries.push(`id=${values.id}`);
      if (values.batchId) queries.push(`batchId=${values.batchId}`);

      if (values.sourceAccountId) queries.push(`sourceAccountId=${values.sourceAccountId}`);
      if (values.destinationAccountId) queries.push(`accountId=${values.destinationAccountId}`);
      if (values.reference) queries.push(`reference LIKE N'%${values.reference}%'`);
      if (values.concept) queries.push(`concept LIKE N'%${values.concept}%'`);
      if (values.branch) queries.push(`branch='${values.concept}'`);
      if (values.contactId) queries.push(`contactId=${values.contactId}`);
      if (values.entityState) queries.push(`entityState='${values.entityState}'`);
      if (values.executed!==undefined) {
        if(values.executed) queries.push(`entityState='EXECUTED'`);
        else queries.push(`entityState!='EXECUTED'`);
      }
      if (values.paymentMethodCode) queries.push(`paymentMethodCode='${values.paymentMethodCode}'`);
      if (values.dueRange&&values.dueRange.length>1) {
        queries.push(`id in (SELECT entityId FROM Proceso WHERE entity='ClaimPayment' AND dueDate between '${values.dueRange[0].format("YYYY-MM-DD")}' and  '${values.dueRange[1].format("YYYY-MM-DD")}')`);
      }
      if (values.releaseRange&&values.releaseRange.length>1) {
        queries.push(`releaseDate between '${values.releaseRange[0].format("YYYY-MM-DD")}' and '${values.releaseRange[1].format("YYYY-MM-DD")}'`);
      }
      if(values.origin){
        //translating origin to producer
        switch (values.origin) {
            case "CLA":
                queries.push(`claimId is not null`);
                break;
            case "POL":
                queries.push(`lifePolicyId is not null and producer is null and claimId is null`);
                break;
                default:
                    queries.push(`producer='${values.origin}'`);
                
        }
      }
      if(values.lob){
        if(values.origin==="CLA"){
          queries.push(`claimId in (SELECT id FROM Claim WHERE lifePolicyId in (SELECT id from LifePolicy where lob='${values.lob}'))`);
        }else if(values.origin==="POL"){
          queries.push(`lifePolicyId in (SELECT id from LifePolicy where lob='${values.lob}')`);
        }
      }

      const filterQuery = queries.join(" AND ");
      setLoading(true);
      exe("RepoClaimPayment", {
        operation: "GET",
        custom: true,
        filter: filterQuery,
        include: ["Process","Taxes","CostCenters","CostCenters.CostCenter"],
        size: values.all?0:15,
        page: props.currentPage ? props.currentPage - 1 : 0,
      }).then((r) => {
        setLoading(false);
        props.onResults(r.outData);
        setMatches(r.total);
        props.onTotal && props.onTotal(r.total);
      });
    });
  };
  const values=props.form.getFieldsValue();
  return (
    <div>
      <Drawer title={t("Payments Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <div style={{ display: "flex" }}>
          <Form.Item label={t("Id")} style={{ width:"100%" }}>{getFieldDecorator("id")(<InputNumber style={{width:"100%"}}  />)}</Form.Item>
          <Form.Item label={t("Batch Id")} style={{ marginLeft: 5,width:"100%" }}>{getFieldDecorator("batchId")(<InputNumber style={{width:"100%"}}  />)}</Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Created on Day")} style={{width:"100%"}}>{getFieldDecorator("date")(<DatePickerW style={{width:"100%"}}  />)}</Form.Item>
            <Form.Item label={t("Created on Month")} style={{ marginLeft: 5,width:"100%" }}>
              {getFieldDecorator("month")(<DatePicker.MonthPicker style={{width:"100%"}}  />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Due Date Between")}>{getFieldDecorator("dueRange")(<DatePicker.RangePicker style={{width:"100%"}}  />)}</Form.Item>
          <Form.Item label={t("Release Date Between")}>{getFieldDecorator("releaseRange")(<DatePicker.RangePicker style={{width:"100%"}}  />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Claim")} style={{ width: "100%" }}>
              {getFieldDecorator("claimId")(<ClaimSelect />)}
            </Form.Item>
            <Form.Item label={t("Currency")} style={{ marginLeft: 5, width: "100%" }}>
              {getFieldDecorator("currency")(<Currency />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Amount")}>{getFieldDecorator("total")(<Compare />)}</Form.Item>
          <Form.Item label={t("Origin")}>{getFieldDecorator("origin")(<OriginSelect />)}</Form.Item>
          <div style={{ display: (values.origin==="POL"||values.origin==="CLA")?"block":"none" }}>
            <Form.Item label={t("LoB")}>{getFieldDecorator("lob")(<LobSelect />)}</Form.Item>
          </div>
          <Form.Item label={t("Source Account")}>{getFieldDecorator("sourceAccountId")(<AccountSelect />)}</Form.Item>
          <Form.Item label={t("Destination Account")}>{getFieldDecorator("destinationAccountId")(<AccountSelect />)}</Form.Item>
          <div style={{display:"flex"}}>
            <Form.Item label={t("Reference")} style={{ width: "100%" }}>{getFieldDecorator("reference")(<Input />)}</Form.Item>
            <Form.Item label={t("Concept")} style={{ marginLeft: 5,width: "100%" }}>{getFieldDecorator("concept")(<Input />)}</Form.Item>
          </div>
          <div style={{display:"flex"}}>
          <Form.Item label={t("Branch")} style={{width:"100%"}}>{getFieldDecorator("branch")(<Input />)}</Form.Item>
          <Form.Item label={t("Executed")} style={{ marginLeft: 5,width: "100%" }}>{getFieldDecorator("executed")(<YesNoSelect />)}</Form.Item>
          </div>
          <Form.Item label={t("Beneficiary")}>{getFieldDecorator("contactId")(<SimpleContactSelect />)}</Form.Item>
          <div style={{display:"flex"}}>
            <Form.Item label={t("Status")} style={{ width: "100%" }}>{getFieldDecorator("entityState")(<Input />)}</Form.Item>
            <Form.Item label={t("Method of Payment")} style={{ marginLeft: 5,width: "100%" }}>{getFieldDecorator("paymentMethodCode")(<PaymentMethods module="PAYMENT" style={{ width: "100%" }} />)}</Form.Item>
          </div>
          <Form.Item label={t("Retrieve All Pages for Download")}>{getFieldDecorator("all", { valuePropName: "checked"})(<Checkbox />)}</Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()} loading={loading}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(PaymentFilter);
