import { exe } from "./Dal";

const getCache = (item) => {
  if (!window.Cache) window.Cache = {};
  return new Promise((resolve) => {
    const cache = window.Cache;
    if (cache[item]) {
      resolve({ ok: true, outData: cache[item], cache: true });
    } else {
      if (!cache["fetching_" + item]) {
        cache["fetching_" + item] = true;
        exe(item, { operation: "GET" })
          .then((r) => {
            if (r.ok) {
              cache[item] = r.outData;
            }
            cache["fetching_" + item] = false;
            resolve(r.ok ? { ok: true, outData: r.outData } : { ok: false, msg: r.msg });
          })
          .catch((err) => {
            cache["fetching_" + item] = false;
            resolve({ ok: false, msg: `Error fetching data: ${err.message}` });
          });
      } else {
        (async () => {
          console.log("waiting for cache");
          let retries = 0;
          const maxRetries = 20; // Example: 20 retries * 500ms = 10 seconds
          while (cache["fetching_" + item] && retries < maxRetries) {
            await new Promise((r) => setTimeout(r, 500));
            retries++;
          }
          if (retries >= maxRetries) {
            resolve({ ok: false, msg: "Timeout waiting for cache to populate" });
          } else {
            console.log("cache is defined");
            resolve({ ok: true, outData: cache[item], cache: true });
          }
        })();
      }
    }
  });
};

export default getCache;