import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select, Tag} from "antd";
import { useTranslation } from 'react-i18next';

const ClaimStageSelect = (props) => {
    const [t] = useTranslation();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        load();
    },[])

    const setStageCode=(code)=>{
        if(props.noDb){//noDb mode is used in the filter component and should behave like a normal field
            props.onChange(code)
            return;
        }
        setLoading(true);
        exe("SetClaimStage",{claimId:props.claimId,stageCode:code}).then(r=>{
            setLoading(false);
            if(r.ok){
                props.onChange(code)
                message.success(r.msg)
            }else message.error(r.msg)
        }
        )
    }
    
    const load=()=>{
        setLoading(true);
        exe("RepoClaimStage",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                const myData=r.outData;
                //if noDb mode is enabled, we need to add the no state value
                if(props.noDb){
                    myData.unshift({code:"-1",name:t("No Stage")})
                }
                setData(myData)

            }else message.error(r.msg)
        })
    }
    const selectedOption = data.find(option => option.code == props.value);
    const disabledStageCodes = props.disabledStageCodes || [];

    return (
        <div>
             {selectedOption && (
                <Tag
                    color={selectedOption.color||"gold"}
                    closable
                    onClose={() => props.onChange(undefined)}
                    style={{ marginBottom: 5 }}
                >
                {selectedOption.name}
                </Tag>
            )}
          {!selectedOption&&<Select value={props.value} onChange={setStageCode} style={props.style}
          disabled={props.disabled} loading={loading} bordered={!!props.noDb} placeholder={<span style={{color:props.noDb?"unset":"#1890ff"}}>{t("Claim Stage")}</span>}>
              {data.map(p=><Select.Option value={p.code} key={p.code} disabled={p.disabled||disabledStageCodes.includes(p.code)}>{p.name}</Select.Option> )}
          </Select>}  
        </div>
    );
};

export default ClaimStageSelect;