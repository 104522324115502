import { DeleteOutlined, DollarOutlined, EyeOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Popconfirm, Table, Tag, Tooltip } from "antd";
import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import {formatDate, formatDateShort, formatMoney, getColor} from "../../Lib/Helpers";
import Status from "../Shared/Status";
import LiquidationDetail from './LiquidationDetail';

const LiquidationRecord = (props) => {
  const [detailSelected, setDetailSelected] = useState(); 
  const [t, i18n] = useTranslation();

  const getStatusText = (v) => {
    if (v == 0) {
      return <Tag>{t("Draft")}</Tag>;
    } else if (v == 1) {
      return <Tag color="green">{t("Executed")}</Tag>;
    }else if (v == 2) {
        return <Tag color="red">{t("Reverted")}</Tag>;} 
    else {
      return <Tag>{v}</Tag>;
    }
  };
  return (
    <div>
      <div style={{ display: detailSelected ? "none" : "block" }}>
      <Table dataSource={props.value} loading={props.loading} scroll={{ x: true }}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Name")} dataIndex="liquidationName" />
        <Table.Column title={t("From")} dataIndex="from" render={(v) => formatDateShort(v)} />
        <Table.Column title={t("To")} dataIndex="to" render={(v) => formatDateShort(v)} />
        <Table.Column title={t("Contract")} dataIndex="contractId" />
        <Table.Column title={t("Participant")} dataIndex="participantId" render={(v, r) => <a href={"#/contact/" + v}>{r.participantName}</a>} />
        <Table.Column title={t("Premium Ceded")} dataIndex="premiums" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Loss Ceded")} dataIndex="claims" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Commission")} dataIndex="commissions" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Tax")} dataIndex="tax" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Reserve")} dataIndex="reserve" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Premium Resv Int")} dataIndex="premiumReserveInterest" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Claim Resv Int")} dataIndex="claimReserveInterest" render={(v, r) => formatMoney(v, r.currency)} />

        <Table.Column title={t("Reinstatement")} dataIndex="reinstatementPremiums" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Balance")} key="balance" render={(v, r) => <b style={{whiteSpace:"nowrap"}}>{formatMoney(r.premiums-r.claims-r.commissions-r.tax-r.reserve+r.reinstatementPremiums+r.premiumReserveInterest+r.claimReserveInterest, r.currency)}</b>} />
        <Table.Column title={t("Created")} dataIndex="creation" render={(v) => formatDate(v)} />
        <Table.Column title={t("Operation Id")} dataIndex="operationId" />
        <Table.Column title={t("Status")} dataIndex="status" render={(v) => getStatusText(v)} />
          <Table.Column
          dataIndex="Process"
          title={t("Workflow")}
          render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => props.reload&&props.reload()} />}
      />

          <Table.Column
          title={t("Actions")}
          key="actions"
          fixed="right"
          render={(v, r) => (
            <div style={{display:"flex",whiteSpace:"nowrap"}}>
              <div style={{ display: r.id && (r.status === 0||r.status===2) ? "block" : "none",whiteSpace:"nowrap" }}>
                <Popconfirm title={t("Are you sure you want to remove this entry?")} onConfirm={() => props.onDelete && props.onDelete(r)}>
                  <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm
                  title={t("Are you sure you want to execute this liquidation?")}
                  onConfirm={() => props.onExecuteLiquidation && props.onExecuteLiquidation(r)}>
                  <Button type="link" icon={<DollarOutlined />} />
                </Popconfirm>
                <Divider type="vertical" />
              </div>
              <Tooltip title={t("Detail")}>
                <Button type="link" icon={<EyeOutlined />} onClick={()=>setDetailSelected(r)} />
              </Tooltip>
            </div>
          )}
        />
      </Table>
      </div>
      <div style={{ display: detailSelected ? "block" : "none" }}>
        <Button type='link' style={{paddingLeft:0,paddingRight:0}} icon={<LeftOutlined />} onClick={() => setDetailSelected(null)}>{t("Back")}</Button>
        <LiquidationDetail liquidationRecord={detailSelected} />
      </div>
    </div>
  );
};

export default LiquidationRecord;
