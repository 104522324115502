import React, {Component, useEffect} from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Input,
  Table,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Switch,
} from "antd";
import { exe } from "Lib/Dal";
import HoldingSelect from "../Contact/HoldingSelect";
import RoleSelect from "../Contact/RoleSelect";
import {useTranslation, withTranslation} from "react-i18next";
import moment from "moment";
import WorkflowStateSelect from "../Shared/WorkflowStateSelect";
import StatusSelect from "../Shared/StatusSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const Filter=props=>{
  const [t]=useTranslation();

const  submit=()=> {
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let queries = [];
        //if (values.name) queries.push(`(name like N'%${values.name}%' or surname1 like N'%${values.name}%' or surname2 like N'%${values.name}%')`);
        if (values.name) queries.push(`(RTRIM(ISNULL([name],''))+' '+RTRIM(ISNULL(surname1,''))+' '+RTRIM(ISNULL(surname2,''))) like N'%${values.name}%'`);
        
        if (values.passport !== undefined) queries.push(`passport='${values.passport}'`);
        if (values.workflowState){
            queries.push(`processId in (SELECT id from Proceso WHERE estado='${values.workflowState}')`);
        }
        if (values.cnp !== undefined) queries.push(`cnp='${values.cnp}'`);
        if (values.nif !== undefined) queries.push(`nif='${values.nif}'`);
        if (values.holdingId !== undefined) queries.push(`holdingId=${values.holdingId}`);
        if (values.id !== undefined) queries.push(`id=${values.id}`);
        if (values.isPerson !== undefined) queries.push(`isPerson='${values.isPerson}'`);
        if (values.updated !== undefined && values.updated.length == 2) {
          queries.push(`updated BETWEEN '${values.updated[0].format("YYYY-MM-DD")}' AND '${values.updated[1].format("YYYY-MM-DD")}' `);
        }
        if (values.birth !== undefined) queries.push(`birth='${values.birth.format("YYYY-MM-DD")}'`);
        if (values.active !== undefined) queries.push(`inactive='${!values.active}'`);
        let filterQuery = queries.join(" AND ");
        
        if (values.roles !== undefined && values.roles.length > 0) {
          const roleQuery=[];
          values.roles.forEach((item) => roleQuery.push(`id in (select contactId from ContactRole where role='${item.role}')`));
          if(values.rolesOrMode){
            filterQuery+=`${queries.length>0?" AND ":""} (${roleQuery.join(" OR ")})`;
          }else{
            filterQuery+=`${queries.length>0?" AND ":""} (${roleQuery.join(" AND ")})`;
          }
        }
        props.onClose();
        props.onSearch({filter: filterQuery, tagFilterAny: values.tagsAny, tagFilterAll: values.tagsAll});
      }
    });
  }
 const onReset=()=>{
    props.form.resetFields();
    submit();
  }

    const { getFieldDecorator } = props.form;
    return (
      <Drawer title={t("Contact Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => onReset()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Id")}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
          <Form.Item label={t("Name")}>{getFieldDecorator("name")(<Input />)}</Form.Item>
          <Form.Item label={t("Passport")}>{getFieldDecorator("passport")(<Input />)} </Form.Item>
          <Form.Item label={t("Workflow State")}>{getFieldDecorator("workflowState")(<WorkflowStateSelect />)} </Form.Item>
          <Form.Item label={t("CNP")}>{getFieldDecorator("cnp")(<Input />)} </Form.Item>
          <Form.Item label={t("NIF")}>{getFieldDecorator("nif")(<Input />)} </Form.Item>
          <Form.Item label={t("Holding")}>{getFieldDecorator("holdingId")(<HoldingSelect />)} </Form.Item>
          <Form.Item label={t("Status")}>{getFieldDecorator("active")(<StatusSelect allowClear />)}</Form.Item>
            <div style={{display:"flex"}}>
                <Form.Item style={{flex:1,marginRight:3}} label={t("Role")}>{getFieldDecorator("roles")(<RoleSelect />)} </Form.Item>
                <Form.Item label={t("Mode")}>{getFieldDecorator("rolesOrMode",{valuePropName:"checked"})(<Switch checkedChildren={t("Or")} unCheckedChildren={t("And")}  />)} </Form.Item>
            </div>
          <Form.Item label={t("Type")}>
            {getFieldDecorator("isPerson")(
              <Radio.Group name="radiogroup" type="">
                <Radio.Button value={true}>{t("Person")}</Radio.Button>
                <Radio.Button value={false}>{t("Company")}</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label={t("Tags (Any)")}>
            {getFieldDecorator(
              "tagsAny",
              {}
            )(<Select mode="tags" placeholder={t("Insert comma separated tags")} style={{ width: "100%", marginBottom: 5 }} tokenSeparators={[","]}></Select>)}
          </Form.Item>
          <Form.Item label={t("Tags (All)")}>
            {getFieldDecorator(
              "tagsAll",
              {}
            )(<Select mode="tags" placeholder={t("Insert comma separated tags")} style={{ width: "100%", marginBottom: 5 }} tokenSeparators={[","]}></Select>)}
          </Form.Item>
          <Form.Item label={t("Last updated")}>{getFieldDecorator("updated")(<DatePicker.RangePicker />)}</Form.Item>
          <Form.Item label={t("Date of birth")}>{getFieldDecorator("birth")(<DatePicker />)}</Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
      </Drawer>
    );
  }
export default  Form.create()(Filter);
