import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import ComContractSelect from '../Commissions/ComContractSelect';
import SellerSelect from '../Commissions/SellerSelect';
import ParticipantRoleSelect from '../Commissions/ParticipantRoleSelect';
import Percentage from '../Shared/Percentage';

const AmendmentIntermediary = (props) => {
    const [hasAdHocCommission, setHasAdHocCommission] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);
  const currentIntermediary = props.currentIntermediary;
  const values=props.form.getFieldsValue();
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={t("Old Intermediary Value")}>
                {field("oldIntermediary", { initialValue: currentIntermediary })(<ComContractSelect disabled={true} />)}
              </Form.Item>
                <div style={{ display: props.multipleRoles ? "none" : "block" }}>
              <Form.Item label={t("Old Seller Value")}>
                {field("oldSellerId", { normalize: (v) => (v ? v : undefined) })(
                    <SellerSelect comContractId={values.oldIntermediary}  disabled />
                )}
              </Form.Item>
                </div>
              <div style={{ display: props.multipleRoles  ? "block" : "none" }}>
                <Form.Item label={t("Old Participants")}>
                  {field("oldJComParticipants")(
                      <ParticipantRoleSelect disabled />
                  )}
                </Form.Item>
              </div>
              <div style={{ display: values.oldComPercentage ? "block" : "none" }}>
                <Form.Item label={t("Old Ad-Hoc Commission")}>
                  {field("oldComPercentage")(
                      <Percentage disabled />
                  )}
                </Form.Item>
              </div>
              <Form.Item label={t("New Intermediary Value")}>
                {field("newIntermediary")(<ComContractSelect onSelect={(r)=>setHasAdHocCommission(r.isAdHoc)} />)}
              </Form.Item>
              <div style={{ display: props.multipleRoles ? "none" : "block" }}>
                <Form.Item label={t("Seller")}>
                  {field("newSellerId")(
                      <SellerSelect comContractId={values.newIntermediary}  />
                  )}
                </Form.Item>
                </div>
              <div style={{ display: props.multipleRoles ? "block" : "none" }}>
                <Form.Item label={t("Participants")}>
                  {field("newJComParticipants")(
                      <ParticipantRoleSelect comContractId={values.newIntermediary}  />
                  )}
                </Form.Item>
              </div>
              <div style={{ display: (hasAdHocCommission||values.newComPercentage) ? "block" : "none" }}>
                <Form.Item label={t("Ad-Hoc Commission")}>
                  {field("newComPercentage")(
                      <Percentage />
                  )}
                </Form.Item>
              </div>
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentIntermediary);
