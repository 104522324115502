import { EditOutlined, LinkOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Badge,
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Switch,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import Title from "antd/lib/typography/Title";
import Currency from "../Shared/Currency";
import DistributionModeSelect from "./DistributionModeSelect";
import CessionParticipants from "./CessionParticipants";
import Section from "../Shared/Section";
import Percentage from "../Shared/Percentage";
import ContractSelect from "./ContractSelect";
import {round2} from "../../Lib/Helpers";
import UpdateRetention from "./UpdateRetention";
import DatePickerW from "../Shared/DatePickerW";
import Money from '../Shared/Money';

const CessionEdit = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [changeRetentionVisible,setChangeRetentionVisible]=useState(false);
  
  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);
  const field = props.form.getFieldDecorator;

  const save = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("RepoCession", { operation: "UPDATE", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          props.onEdit && props.onEdit();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  //proportion change
  const onCoinsurerProportionChange=v=>{
    const insuredSum=formValues.sumInsured;
    props.form.setFieldsValue({coSumInsured:v*insuredSum})
  }
  const onCedantProportionChange=v=>{
    const insuredSum=formValues.sumInsured;
    props.form.setFieldsValue({sumInsuredCedant:v*insuredSum})
  }
  const onReinsurerProportionChange=v=>{
    const insuredSum=formValues.sumInsured;
    props.form.setFieldsValue({sumInsuredRe:v*insuredSum})
  }
  //sum insured change
  const onCoinsurerSumInsuredChange=v=>{
    const insuredSum=formValues.sumInsured;
    props.form.setFieldsValue({coPercentage:round2(v/insuredSum*100)})
    props.form.validateFields(["coPercentage"]);
  }
  const onCedantSumInsuredChange=v=>{
    const insuredSum=formValues.sumInsured;
    props.form.setFieldsValue({proportionCed:round2(v/insuredSum)})
    props.form.validateFields(["proportionCed"]);
  }
  const onReinsurerSumInsuredChange=v=>{
    const insuredSum=formValues.sumInsured;
    props.form.setFieldsValue({proportionRe:round2(v/insuredSum)})
    props.form.validateFields(["proportionRe"]);
  }
  //proportion validators
  const coProportionValidator=(rules,value,cb)=>{
    //if(value/100+formValues.proportionCed+formValues.proportionRe>1) return cb(t("Total % cannot exceed 100%"))
    if(value/100>1) return cb(t("Total % cannot exceed 100%"))
    cb();
  }
  const cedProportionValidator=(rules,value,cb)=>{
    if(value+formValues.proportionRe>1) return cb(t("Total % cannot exceed 100%"))
    cb();
  }
  const reProportionValidator=(rules,value,cb)=>{
    if(formValues.proportionCed+value>1) return cb(t("Total % cannot exceed 100%"));
    cb();
  }
  
  const onRetentionChanged=()=>{
    setChangeRetentionVisible(false);
    props.onBack&&props.onBack();
    props.onEdit && props.onEdit(); //reload
    
  }

  const formValues = props.form.getFieldsValue();
  return (
    <div>
      <Button type="link" icon={<SaveOutlined />} onClick={() => save()}>
        {t("Save")}
      </Button>
      <Button type="link" icon={<EditOutlined />} onClick={() => setChangeRetentionVisible(true)}>
        {t("Change Retention")}
      </Button>
      <Tabs>
        <Tabs.TabPane tab={t("Distribution")} key="distribution">
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 25 }}>
              <Section text={t("Underwritten")}>
                <Form.Item label={t("Sum Insured")}>
                  {field("sumInsured", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
                <Form.Item label={t("Premium")}>{field("premium", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}</Form.Item>
              </Section>
            </div>
            <div style={{ marginRight: 25 }}>
              <Section text={t("Coinsurer(s)")}>
                <Form.Item label={t("Sum Insured Coinsurers")}>
                  {field("coSumInsured", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} onChange={v=>onCoinsurerSumInsuredChange(v)} />)}
                </Form.Item>
                <Form.Item label={t("Coverage Premium Coinsurers")}>
                  {field("coCovPremium", { rules: [{ required: false }] })(<Money currency={formValues.currency} style={{ width: "171px" }} disabled />)}
                </Form.Item>
                <Form.Item label={t("Proportion Coinsurers")}>
                  {field("coPercentage", { rules: [{ required: true,validator:coProportionValidator }] })(<Percentage style={{ width: "171px" }} onChange={v=>onCoinsurerProportionChange(v/100)} />)}
                </Form.Item>
                <Form.Item label={t("Commission")}>
                  {field("coCommission", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
                <Form.Item label={t("Premium Coinsurers")}>
                  {field("coPremium", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
              </Section>
            </div>
            <div style={{ marginRight: 25 }}>
              <Section text={t("Cedant")}>
                <Form.Item label={t("Sum Insured Cedant")}>
                  {field("sumInsuredCedant", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} onChange={v=>onCedantSumInsuredChange(v)} />)}
                </Form.Item>
                <Form.Item label={t("Premium Cedant")}>
                  {field("premiumCedant", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
                <Form.Item label={t("Proportion Cedant")}>
                  {field("proportionCed", { rules: [{ required: true,validator:cedProportionValidator }] })(<Percentage precision={2} decimal style={{ width: "171px" }} onChange={v=>onCedantProportionChange(v)} />)}
                </Form.Item>
                <Form.Item label={t("Commission Cedant")}>
                  {field("comissionCedant", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
              </Section>
            </div>
            <div style={{ marginRight: 25 }}>
              <Section text={t("Reinsurer(s)")}>
                <Form.Item label={t("Sum Insured Reinsurer")}>
                  {field("sumInsuredRe", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} onChange={v=>onReinsurerSumInsuredChange(v)} />)}
                </Form.Item>
                <Form.Item label={t("Premium Reinsurer")}>
                  {field("premiumRe", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
                <Form.Item label={t("Proportion Reinsurer")}>
                  {field("proportionRe", { rules: [{ required: true,validator:reProportionValidator }] })(<Percentage precision={2} decimal style={{ width: "171px" }} onChange={v=>onReinsurerProportionChange(v)} />)}
                </Form.Item>
                <Form.Item label={t("Reserve")}>
                  {field("reserve", { rules: [{ required: true }] })(<Money currency={formValues.currency} style={{ width: "171px" }} />)}
                </Form.Item>
              </Section>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Conditions")} key="conditions">
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label={t("Currency")}>{field("currency", { rules: [{ required: true }] })(<Currency />)}</Form.Item>
              <Form.Item label={t("Line")}>{field("lineId")(<Input />)}</Form.Item>
              <Form.Item label={t("Type")}>{field("premiumType")(<Input />)}</Form.Item>
              <Form.Item label={t("Distribution Mode")}>{field("distributionMode", { rules: [{ required: true }] })(<DistributionModeSelect />)}</Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t("Start")}>{field("start", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("End")}>{field("end", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}</Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t("Id")}>{field("id")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Contract Id")}>
                {field("contractId")(<ContractSelect disabled />)}
                <a href={"#/contract/" + formValues.contractId}>
                  <LinkOutlined style={{ marginLeft: 5 }} />
                </a>
              </Form.Item>
              <Form.Item label={t("Policy")}>
                {field("policyCode")(<FormLabel />)}{" "}
                <a href={"#/lifepolicy/" + formValues.lifePolicyId}>
                  <LinkOutlined style={{ marginLeft: 5 }} />
                </a>
              </Form.Item>
              <Form.Item label={t("Policy Id")}>{field("lifePolicyId")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Holder")}>{field("holderName")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Change Id")}>{field("changeId")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Manually Edited")}>{field("edited")(<FormLabel />)}</Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t("Coverage Id")}>{field("coverageId")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Coverage")}>{field("cover")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Coverage Code")}>{field("coverageCode")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Product")}>{field("product")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("LoB")}>{field("LoB")(<FormLabel />)}</Form.Item>
              <Form.Item label={t("Not Proportional Line")}>{field("np",{valuePropName:"checked"})(<Switch disabled />)}</Form.Item>
              <Form.Item label={t("Overwritten")}>{field("overwritten",{valuePropName:"checked"})(<Switch disabled />)}</Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane key="validation" tab={<Badge dot={formValues.err}>{t("Validation")}</Badge>}>
          <Form.Item label={t("Invalid Flag")}>{field("err", { valuePropName: "checked" })(<Switch checkedChildren={<StopOutlined />} />)}</Form.Item>
          <Form.Item label={t("Messages")}>{field("msg")(<Input.TextArea rows={3} placeholder={t("Distribution messages")} />)}</Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Participants")} key="participants">
          {field("Participants")(<CessionParticipants cession={formValues} reload={v=>props.form.setFieldsValue({Participants:v})} reloadParent={()=>props.onEdit && props.onEdit()} />)}
        </Tabs.TabPane>
      </Tabs>
    <UpdateRetention visible={changeRetentionVisible} cessionId={formValues.id} onCancel={()=>setChangeRetentionVisible(false)} onOk={onRetentionChanged} />
    </div>
  );
};

export default Form.create()(CessionEdit);
