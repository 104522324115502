import React, {useEffect, useLayoutEffect, useState} from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import "inputmask/dist/jquery.inputmask.min"
import { get, exe } from "../../Lib/Dal";
import { Empty, message,notification } from "antd";
import { useTranslation } from "react-i18next";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("formBuilder/dist/form-render.min.js");

const CustomForm = (props) => {
  const [t, i18n] = useTranslation();
  const [loadedFormId, setLoadedFormId] = useState();
  const [customLogic, setCustomLogic] = useState();

  useEffect(() => {
    //if (props.formId){console.log("--formid then value",props.formId,props.value);loadForm(props.formId).then((r) => loadValue(props.value));}
    if (props.formId){console.log("--formId",props.formId,props.value);loadForm(props.formId);}
    if (props.value){console.log("--value",props.value,props.formId);loadValue(props.value);}
  }, [props.formId, props.value,props.update]);
  
  useEffect(()=>{
    //resetting form
    if(window[props.variable]&&window[props.variable].instanceContainers&&props.newRecord){
      window[props.variable].clear(); 
    }
  },[props.newRecord])
  
  useLayoutEffect(() => () => {
    //unmounting
    window[props.variable]=null;
  }, [])

  const loadForm = (formId) =>
    new Promise((resolve, reject) => {
      if (loadedFormId == formId) {
        //same form, executing saved custom logic
        exeCustomLogic(customLogic);
        return resolve();
      }
      setLoadedFormId(formId);
      exe("GetForms", { filter: "id=" + formId }).then((r) => {
        if (r.ok) {
          const fbRender = document.getElementById("fb-render" + (props.variable ? props.variable : ""));
          const formInstance = $(fbRender).formRender({
            container: fbRender,
            formData: r.outData[0].json,
          });
          setCustomLogic(r.outData[0].logic);
          exeCustomLogic(r.outData[0].logic);
          window[props.variable] = formInstance;
          window[props.variable].load = loadForm;
          resolve();
        } else {
          message.error(r.msg);
          reject(r.msg);
        }
      });
    });
  const loadValue = (value) => {
    if(!value) return;
    const checkExists = () => {
      if (window[props.variable]&&window[props.variable].instanceContainers) {
        $(window[props.variable].instanceContainers[0]).formRender({ formData: value }); //warning. overwrites exsisting form
        console.log("setting value...")
      } else {
        console.log("Form instance not yet available");
        setTimeout(checkExists, 500);
      }
    };
    checkExists();
  };
  const evalInContext = (js, context) => {
    return function () {
      return eval(js);
    }.call(context);
  };
  const loadCatalog = (catalog, element, filter, valueField = "id", displayField = "name") => {
    exe(catalog, { operation: "GET", filter: filter }).then((r) => {
      r.outData.map((r) => {
        $(element).append($("<option></option>").attr("value", r[valueField]).text(r[displayField]));
      });
    });
  };
  const exeCustomLogic = (logic) => {
    if (!logic) return;
    try {
      console.log("Executing custom logic:", logic);

      evalInContext(logic, { exe: exe, loadCatalog: loadCatalog,notification:notification,message:message });
      console.log("Success");
    } catch (error) {
      console.warn(error);
    }
  };
  return (
    <div className={props.disabled?"disabled":""}>
      <form id={"fb-render" + (props.variable ? props.variable : "")}></form>
      {!props.formId && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("No custom form configured")} />}
    </div>
  );
};

export default CustomForm;
