import {
  DeleteOutlined,
  EuroOutlined,
  PlusOutlined,
  ReloadOutlined,
  RocketOutlined,
  SearchOutlined,
  SelectOutlined,
  GroupOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Button, Divider, Input, message, Modal, Popconfirm, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGetRaw, safeValue} from "../../Lib/Dal";
import useUpdateEffect, { formatMoney } from "../../Lib/Helpers";
import AddCertificate from "./AddCertificate";
import CertificateGroups from './CertificateGroups';
import PolicyCertificateFilter from './PolicyCertificateFilter';

const Certificates = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [addCertificateVisible, setAddCertificateVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [certificateGroupsVisible,setCertificateGroupsVisible]=useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const [currentFilter, setCurrentFilter] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  
  const disableCertificateEditing=safeGetRaw(["Main","disableCertificateEditing"],props.config,false)&&props.active;

  useEffect(() => {
    if (props.groupPolicyId) load();
  }, [props.groupPolicyId]);

  useUpdateEffect(() => {
    load();
  }, [pagination.current]);
  useUpdateEffect(() => {
    load();
  }, [currentFilter]);

  const onAddCertificate = () => {
    setAddCertificateVisible(true);
  };
  const load = () => {
    setLoading(true);
    exe("GetCertificates", { groupPolicyId:props.groupPolicyId,size: pagination.pageSize,page: pagination.current - 1,filter:currentFilter}).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData((r.outData.certificates || []));
        setPagination({ ...pagination, total: r.outData.total, current:pagination.total!==r.outData.total?1:pagination.current });
      } else {
        message.error(r.msg);
      }
    });
  };
  const save = (newCertificate) => {
    setLoading(true);
    exe("AddCertificate", { certificates: [newCertificate], groupPolicyId: props.groupPolicyId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSubmitNewCertificate = (values) => {
    setAddCertificateVisible(false);
    save(values);
  };
  const remove = (policyId) => {
    setLoading(true);
    exe("DeleteCertificate", policyId).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        setSelectedRowKeys(selectedRowKeys.filter(p=>p!==policyId));
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const removeSelection=()=>{
    setLoading(true);
    for (let i = 0; i <selectedRowKeys.length ; i++) {
      exe("DeleteCertificate", selectedRowKeys[i]).then((r) => {
        if(i===selectedRowKeys.length-1) setLoading(false);
        if (r.ok) {
          if(i===selectedRowKeys.length-1) {
            message.success(`${selectedRowKeys.length} ${t("Certificates removed")}`);
            setSelectedRowKeys([]);
            load();
          }
        } else {
          message.error(r.msg);
        }}
        )
    }
}
  const quote = (selected) => {
    setLoading(true);
    exe("QuoteCertificates", { groupPolicyId: props.groupPolicyId, certificates: selected ? selected : null,async:true }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const issue = (selected) => {
    setLoading(true);
    exe("IssueCertificates", { groupPolicyId: props.groupPolicyId, certificates: selected ? selected : null,async:true }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end

  const onSearch = (filterQuery) => {
    console.log(filterQuery, "FILTER QUERY");
    //load(filterQuery);
    setFilterVisible(false);
    setCurrentFilter(filterQuery); // saving filter query for pagination
  };

  const quoteSelection = () => {};
  const groups=safeValue("config.Main.certificateGroups", props, ["DEFAULT"]).concat(props.jCertificateGroups?JSON.parse(props.jCertificateGroups).map(x=>x.name):[]);
  return (
    <div>
      <div className={props.groupPolicyId ? "" : "disabled"}>
        <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: 5 }} onClick={onAddCertificate} disabled={!props.groupPolicyId||disableCertificateEditing}>
          {t("Add")}
        </Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
          {t("Reload")}
        </Button>
        {selectedRowKeys.length > 0 && (
          <Button type="link" icon={<SelectOutlined />} onClick={() => quote(selectedRowKeys)} disabled={disableCertificateEditing}>
            {t("Quote Selection")}
          </Button>
        )}
        {selectedRowKeys.length > 0 && (
            <Button type="link" icon={<SelectOutlined />} onClick={() => issue(selectedRowKeys)} disabled={disableCertificateEditing}>
              {t("Issue Selection")}
            </Button>
        )}
        {selectedRowKeys.length > 0 && (
            <Popconfirm title={t("Are you sure you want to remove selected certificates?")} onConfirm={()=>removeSelection()} disabled={disableCertificateEditing}>
              <Button type="link" icon={<DeleteOutlined />} disabled={disableCertificateEditing}>
                {t("Remove Selection")}
              </Button>
            </Popconfirm>
        )}
        <Button type="link" icon={<EuroOutlined />} onClick={() => quote()} disabled={disableCertificateEditing}>
          {t("Quote All")}
        </Button>
        <Button type="link" icon={<RocketOutlined />} onClick={() => issue()} disabled={!props.active||disableCertificateEditing}>
          {t("Issue All")}
        </Button>
        <Button type="link" icon={<GroupOutlined />} onClick={() => setCertificateGroupsVisible(true)}>
          {t("Custom Groups")}
        </Button>
        <Button type="link" icon={<FileSearchOutlined />} onClick={() => setFilterVisible(true)}>
          {t("Search")}
        </Button>

      </div>
      <Table
        dataSource={(data || []).map((p) => ({ ...p, 
          insuredName: p.Insureds&&p.Insureds.length>0 ? p.Insureds[0].name : "", 
          payerName: p.Payer ? p.Payer.FullName : "",
          description:p.description?p.description: "",
          group:p.group?p.group:"",
          statusName: p.active ? t("Active") : t("Inactive") }))}
        rowSelection={{ selectedRowKeys, onChange: (keys) => setSelectedRowKeys(keys) }}
        rowKey="id"
        pagination={pagination}
        onChange={(pag) => setPagination(pag)}
        loading={loading}>
        <Table.Column title={t("Num")} dataIndex="certificate" {...getColumnSearchProps("certificate")} sorter={(a, b) => a.certificate - b.certificate} />
        <Table.Column title={t("Insured")} dataIndex="insuredName" {...getColumnSearchProps("insuredName")} sorter={(a, b) => a.insuredName.localeCompare(b.insuredName)}/>
        <Table.Column title={t("Payer")} dataIndex="payerName" {...getColumnSearchProps("payerName")} sorter={(a, b) => a.payerName.localeCompare(b.payerName)}/>
        <Table.Column
          title={t("Base Insured Sum")}
          dataIndex="insuredSum"
          render={(v, r) => formatMoney(v, props.currency)}
          {...getColumnSearchProps("insuredSum")}
          sorter={(a, b) => a.insuredSum - b.insuredSum}
        />
        <Table.Column
          title={t("Premium")}
          dataIndex="anualPremium"
          render={(v, r) => formatMoney(v, props.currency)}
          {...getColumnSearchProps("anualPremium")}
          sorter={(a, b) => a.anualPremium - b.anualPremium}
        />
        <Table.Column title={t("Group")} dataIndex="group" {...getColumnSearchProps("group")} sorter={(a, b) =>  a.group.localeCompare(b.group)}/>
        <Table.Column
          title={t("Status")}
          dataIndex="statusName"
          {...getColumnSearchProps("statusName")}
          sorter={(a, b) =>  a.statusName.localeCompare(b.statusName)}
          render={(v, r) => <Tag color={r.active ? "#87d068" : "#f50"}>{v}</Tag>}
        />
        <Table.Column title={t("Last Event")} dataIndex="lastEventName" {...getColumnSearchProps("lastEventName")} 
        sorter={(a, b) => a.lastEventName.localeCompare(b.lastEventName)} render={(v,r)=>
          <Tooltip title={r.lastEventDescription}>
            <Tag color={v.toLowerCase().includes("error")?"red":""}>{v}</Tag>
            </Tooltip>
        } />
        <Table.Column title={t("Description")} dataIndex="description" {...getColumnSearchProps("description")} sorter={(a, b) => a.description.localeCompare(b.description)} />
        <Table.Column
          title={t("Actions")}
          dataIndex="id"
          render={(v, r) => (
            <span>
              <a href={"#/lifePolicy/" + v + "/tab1"}>{t("Open")}</a>
              <Divider type="vertical" />
              <Popconfirm title={t("Are you sure you want to remove this certificate?")} onConfirm={() => remove(v)} disabled={disableCertificateEditing}>
                <Button type="link" style={{ paddingLeft: 0 }} disabled={r.active||disableCertificateEditing}>
                  {t("Remove")}
                </Button>
              </Popconfirm>
            </span>
          )}
        />
      </Table>
      <Modal title={t("Add Certificate")} visible={addCertificateVisible} footer={false} onCancel={() => setAddCertificateVisible(false)}>
        <AddCertificate
          currency={props.currency}
          onSubmit={(v) => onSubmitNewCertificate(v)}
          config={props.config}
          groups={groups}
          mainInsuredId={props.mainInsuredId}
          payerId={props.payerId}
        />
      </Modal>
      <CertificateGroups policyId={props.groupPolicyId} visible={certificateGroupsVisible} value={props.jCertificateGroups} config={props.config}  
      onCancel={()=>setCertificateGroupsVisible(false)} onOk={(values)=>{setCertificateGroupsVisible(false);props.onUpdateCertificateGroups(values)}} />
      <PolicyCertificateFilter pagination={pagination} groups={groups}  visible={filterVisible} onClose={()=>setFilterVisible(false)} onSearch={onSearch} />
    </div>
  );
};

export default Certificates;
