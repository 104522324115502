import { CheckOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateShort } from "../../Lib/Helpers";
import LoadingDetail from "./LoadingDetail";

const Loadings = (props) => {
  const [t] = useTranslation();
  const [detailVisible, setDetailVisible] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (props.value) {
      setData(props.value.map((p) => (p.id > 0 ? { ...p, tempId: p.id } : p)));
    }
  }, [props.value]);

  const onEditedRecord = (v) => {
    console.log(v, "edited");
    let newData = [];
    if (v.tempId == 0) {
      //new. not saved yet, using tempId
      v.tempId = new Date().valueOf();
      newData = data.concat(v);
      setData(newData);
    } else {
      //existing, substitution
      newData = data.map((p) => (p.tempId == v.tempId ? v : p));
      setData(newData);
    }
    props.onChange(newData);
  };
  const onSelect = (r) => {
    setSelected(r);
    setDetailVisible(true);
  };
  return (
    <div className={props.disabled ? "disabled" : ""}>
      <div style={{ display: detailVisible ? "none" : "block" }}>
        <Button type="link" icon={<PlusOutlined />} onClick={() => setDetailVisible(true)}>
          {t("New")}
        </Button>
        <Table dataSource={data.filter((p) => typeof p.loading=="number"&&p.loading!==0)} rowKey="id">
          <Table.Column title={t("Risk")} dataIndex="riskType" />
          <Table.Column title={t("Loading %")} dataIndex="loading" />
          <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShort(v)} />
          <Table.Column title={t("End")} dataIndex="end" render={(v) => (v ? formatDateShort(v) : null)} />
          <Table.Column title={t("Notes")} dataIndex="notes" />
          <Table.Column title={t("Manual")} dataIndex="manual" render={(v) => (v ? <CheckOutlined /> : null)} />
          <Table.Column title={t("Insured Sum")} dataIndex="insuredSumBased" render={(v) => (v ? <CheckOutlined /> : null)} />
          <Table.Column title={t("Actions")} dataIndex="id" render={(v, r) => <Button type="link" icon={<EditOutlined />} onClick={() => onSelect(r)} />} />
        </Table>
      </div>
      <div style={{ display: detailVisible ? "block" : "none" }}>
        <LoadingDetail duration={props.duration} coverage={props.coverage} loadings={data.filter((p) => typeof p.loading=="number")} onChange={(v) => onEditedRecord(v)} onBack={() => setDetailVisible(false)} value={selected} enableDateEdition={props.enableDateEdition} />
      </div>
    </div>
  );
};

export default Loadings;
