import React, { useEffect, useState } from "react";
import {message, Select, Tag} from "antd";
import { formatter } from "../Shared/Money";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ClaimBeneficiary = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.coverageId) loadBeneficiaries(props.coverageId);
  }, [props.coverageId]);

  //triggering the percentage change when a beneficiary is selected
  useEffect(() => {
    if (props.value) {
      if(props.onPercentageChange){
        const currentSelected = data.find((p) => p.contactId == props.value);
        if(currentSelected) props.onPercentageChange(currentSelected.percentage);
      }
    }else{
      if(props.onPercentageChange){
        props.onPercentageChange(0);
      }
    }
  }, [props.value]);

  const loadBeneficiaries = (coverageId) => {
    setLoading(true);
    exe("GetCoverageBeneficiaries", { coverageId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        const currentSelected = r.outData.find((p) => p.contactId == props.value);
        if (!currentSelected) props.onChange(undefined);
      } else {
        message.error(r.msg);
      }
    });
  };

  const Options = data.map((d) => (
    <Select.Option
      key={d.contactId}
      value={d.contactId}
      percentage={d.percentage}
      disabled={d.Contact.isMinor}
      label={
        <div>
          {d.name} - {d.percentage}%
        </div>
      }>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          {d.name} - {d.percentage}%  {d.Contact.isMinor&&<Tag color="orange">{t("Minor")}</Tag>}
        </div>
        <i>Type:{d.type}</i>
      </div>
    </Select.Option>
  ));
  return (
    <Select
      loading={loading}
      onSelect={(v, op) => props.onSelect && props.onSelect(v, op)}
      onClear={props.onClear}
      optionLabelProp="label"
      value={props.value ? +props.value : undefined}
      disabled={!props.value && !props.coverageId}
      onChange={props.onChange}
      placeholder={t("Please select beneficiary")}
      style={props.style}
      allowClear>
      {Options}
    </Select>
  );
};

export default ClaimBeneficiary;
