import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import CoveragesDisplay from "./CoveragesDisplay";
import CoverageSelector from "./CoverageSelector";

const AmendmentAddCoverage = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);
  const field = props.form.getFieldDecorator;
  const currentCoverages = props.currentCoverages || [];
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Section text={t("Additional Amendment Options")}>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
            <Form.Item label={<Text strong>{t("Coverages before change")}</Text>}>
              {field("jBeforeCoverages", { initialValue: currentCoverages })(<CoveragesDisplay />)}
            </Form.Item>
            <Form.Item label={<Text strong>{t("Coverages after change")}</Text>}>
              {field("jAddedCoverages", { initialValue: currentCoverages, rules: [{ required: true }] })(
                <CoverageSelector config={props.config} excluded={currentCoverages.map((p) => p.code)} value={selected} onChange={(p) => setSelected(p)} />
              )}
            </Form.Item>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
          </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentAddCoverage);
