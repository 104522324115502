import {
  BankOutlined,
  CheckSquareOutlined,
  EnterOutlined,
  HomeOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
  ShoppingOutlined,
  StopOutlined,
  ToolOutlined,
} from '@ant-design/icons';

import { Button, Dropdown, Menu, message, Table } from "antd";
import React, { useState } from "react";
import AmendmentAddCoverage from "./AmendmentAddCoverage";
import AmendmentClause from "./AmendmentClause";
import AmendmentExclusion from "./AmendmentExclusion";
import AmendmentLoading from "./AmendmentLoading";
import AmendmentRemoveCoverage from "./AmendmentRemoveCoverage";
import AmendmentIntermediary from './AmendmentIntermediary';
import { useTranslation } from 'react-i18next';
import AmendmentInsuredObject from './AmendmentInsuredObject';
import AmendmentTemporalStatus from './AmendmentTemporalStatus';
import AmendmentCessionBeneficiary from './AmendmentCessionBeneficiary';

const Amendments = (props) => {
  const [t]=useTranslation();
  const [typeSelected, setTypeSelected] = useState();
  const [amendmentSelected, setAmendmentSelected] = useState();
  const data = props.value || [];

  const onChangeType = (type) => {
    setTypeSelected(type.key);
  };
  const onAmendementChange = (v) => {
    const newAmendments = data.filter((p) => p.type !== typeSelected);
    newAmendments.push({ ...v, type: typeSelected });
    props.onChange(newAmendments);
    //setting also jAmendments in the parent
    props.changeForm.setFieldsValue({ jAmendments: JSON.stringify(newAmendments) });
  };
  const onSubmitAmendment = () => {
    let err=false;
    if(typeSelected=="AmendmentCessionBeneficiary"){
      //getting amendment
      const myAmendment = data.find((p) => p.type === typeSelected);
      //validating and setting dynamic form values, if present
      if(window.customCessionBeneficiaryFormNew){
        //validating custom form
        if (window.customCessionBeneficiaryFormNew &&window.customCessionBeneficiaryFormNew.instanceContainers&& window.customCessionBeneficiaryFormNew.instanceContainers[0]) {
         if (window.customCessionBeneficiaryFormNew.instanceContainers[0].reportValidity()) {
            myAmendment.oldJCessionBeneficiary = policy.jCessionBeneficiary;
            myAmendment.newJCessionBeneficiary = JSON.stringify(window.customCessionBeneficiaryFormNew.userData);
            onAmendementChange(myAmendment);
         } else {
             message.error(t("Please check cession beneficiary form validation errors"));
              err=true;
         }}
     }
    }
    if(err) return;
    setTypeSelected();
    setAmendmentSelected();
  };
  const onSelectAmendment = (r) => {
    console.log(r);
    setAmendmentSelected(r);
    setTypeSelected(r.type);
  };
  const policy = props.common.policy;
  const config = props.common.config;
  return (
    <div>
      {!typeSelected && (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu onClick={onChangeType}>
              <Menu.Item key="AmendmentLoading">
                <ToolOutlined />
                {t("Loading")}
              </Menu.Item>
              <Menu.Item key="AmendmentClause">
                <CheckSquareOutlined />
                {t("Clauses")}
              </Menu.Item>
              <Menu.Item key="AmendmentExclusion">
                <StopOutlined />
                {t("Exclusions")}
              </Menu.Item>
              <Menu.Item key="AmendmentAddCoverage">
                <PlusCircleOutlined />
                {t("Add Coverage")}
              </Menu.Item>
              <Menu.Item key="AmendmentRemoveCoverage">
                <MinusCircleOutlined />
                {t("Remove Coverage")}
              </Menu.Item>
              <Menu.Item key="AmendmentIntermediary">
                <ShoppingOutlined />
                {t("Intermediary")}
              </Menu.Item>
              <Menu.Item key="AmendmentInsuredObject">
                <HomeOutlined />
                {t("Insured Object")}
              </Menu.Item>
              <Menu.Item key="AmendmentTemporalStatus" >
               <ScheduleOutlined />
              {t("Temporal Status")}
              </Menu.Item>
              <Menu.Item key="AmendmentCessionBeneficiary">
              <BankOutlined />
              {t("Cession Beneficiary")}
              </Menu.Item>
            </Menu>
          }>
          <Button type="link" icon={<PlusCircleOutlined />} style={{ paddingLeft: 0 }}>
            {t("Combine Amendment")}
          </Button>
        </Dropdown>
      )}

      {typeSelected && (
        <Button type="link" icon={<EnterOutlined />} style={{ paddingLeft: 0 }} onClick={onSubmitAmendment}>
          {t("Submit Amendment")}
        </Button>
      )}

      {data.length > 0 && !typeSelected && (
        <Table dataSource={data} size="middle">
          <Table.Column title="Type" dataIndex="type" />
          <Table.Column
            title="Actions"
            key="actions"
            render={(v, r) => (
              <Button type="link" onClick={() => onSelectAmendment(r)}>
                {t("Open")}
              </Button>
            )}
          />
        </Table>
      )}
      {typeSelected == "AmendmentLoading" && (
        <AmendmentLoading 
        value={amendmentSelected} 
        config={config} 
        policy={policy} 
        onChange={onAmendementChange} />
      )}
      {typeSelected == "AmendmentClause" && (
        <AmendmentClause
          value={amendmentSelected}
          config={config}
          policy={policy}
          currentClauses={policy.Clauses}
          onChange={onAmendementChange}
        />
      )}
      {typeSelected == "AmendmentExclusion" && (
        <AmendmentExclusion
          value={amendmentSelected}
          config={config}
          policy={policy}
          currentExclusions={policy?.Exclusions||[]}
          onChange={onAmendementChange}
        />
      )}
      {typeSelected == "AmendmentAddCoverage" && (
        <AmendmentAddCoverage
          value={amendmentSelected}
          onChange={onAmendementChange}
          policy={policy}
          config={config}

          currentCoverages={policy.Coverages}
        />
      )}
      {typeSelected == "AmendmentRemoveCoverage" && (
        <AmendmentRemoveCoverage
          value={amendmentSelected}
          onChange={onAmendementChange}
          policy={policy}
          config={config}

          currentCoverages={policy.Coverages}
        />
      )}
      {typeSelected == "AmendmentIntermediary" && (
        <AmendmentIntermediary
          value={amendmentSelected}
          onChange={onAmendementChange}
          policy={policy}
          config={config}

          currentIntermediary={policy.comContractId}
          currentSellerId={policy.sellerId}
          currentComPercentage={policy.comPercentage}
          currentJComParticipants={policy.jComParticipants}
          multipleRoles={props.common.multipleRoles}
        />
      )}
       {typeSelected == "AmendmentInsuredObject" && (
        <div>
          <AmendmentInsuredObject 
            value={amendmentSelected} 
            onChange={onAmendementChange}  
            config={config} 
            policy={policy} 
            new={!amendmentSelected}
            />
        </div>
      )}
       {typeSelected == "AmendmentTemporalStatus" && (
        <AmendmentTemporalStatus
          value={amendmentSelected}
          onChange={onAmendementChange} 
          currentStatus={policy.active}
    />
      )}
      {typeSelected == "AmendmentCessionBeneficiary" && (
        <AmendmentCessionBeneficiary
          value={amendmentSelected}
          onChange={onAmendementChange} 
          policy={policy} 
          config={config} 
          />
      )}
    </div>
  );
};

export default Amendments;
