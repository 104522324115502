import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, DatePicker, InputNumber, Button, Popconfirm } from 'antd';
import moment from 'moment';
import { MenuUnfoldOutlined, DeleteOutlined } from '@ant-design/icons';
const TaskEdit = ({ visible, onCancel, onSave, initialValues, tasks, onDelete }) => {
    const [form] = Form.useForm();
    const inputRef = useRef(null);

    useEffect(() => {
        if(visible && inputRef.current) {
            // Short delay to ensure the input is rendered
            setTimeout(() => inputRef.current.focus(), 100);
        }
        if(initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [visible, initialValues]);

    const hasSubtasks = initialValues && initialValues.children && initialValues.children.length > 0;

    const handleOk = () => {
        form.validateFields().then((values) => {
            onSave({
                ...values,
                start: values.start.format('YYYY-MM-DD'),
                end: values.end.format('YYYY-MM-DD'),
            });
        }).catch((info) => {
            form.setFieldsValue({ convertToChild: false });
            console.log('Validate Failed:', info);
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleOk();
        }
    };

    const handleSubmit = (values) => {
        onSave({
            ...values,
            start: values.start.format('YYYY-MM-DD'),
            end: values.end.format('YYYY-MM-DD'),
        });
    };

    return (
        <Modal
            visible={visible}
            title="Edit Task"
            onCancel={onCancel}
            onOk={handleOk}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                </Button>,
            ]}
        >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                <Popconfirm
                    title="Are you sure you want to delete this task?"
                    onConfirm={() => onDelete(initialValues.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{padding:0}}>
                        Delete
                    </Button>
                </Popconfirm>
            </div>
         
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    ...initialValues,
                    start: initialValues?.start ? moment(initialValues.start) : null,
                    end: initialValues?.end ? moment(initialValues.end) : null,
                }}
                onKeyDown={handleKeyDown}
                onFinish={handleSubmit}
            >
                <Form.Item name="id" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="projectId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="parentId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="wProgress" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="expected" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="diff" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    name="code"
                    label="WBS"
                    rules={[{ required: true, message: 'Please input the WBS!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Task Name"
                    rules={[{ required: true, message: 'Please input the task name!' }]}
                >
                    <Input ref={inputRef} />
                </Form.Item>
                <Form.Item
                    name="start"
                    label="Start Date"
                    rules={[{ required: true, message: 'Please select the start date!' }]}
                >
                    <DatePicker style={{ width: '100%' }} disabled={hasSubtasks} />
                </Form.Item>
                <Form.Item
                    name="end"
                    label="End Date"
                    rules={[{ required: true, message: 'Please select the end date!' }]}
                >
                    <DatePicker style={{ width: '100%' }} disabled={hasSubtasks} />
                </Form.Item>
                {/* <Form.Item
                    name="progress"
                    label="Progress"
                    rules={[{ required: true, message: 'Please input the progress!' }]}
                >
                    <InputNumber 
                        min={0} 
                        max={100} 
                        formatter={(value) => `${value}%`} 
                        parser={(value) => value.replace('%', '')} 
                        disabled={hasSubtasks}
                    />
                </Form.Item> */}
            </Form>
        </Modal>
    );
};

export default TaskEdit;
