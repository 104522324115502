import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, DatePicker, InputNumber, Button, Popconfirm, message, Row, Col, Tabs } from 'antd';
import moment from 'moment';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from '../../Lib/Dal';
import UserSelect from '../Bpm/UserSelect';
import RichEditor from './RichEditor';
import TabPane from 'antd/lib/tabs/TabPane';
import Comments from '../Life/Comments';

const CardEdit = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
       
        if(props.selected) {
            form.setFieldsValue(props.selected);
        }
    }, [props.visible, props.selected]);


    const handleOk = () => {
        form.validateFields().then((values) => {

            setLoading(true);
            exe('RepoProjectTaskCard',{operation:"UPDATE",entity:values}).then((res) => {
                setLoading(false);
                if(res.ok){
                    props.onSave(values);
                    message.success(res.msg);
                    form.resetFields();
                }else {
                    message.error(res.msg);
                }
            });


        }).catch((info) => {
            form.setFieldsValue({ convertToChild: false });
            console.log('Validate Failed:', info);
        });
    };

    const onDeleteCard = (card) => {
        setLoading(true);
        exe("RepoProjectTaskCard",{operation:"DELETE",entity:{id:card.id}}).then((r) => {
            setLoading(false);
            if(r.ok){
                console.log(r.outData);
                props.onDelete(card)
            }else message.error(r.msg);
        });
    }

    return (
        <Modal
            visible={props.visible}
            bodyStyle={{padding:"0px 24px 24px 24px"}}
            title={<span><FileOutlined /> Card {props.selected?.id}</span>}
            width={800}
            onCancel={props.onCancel}
            footer={[
                <Button key="cancel" onClick={props.onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Tabs>
                <Tabs.TabPane tab="Card" key="card">
                    <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col span={18}>
                                <Form.Item name="id" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="projectTaskColId" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="projectTaskId" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="projectId" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="order" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="status" hidden>
                                    <Input type="hidden" />
                                </Form.Item>

                                <Form.Item name="name" label="Title">
                                    <Input placeholder="Card title" />
                                </Form.Item>
                                <Form.Item name="content" label="Content">
                                    <RichEditor />
                                    {/* <Input.TextArea autoSize={{minRows:4}}  placeholder="Card content" /> */}
                                </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="user" label="Responsible">
                                    <UserSelect />
                                </Form.Item>
                            <Button type={"link"} style={{padding:0}} icon={<DeleteOutlined />} onClick={() => onDeleteCard(props.selected) }>Delete</Button>
                        </Col>
                    </Row>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Comments" key="comments">
                     <Comments cardId={props.selected&&props.selected.id}  />
                </Tabs.TabPane>


            </Tabs>
           
         
        </Modal>
    );
};

export default CardEdit;
